import { Alert, Button, Col, DatePicker, Drawer, Form, Input, Row } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import { FormComponentProps } from 'antd/lib/form';
import ContractSelect from 'components/form/ContractSelect';
import { LocationSelect } from 'components/form/select/LocationSelect';
import gql from 'graphql-tag';
import moment from 'moment';
import React from 'react';
import { useMutation } from 'react-apollo';
import AircraftSelect from '../../form/AircraftSelect';
import LocationOnshoreSelect from '../../form/LocationOnshoreSelect';
import PilotSelect from '../../form/PilotSelect';
import WithOrgData, { WithOrgDataProps } from '../../WithOrgData';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
        span: 24,
        offset: 0,
        },
        sm: {
        span: 16,
        offset: 8,
        },
    },
};

const CREATE_FLIGHT = gql`
mutation CreateFlight($payload: FlightNodeInput!, $orgID: ID!){
    setFlight(
        payload: $payload,
        orgID: $orgID
    ){
        _id
    }
}
`

const defaultFormOptions = (initialValue = '', required = true) => ({
    rules: [
        {
            required,
            message: 'This field is required'
        }
    ],
    initialValue
})

export interface NewFlightDrawerProps extends Partial<FormComponentProps>, WithOrgDataProps, DrawerProps {
    onCompleted?(data: any): void
}

const NewFlightDrawer: React.FC<NewFlightDrawerProps> = (props) => {

    const { onCompleted, orgData, form, ...restProps } = props;
    
    const [ createFlight, { loading, error } ] = useMutation(CREATE_FLIGHT, {
        onCompleted: (data) => props.onCompleted && props.onCompleted(data && data.setFlight)
    })

    function submit(e: React.FormEvent<HTMLFormElement>){
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                createFlight({
                    variables: {
                        payload: {
                            ...values,
                            contractID: values.contractID,
                            customerID: orgData.getOrgIDByType('customer'),
                            tpID: orgData.getOrgIDByType('transporter'),
                            lastKnownController: values.lastKnownController,
                            departureID: values.departureID.key,
                            scheduledFlightDate: moment(values.scheduledFlightDate).format('YYYY-MM-DD')
                        },
                        orgID: orgData.getActiveOrgID()
                    }
                })
            }
        });
    }
    function renderContent(){
        const { getFieldDecorator, getFieldValue } = form;
        return (
            <Form onSubmit={submit}>
            {error && <Alert type="error" message="Failed to create flight" description={error.message} showIcon />}
                <Form.Item {...formItemLayout} label="Scheduled Flight Date">
                    {getFieldDecorator('scheduledFlightDate', defaultFormOptions(undefined))(
                        <DatePicker disabledDate={current => current < moment().startOf('day')} format="YYYY-MM-DD" />
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Flight Designation">
                    {getFieldDecorator('desig', defaultFormOptions())(
                        <Input />
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Controlling Heliport">
                    {getFieldDecorator('lastKnownController', defaultFormOptions())(
                        <LocationOnshoreSelect />
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Departure">
                    {getFieldDecorator('departureID', defaultFormOptions())(
                        <LocationSelect labelInValue />
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Aircraft">
                    {getFieldDecorator('aircraftID', defaultFormOptions())(
                        <AircraftSelect />
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Contract">
                    {getFieldDecorator('contractID', defaultFormOptions())(
                        <ContractSelect
                            orgType="customer"
                            placeholder="Choose a contract"
                        />
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Pilot">
                    {getFieldDecorator('pilotID', defaultFormOptions('', false))(
                        <PilotSelect />
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Co-Pilot">
                    {getFieldDecorator('copilotID', defaultFormOptions('', false))(
                        <PilotSelect />
                    )}
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Row type="flex" gutter={6}>
                        <Col>
                            <Button htmlType="submit" loading={loading} type="primary">Create Flight</Button>
                        </Col>
                        <Col>
                            <Button onClick={() => form.resetFields()}>Reset</Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        )
    }
    return <Drawer
        title="Create New Flight"
        closable
        width="35rem"
        {...restProps}
    >
        {renderContent()}
    </Drawer>
}

export default Form.create<NewFlightDrawerProps & FormComponentProps>({
    onValuesChange:(props, changedValues, allValues) => {
        if(changedValues["lastKnownController"]){
            props.form.setFieldsValue({"contractID": ""})
        }
    }})(WithOrgData(NewFlightDrawer))