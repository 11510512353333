import { Cell, Column, RenderMode, SelectionModes, Table } from '@blueprintjs/table';
import { Alert, Button, Col, Divider, Icon, Input, Row, Tooltip } from 'antd';
import Checkbox from 'antd/lib/checkbox';
import { getLabelInValueKey } from 'common/form';
import { useColWidths } from 'common/table';
import { PersonColumnSet } from 'common/table/blueprintcolumns';
import { safeGet } from 'common/util';
import BlueprintTableInfiniteScroller from 'components/BlueprintTableInfiniteScroller';
import EmployerSelectWithData from 'components/form/select/EmployerSelect';
import { OrganizationSelect } from 'components/form/select/OrganizationSelect';
import NonIdealState from 'components/NonIdealState';
import WithOrgData, { WithOrgDataProps } from 'components/WithOrgData';
import gql from 'graphql-tag';
import useControlledState from 'hooks/useControlledState';
import { useMultiSelectByID } from 'hooks/useMultiSelectByID';
import { compact } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { LoadingIndicator, shouldRenderLoadingIndicator } from '..';
import { SearchProps } from '../interface';
import '../search.less';
import SelectionCount from '../SelectionCount';

const PERSON_SEARCH_QUERY = gql`
query PersonSearch(
    $filter: PersonFilterInput
    $advancedFilter: [FilterByKeyValueInput!]
    $advancedFilterOr: [FilterByKeyValueInput!]
    $search: [FilterByKeyValueInput!]
    $bookmark: String
    $limit: Int!
    $skip: Int
) {
    persons(
        filter: $filter
        bookmark: $bookmark
        advancedFilter: $advancedFilter
        advancedFilterOr: $advancedFilterOr
        search: $search
        limit: $limit
        skip: $skip
    ){
        bookmark
        docs {
            _id
            ... on Person {
                lastName
                firstName
                extraBroadState
                customerID {
                    _id
                    name
                }
                employerID {
                    _id
                    name
                }
                dob
                nation
                contactInfo {
                    phone {
                        number
                        type
                    }
                }
                python_backend_isNonPreferred
                lastPaxWeight
                lastBagWeight
                lastBagCount
            }
        }
    }
}
`

export interface SearchState {
    lastName?: string,
    firstName?: string,
    offset: number
}

export const initialSearchState: SearchState = {
    lastName: null,
    firstName: null,
    offset: 0
}

interface LabelInValue {
    key: string,
    label: string
}

export interface AdvancedFilterState {
    customer?: LabelInValue[],
    employer?: LabelInValue[]
}

export const initialAdvancedFilterState: AdvancedFilterState = {
    customer: [],
    employer: []
}

export interface PersonSearchProps extends SearchProps, WithOrgDataProps, React.HTMLAttributes<HTMLDivElement> {
    filterResults?(rows: any[]): any[],
    showCustomerColumn?: boolean,
    showEmployerColumn?: boolean,

}

const PersonSearch: React.FC<PersonSearchProps> = ({
    pageLimit = 50,
    onSelection,
    filterOptions,
    selectedItems: selectedItemsProps,
    orgData,
    showSelectedItemsCount = true,
    searchState: searchStateProp,
    onSearchStateChange,
    excludeCarrier,
    showCustomerColumn = true,
    showEmployerColumn = true,
    filterResults,
    ...restProps
}) => {
    const [searchState, setSearchState] = useControlledState<SearchState>(initialSearchState, searchStateProp, onSearchStateChange);
    const { isSelected, toggleSelection, selectedItems, clearAll } = useMultiSelectByID({ selectedItems: selectedItemsProps, onSelection });
    const [showMoreFilters, setShowMoreFilters] = useState<boolean>(false);
    const [advFilterState, setAdvFilterState] = useState<AdvancedFilterState>(initialAdvancedFilterState);

    let colCount = 2;

    if (showCustomerColumn) colCount++;
    if (showEmployerColumn) colCount++;

    useEffect(() => {
        if (!showEmployerColumn && advFilterState.employer.length) {
            setAdvFilterState({ employer: [] });
        }
        if (!showCustomerColumn && advFilterState.customer.length) {
            setAdvFilterState({ customer: [] });
        }
        if (!showEmployerColumn && !showCustomerColumn && showMoreFilters) {
            setShowMoreFilters(false);
        }
        // eslint-disable-next-line
    }, [showEmployerColumn, showCustomerColumn])

    const inputDirty = (
        searchState.lastName ||
        searchState.firstName ||
        advFilterState.employer.length ||
        advFilterState.customer.length
    ) ? true : false;

    function getAdvFilterValues(): { [key: string]: string[] } {
        let keys = Object.keys(advFilterState);
        let keyValues = {};
        keys.forEach(key => {
            let value = advFilterState[key];
            if (Array.isArray(value)) {
                keyValues[key] = value.map(valItem => getLabelInValueKey(valItem))
            }
            else {
                return;
            }
        })
        return keyValues
    }

    function hideMoreFilters() {
        setShowMoreFilters(false);
    }

    let variables = {
        filter: {
            lastName: searchState.lastName,
            firstName: searchState.firstName,
            employer: null,
            customer: null
        },
        limit: pageLimit,
        advancedFilter: null
    }

    const advFilterValues = getAdvFilterValues();
    variables.advancedFilter = [];
    if (advFilterValues.employer.length) {
        variables.filter.employer = advFilterValues.employer;
    }
    if (advFilterValues.customer.length) {
        variables.filter.customer = advFilterValues.customer;
    }

    const queryResult = useQuery(PERSON_SEARCH_QUERY, {
        variables,
        fetchPolicy: 'cache-and-network',
        skip: !inputDirty,
        context: {
            debounceKey: 'personsearch',
            debounceTimeout: 1000
        }
    })

    function handleRefresh() {
        setSearchState({ ...searchState, offset: 0 });
        queryResult.refetch()
    }

    function handleInputValueChange(key: string, value: string) {
        setSearchState({ ...searchState, [key]: value })
    }

    function handleInputPressEnter(key: string, value: string) {
        setSearchState({ ...searchState, [key]: value })
    }

    const [colWidths, , updateColWidth] = useColWidths(colCount, { 0: 40 }, 200);

    function renderTable() {
        const { data, error, networkStatus, fetchMore } = queryResult;
        if (shouldRenderLoadingIndicator(networkStatus)) {
            return <LoadingIndicator />
        }

        if (!inputDirty || (!data && networkStatus > 3 && !error)) {
            return <NonIdealState icon="search" title="Search for personnel by typing into the fields above" />
        }

        if (error) return <Alert
            banner
            type="error"
            showIcon
            message="Failed to search personnel"
            description={error.message}
        />

        let rows: any[] = safeGet(['persons', 'docs'], data);
        if (!Array.isArray(rows)) {
            rows = [];
        }
        if (!rows.length) {
            return <NonIdealState icon="question" title="No Results" />
        }

        if (filterResults) {
            rows = filterResults(rows)
        }
        console.log('------');
        const ColumnSet = new PersonColumnSet(rows);
        let colNames = [];
        if (showEmployerColumn) {
            colNames.push('employerName');
        }
        if (showCustomerColumn) {
            colNames.push('customerName');
        }
        let columns = [
            <Column key="select" name="" cellRenderer={idx => {
                const record = rows[idx];
                if (record) {
                    let checked = isSelected ? isSelected(record._id) : false
                    console.log('checked: ', checked);
                    return <Cell interactive>
                        <Checkbox
                            checked={checked}
                            onClick={() => {
                                if (toggleSelection) {
                                    toggleSelection(record._id);
                                }
                            }}
                        />
                    </Cell>
                }
                return <Cell />
            }} />,
            <Column
                key='personName'
                name='Name'
                cellRenderer={(idx) => {
                    let row = rows[idx];
                    let nonPrefIcon = null;

                    if (row.python_backend_isNonPreferred) {
                        nonPrefIcon = <Icon type='warning' theme='filled' style={{ color: 'red', float: 'right', marginTop: '3px' }} />
                    }

                    return <Cell intent={nonPrefIcon ? 'danger' : undefined}>
                        <Tooltip title={nonPrefIcon ? 'This person is NON-PREFERRED' : ''}>
                            <div>
                                {row.lastName + ' ' + row.firstName}
                                {nonPrefIcon}
                            </div>
                        </Tooltip>
                    </Cell>
                }}
            />,
            ...ColumnSet.some(colNames)
        ]
        return <BlueprintTableInfiniteScroller
            loading={queryResult.loading}
            hasMore={rows.length >= pageLimit + searchState.offset}
            loadMore={() => {
                let nextOffset = searchState.offset + pageLimit;
                return fetchMore({
                    variables: {
                        skip: nextOffset
                    },
                    updateQuery: (prev: any, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        setSearchState({ ...searchState, offset: nextOffset });
                        return {
                            ...prev,
                            persons: {
                                ...prev.persons,
                                docs: [
                                    ...prev.persons.docs,
                                    ...fetchMoreResult.persons.docs
                                ]
                            }
                        }
                    }
                })
            }}
        >
            <Table
                className="mc-table"
                numRows={rows.length}
                selectionModes={SelectionModes.NONE}
                renderMode={RenderMode.NONE}
                columnWidths={colWidths}
                onColumnWidthChanged={updateColWidth}
                numFrozenColumns={1}
                selectedRegions={compact(rows.map((row, idx) => {
                    if (isSelected(row._id)) {
                        return {
                            rows: [idx, idx]
                        }
                    }
                    return null
                }))}
            >
                {columns}
            </Table>
        </BlueprintTableInfiniteScroller>
    }

    function numAdvFiltersApplied() {
        let values = Object.values(advFilterState);
        values = values.filter(value => {
            if (Array.isArray(value)) {
                return value.length > 0
            }
            return value
        });
        return values.length
    }

    return <div className="search-wrapper" {...restProps}>
        <div className="search-header">
            <Row type="flex">
                <Col style={{ flex: 1 }} className="search-header-col">
                    <div className="search-header-input" style={{ minWidth: '22rem' }}>
                        <Input.Group className="search-header-input-group">
                            <Input
                                placeholder="Last Name"
                                onChange={(e) => handleInputValueChange('lastName', e.target.value)}
                                onPressEnter={(e) => handleInputPressEnter('lastName', e.currentTarget.value)}
                                value={searchState.lastName}
                            />
                            <Input
                                placeholder="First Name"
                                onChange={(e) => handleInputValueChange('firstName', e.target.value)}
                                onPressEnter={(e) => handleInputPressEnter('firstName', e.currentTarget.value)}
                                value={searchState.firstName}
                            />
                        </Input.Group>
                    </div>
                    {showMoreFilters && (showCustomerColumn || showEmployerColumn) ? (
                        <>
                            {showCustomerColumn ? (
                                <div style={{ marginTop: '0.4rem' }}>
                                    <div style={{ marginBottom: '3px' }}>Filter customers</div>
                                    <OrganizationSelect
                                        mode="multiple"
                                        classTypes={['flytsuite.customer']}
                                        className="search-header-input"
                                        style={{ maxWidth: '22rem' }}
                                        value={advFilterState.customer}
                                        labelInValue
                                        onChange={(value: LabelInValue[]) => setAdvFilterState({ ...advFilterState, customer: value })}
                                    />
                                </div>
                            ) : null}
                            {showEmployerColumn ? (
                                <div style={{ marginTop: '0.4rem' }}>
                                    <div style={{ marginBottom: '3px' }}>Filter employers</div>
                                    <EmployerSelectWithData
                                        mode="multiple"
                                        value={advFilterState.employer}
                                        onChange={(e) => setAdvFilterState({ ...advFilterState, employer: e })}
                                        style={{ maxWidth: '22rem' }}
                                        className="search-header-input"
                                        placeholder="Search by name"
                                        labelInValue
                                    />
                                </div>
                            ) : null}
                        </>
                    ) : null}
                </Col>
                <Col className="search-header-col">
                    <Button onClick={handleRefresh}>{<Icon type="sync" spin={queryResult.networkStatus <= 4} />}</Button>
                </Col>
            </Row>
            <Row type="flex">
                <Col style={{ flex: 1, paddingTop: 0 }} className="search-header-col">
                    <Button className="mc-link-btn" onClick={(e) => {
                        e.preventDefault();
                        setSearchState({
                            ...searchState,
                            lastName: null,
                            firstName: null
                        });
                        setAdvFilterState({
                            ...advFilterState,
                            employer: []
                        })
                    }}>Clear</Button>
                    {(showCustomerColumn || showEmployerColumn) ? (
                        <>
                            <Divider type="vertical" />
                            {!showMoreFilters ? (
                                <Button className="mc-link-btn" onClick={(e) => {
                                    e.preventDefault();
                                    setShowMoreFilters(true);
                                }}>Show advanced filters {numAdvFiltersApplied() > 0 ? `(${numAdvFiltersApplied()} applied)` : null}</Button>
                            ) : (
                                <Button className="mc-link-btn" onClick={(e) => {
                                    e.preventDefault();
                                    hideMoreFilters();
                                }}>Hide advanced filters</Button>
                            )}
                        </>
                    ) : null}
                </Col>
                {selectedItems.length > 0 ? (
                    <Col className="search-header-col" style={{ paddingTop: 0 }}>
                        <SelectionCount items={selectedItems} onClear={() => clearAll()} />
                    </Col>
                ) : null}
            </Row>
        </div>
        <div className="search-body">
            {renderTable()}
        </div>
    </div>
}

export default WithOrgData(PersonSearch)