import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import LoadingContent from './LoadingContent';
import { Alert, List, Checkbox, Icon, Input, Row, Col, Button } from 'antd';
import _ from 'lodash';

const SEARCH_LOCATIONS = gql`
query SearchLocations(
    $query: String!
    $limit: Int
    $bookmark: String
){
    searchLocations(
        query: $query
        limit: $limit
        bookmark: $bookmark
    ){
        bookmark
        docs {
            _id
            ... on Location {
                name
            }
        }
    }
}
`

const SearchPrompt = () => (
    <div style={{textAlign: 'center', marginTop: '10rem', color: 'grey'}}>
        <Icon type="search" style={{fontSize: '4rem', marginBottom: '1rem'}} />
        <div>Search for locations by typing into the search bar</div>
    </div>
)

const default_limit = 15;

class LocationResults extends Component {
    state = {
        error: null,
        selected: []
    }
    componentDidUpdate(prevProps){
        const { selected } = this.props;
        if (Array.isArray(selected) && Array.isArray(prevProps.selected)){
            if (selected.length !== prevProps.selected.length){
                this.setState({ selected })
            }
        }
    }
    onSelectedChange = (locationID, checked) => {
        var selected;
        if (checked) {
            selected = _.union(this.state.selected, [locationID])
        }else{
            selected = this.state.selected.filter(item => item !== locationID)
        }
        this.setState({ selected })
        if (this.props.onChange){
            this.props.onChange(selected)
        }
    }
    clearSelected = () => this.setState({ selected: [] })
    render (){
        const { searchLocations, loading, error, networkStatus } = this.props.data;
        const isFetchingMore = networkStatus === 3;
        if (loading && !isFetchingMore) return <LoadingContent text="Searching" style={{ margin: '5rem 0' }} />;
        if (error) return (
            <Alert 
                type="error"
                message="An error occured"
                description="An error occured while searching locations"
                showIcon />
        )
        return (
            <React.Fragment>
                <Row type="flex">
                    <Col style={{ flex: 1 }} >
                        {this.state.selected.length ? (
                            <div>
                                <span>{this.state.selected.length} Selected</span>
                                <Button className="mc-link-btn" style={{ marginLeft: 6 }} onClick={this.clearSelected} >Clear</Button>
                            </div>
                        ) : null}
                    </Col>
                    {
                        this.props.action && (
                            <Col>
                                {this.props.action}
                            </Col>
                        )
                    }
                </Row>
                <div style={{ maxHeight: '40rem', overflow: 'auto' }} >
                    <List 
                        size="small" 
                        dataSource={searchLocations.docs}
                        loadMore={this.onLoadMore}
                        loading={isFetchingMore}
                        renderItem={(location) => {
                            const isChecked = this.state.selected.find(item => item === location._id) ? true : false
                            return (
                                <List.Item>
                                    <Checkbox checked={isChecked} onChange={(e) => this.onSelectedChange(location._id, e.target.checked)} />    
                                    <div style={{ marginLeft: '1rem' }}>
                                        {location.name}
                                    </div>
                                </List.Item>
                            )
                        }} />
                    {searchLocations.docs.length >= (this.props.limit || default_limit) ? <div style={{ textAlign: 'center', marginTop: 12, height: 32, lineHeight: '32px' }}>
                        <Button loading={isFetchingMore} onClick={this.props.onLoadMore}>More</Button>
                    </div> : null}
                </div>
            </React.Fragment>
        )
    }
}

const LocationResultsWithData = graphql(
    SEARCH_LOCATIONS,
    {
        options: props => ({
            variables: {
                query: props.query,
                limit: props.limit || default_limit,
                bookmark: props.bookmark
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }),
        props: (props) => ({
            ...props,
            onLoadMore: () => {
                const { fetchMore, searchLocations: { bookmark } } = props.data;
                fetchMore({
                    variables: {
                        bookmark
                    },
                    updateQuery: (prevResult, { fetchMoreResult }) => { 
                        return {
                            ...prevResult,
                            searchLocations: {
                                __typename: 'MangoQueryResult',
                                bookmark: fetchMoreResult.searchLocations.bookmark,
                                docs: [...prevResult.searchLocations.docs, ...fetchMoreResult.searchLocations.docs]
                            }
                        }
                    }
                })
            }
        })
    }
)(LocationResults)

class LocationSearch extends Component {
    state = {
        query: '',
        error: null
    }
    componentDidCatch(error) {
        this.setState({ error })
    }
    onSearch = (query) => {
        this.setState({ query, error: null })
    }
    renderContent = () => {
        if (this.state.error){
            return (
                <Alert 
                    type="error"
                    message="An error occured"
                    description="An error occured while searching locations"
                    showIcon
                />
            )
        }
        if (this.state.query){
            return (
                <LocationResultsWithData
                    query={this.state.query}
                    limit={15}
                    {...this.props}
                />
            )
        }else{
            return this.props.searchPrompt || <SearchPrompt />
        }
    }
    render() {
        return (
            <div style={{padding: 24}}>
                <Input.Search placeholder="Search for locations" onSearch={this.onSearch} enterButton style={{ marginBottom: '1rem' }} />
                {this.renderContent()}
            </ div>
        )
    }
}
 
export default LocationSearch