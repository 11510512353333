import { Button, Tooltip } from 'antd';
import { momentSorter } from 'common/util-ts';
import FlightState from 'components/flight/FlightState';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import BoardingRestrictionButton from '../../components/boarding-restrictions/BoardingRestrictionButton';
import DestinationList from '../../components/DestinationList';
import IsnCheckStatusButton from '../../components/IsnCheckStatusButton';
import { getFlightDeparture, momentOrNull, paxHasRestriction } from '../util';
import ColumnSet from './columnset';
import './style.less';

const INVALID_REFERENCE = 'INVALID REFERENCE';

const NotFound = <span style={{color: 'red'}}>Not Found</span>

export const replaceColumn = (columns, key, obj) => {
    return columns.map(col => {
        if (col.key === key){
            return {
                ...col,
                ...obj
            }
        }
        return col
    })
}

const personName = {
    title: 'Name',
    key: 'name',
    render: (_, record={}) => {
        let personID = record.personID;
        if (personID){
            var xbr = personID.extraBroadState;
        }
        // Uncomment to add extraBroadState functionality to personnel classTypes
        // else
        // {
        //     xbr = record.extraBroadState;
        // }
        if(xbr === 'FAIL'){
            return `\u2194 ${record.lastName}, ${record.firstName}`
        }else if(xbr === 'PASS'){
            return `\uc6c3 ${record.lastName}, ${record.firstName}`
        }
        return `${record.lastName}, ${record.firstName}`
    }
}

const employer = {
    title: 'Employer',
    key: 'employer',
    render: (_, record) => record.employerID ? record.employerID.name : NotFound
}

const location = {
    title: 'Location',
    key: 'location',
    render: (_, record) => record.locationID ? record.locationID.name : NotFound
}

const departure = {
    title: 'Departure',
    key: 'departure',
    render: (_, record) => record.departureID ? record.departureID.name : NotFound
}

const destination = {
    title: 'Destination',
    key: 'destination',
    render: (_, record) => record.destinationID ? record.destinationID.name : NotFound
}

const name = {
    title: 'Name',
    key: 'name',
    dataIndex: 'name'
}

const designation = {
    title: 'Designation',
    key: 'desig',
    dataIndex: 'desig'
}

const passengers = {
    title: 'Passengers',
    key: 'passengers',
    render: (_, record) => record.paxIDList ? record.paxIDList.length : 0
}

const isn = {
    title: 'ISN Status',
    key: 'isnStatus',
    render: (_, record, index, onClick) => {
        if (record.transitType !== 'INBOUND'){
            return <IsnCheckStatusButton block pax={record} size="small" onClick={onClick} />
        }
        return null
    }
}

const assignedFlight = {
    title: 'Assigned Flight',
    key: 'assignedFlight',
    dataIndex: 'currentCarrierID',
    sorter: (a, b) => {
        let aCarr = ((a.currentCarrierID?.desig)+'').toLowerCase();
        if (!a.currentCarrierID?.desig) aCarr = 'aaa';
        let bCarr = ((b.currentCarrierID?.desig)+'').toLowerCase();
        if (!b.currentCarrierID?.desig) bCarr = 'aaa';

        if (aCarr < bCarr) return -1
        if (aCarr > bCarr) return 1
        return 0
    },
    render: flightID => {
        if (flightID) {
            if (flightID.desig === INVALID_REFERENCE){
                return <span style={{ color: 'red' }} >INVALID REFERENCE</span>
            }
            return <Link to={`/app/flights/${flightID._id}`} >{flightID.desig}</Link>
        }
    }
}

const modified = {
    title: 'Last Modified',
    key: 'modifiedTs',
    dataIndex: 'modifiedTs',
    render: (text) => text && moment(text).format('YYYY-MM-DD HH:mm'),
    sorter: (a, b) => {
        if (a.modifiedTs > b.modifiedTs) return -1
        if (a.modifiedTs < b.modifiedTs) return 1
        return 0
    }
}

const transitType = {
    title: 'Transit Type',
    key: 'transitType',
    dataIndex: 'transitType',
    filters: [
        {
            text: 'Outbound',
            value: 'OUTBOUND'
        },
        {
            text: 'Inbound',
            value: 'INBOUND'
        },
        {
            text: 'Transfer',
            value: 'TRANSFER'
        },
    ],
    onFilter: (value, record) => record.transitType.indexOf(value) === 0,
    sorter: (a, b) => {
        if (a.transitType < b.transitType) return -1
        if (a.transitType > b.transitType) return 1
        return 0
    },
    render: (tt) => {
        switch (tt) {
            case 'OUTBOUND':
                return 'Outbound'
            case 'INBOUND':
                return 'Inbound'
            case 'TRANSFER':
                return 'Transfer'
            default:
                return null;
        }
    }
}

const departureSorter = (a, b) => {
    return ('' + (a.departureID && a.departureID.name).toLowerCase()).localeCompare('' + (b.departureID && b.departureID.name).toLowerCase())
}

const destinationSorter = (a, b) => {
    return ('' + (a.destinationID && a.destinationID.name).toLowerCase()).localeCompare('' + (b.destinationID && b.destinationID.name).toLowerCase())   
}

const parseLegs = (flight) => {
    if (flight.legs){
        try{
            return Object.values(JSON.parse(flight.legs));
        }catch(error){
            console.error('Could not parse flight legs');
        }
    }
    return []
}

const getFlightDepartureWeight = (flight) => {
    const legs = parseLegs(flight);
    if (legs.length){
        const { paxWeight, cgoWeight, bagWeight } = legs[0]
        return Number(paxWeight) || 0 + Number(cgoWeight) || 0 + Number(bagWeight) || 0
    }
    return 0
}

// const getFlightDeparture = (flight) => {
//     if (flight.departureID){
//         return flight.departureID.name
//     }else{
//         const legs = parseLegs(flight);
//         if (legs.length){
//             return legs[0].departure
//         }
//     }
//     return null
// }

// const getAll = (object) => {
//     const columns = Object.values(object)
//     return columns.filter(column => column.key)
// }

// const getSome = (object, cols) => {
//     const columns = Object.entries(object)
//     const filtered = columns.filter(column => cols.includes(column[0]))
//     return filtered.map(column => column[1])
// }

// const getExcept = (object, cols) => {
//     const columns = Object.entries(object)
//     const filtered = columns.filter(column => !cols.includes(column[0] && column.key))
//     return filtered.map(column => column[1])
// }

class PaxAndCgoColumns extends ColumnSet {
    columns={
        name: {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            defaultSortOrder: 'ascend',
            useSearchFilter: true,
            width: 150,
            onFilter: (value, record) => {
                if(record.lastName){
                    if (typeof record.lastName === 'string') return record.lastName.toLowerCase().includes(value.toLowerCase())
                    if (typeof record.firstName === 'string') return record.firstName.toLowerCase().includes(value.toLowerCase())
                }else {
                    if (typeof record.name === 'string') return record.name.toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                var aName;
                if(a.lastName){
                    aName = `${a.lastName}, ${a.firstName}`
                }else {
                    aName = a.name
                }

                var bName;
                if(b.lastName){
                    bName = `${b.lastName}, ${b.firstName}`
                }else {
                    bName = b.name
                }
                if (aName < bName) return -1
                if (aName > bName) return 1
                return 0
            },
            render: (_, record={}) => {
                if(record.lastName){
                    return `${record.lastName}, ${record.firstName}`
                }

                return record.name
            }
        },
        departure: {
            ...departure,
            onFilter: (value, record) => {
                if (record.departureID && typeof record.departureID.name === 'string') return record.departureID.name.toLowerCase().includes(value.toLowerCase())
            },
            getValue: ({ departureID }) => departureID && departureID.name,
            width: 150,
            sorter: departureSorter
        },
        destination: {
            ...destination,
            onFilter: (value, record) => {
                if (record.destinationID && typeof record.destinationID.name === 'string') return record.destinationID.name.toLowerCase().includes(value.toLowerCase())
            },
            getValue: ({ destinationID }) => destinationID && destinationID.name,
            width: 150,
            sorter: destinationSorter
        },
    }
}

class PassengerColumns extends ColumnSet {
    flightColorMap = {}
    colors = [
        ['#ffe119', 'black'],
        ['#7bc908', 'black'],
        ['#18ad2a', 'white'],
        ['#15c0d3', 'white'],
        ['#1890ff', 'white'],
        ['#D54799', 'white'],
        ['#FF7700', 'white']
    ]
    getColor = (flightID) => {
        const flightColorMapID = flightID || 'no_flight';
        var colors = [...this.colors];
        const flightKeys = Object.keys(this.flightColorMap);
        var colorIndex = flightKeys.findIndex(flt => flt === flightColorMapID);
        if (colorIndex < 0){
            colorIndex = flightKeys.length
        }
        
        while (colorIndex > colors.length - 1){
            colors = colors.concat(colors);
        }
        if (!this.flightColorMap[flightColorMapID]){
            this.flightColorMap[flightColorMapID] = colors[colorIndex];
        }
        return this.flightColorMap[flightColorMapID]
    }
    columns = {
        assignedFlight: {
            ...assignedFlight,
            useSearchFilter: false,
            width: 175,
            onFilter: (value, record) => {
                if (record.currentCarrierID && typeof record.currentCarrierID.desig === 'string') return record.currentCarrierID.desig.toLowerCase().includes(value.toLowerCase())
            },
            getColor: this.getColor,
            getValue: ({ currentCarrierID }) => currentCarrierID && currentCarrierID.desig,
            render: (flight, record) => {
                const color = this.getColor(flight && flight._id);
                if (!flight){
                    return null
                }
                return <Link tabIndex={-1} to={`/app/flights/${flight._id}`} >
                    <Button tabIndex={-1} size="small" block style={{ background: color[0], borderColor: color[0], color: color[1] }} >{flight && flight.desig}</Button>
                </Link>
            }
        },
        name: {
            ...personName,
            useSearchFilter: true,
            width: 150,
            onFilter: (value, record) => {
                if (typeof record.lastName === 'string') return record.lastName.toLowerCase().includes(value.toLowerCase())
                if (typeof record.firstName === 'string') return record.firstName.toLowerCase().includes(value.toLowerCase())
            },
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                const aName = `${a.lastName}, ${a.firstName}`
                const bName = `${b.lastName}, ${b.firstName}`
                if (aName < bName) return -1
                if (aName > bName) return 1
                return 0
            }
        },
        customer: {
            title: 'Customer',
            key: 'customer',
            width: 150,
            render: (_, record) => record.customerID && (record.customerID.name.length > 17 ? record.customerID.name.substring(0,17) + "..." : record.customerID.name),
            onFilter: (value, record) => {
                if (record.customerID && typeof record.customerID.name === 'string') return record.customerID.name.toLowerCase().includes(value.toLowerCase())
            },
            getValue: ({ customerID }) => customerID && customerID.name,
            sorter: (a, b) => {
                const aCus = a.customerID ? a.customerID.name : null
                const bCus = b.customerID ? b.customerID.name : null
                if (aCus < bCus) return -1
                if (aCus > bCus) return 1 
            }
        },
        transporter: {
            title: 'Transporter',
            key: 'transporter',
            width: 150,
            render: (_, record) => record.tpID && record.tpID.name,
            onFilter: (value, record) => {
                if (record.tpID && typeof record.tpID.name === 'string') return record.tpID.name.toLowerCase().includes(value.toLowerCase())
            },
            getValue: ({ tpID }) => tpID && tpID.name,
            sorter: (a, b) => {
                const aCus = a.tpID ? a.tpID.name : null
                const bCus = b.tpID ? b.tpID.name : null
                if (aCus < bCus) return -1
                if (aCus > bCus) return 1 
            }
        },
        employer: {
            ...employer,
            render: (_, record) => record.employerID && (record.employerID.name.length > 20 ? record.employerID.name.substring(0,17) + "..." : record.employerID.name),
            width: 150,
            onFilter: (value, record) => {
                if (record.employerID && typeof record.employerID.name === 'string') return record.employerID.name.toLowerCase().includes(value.toLowerCase())
            },
            getValue: ({ employerID }) => employerID && employerID.name,
            sorter: (a, b) => {
                const aEmp = a.employerID ? a.employerID.name : null
                const bEmp = b.employerID ? b.employerID.name : null
                if (aEmp < bEmp) return -1
                if (aEmp > bEmp) return 1 
            }
        },
        weight: {
            title: 'WT',
            key: 'weight',
            width: 50,
            dataIndex: 'paxWeight',
            editable: true,
            inputType: "number",
            render: (text) => text || 0,
            sorter: (a, b) => (a.paxWeight || 0) - (b.paxWeight || 0)
        },
        bagCount: {
            title: '# Bags',
            dataIndex: 'bagCount',
            width: 70,
            key: 'bagCount',
            render: (text) => text || 0,
            sorter: (a, b) => (a.bagCount || 0) - (b.bagCount || 0)
        },
        bagWeight: {
            title: 'Bag WT',
            dataIndex: 'bagWeight',
            width: 50,
            key: 'bagWeight',
            render: (text) => text || 0,
            sorter: (a, b) => (a.bagWeight || 0) - (b.bagWeight || 0)
        },
        checkedIn: {
            title: 'Checked In',
            dataIndex: 'checkedInTime',
            key: 'checkedInTime',
            width: 150,
            render: (checkedInTime) => {
                const m = momentOrNull(checkedInTime)
                if (m && !m.isSameOrBefore(moment('1970-01-01'))){
                    return m.format('YYYY-MM-DD HH:mm');
                }
                return null;
            },
            sorter: (a, b) => momentSorter('checkedInTime', a, b)
        },
        transitType: {
            ...transitType,
            width: 150,
        },
        departure: {
            ...departure,
            render: (_, record) => record.departureID && (record.departureID.name.length > 20 ? record.departureID.name.substring(0,17) + "..." : record.departureID.name),
            onFilter: (value, record) => {
                if (record.departureID && typeof record.departureID.name === 'string') return record.departureID.name.toLowerCase().includes(value.toLowerCase())
            },
            getValue: ({ departureID }) => departureID && departureID.name,
            width: 150,
            sorter: departureSorter
        },
        destination: {
            ...destination,
            render: (_, record) => record.destinationID && (record.destinationID.name.length > 20 ? record.destinationID.name.substring(0,17) + "..." : record.destinationID.name),
            onFilter: (value, record) => {
                if (record.destinationID && typeof record.destinationID.name === 'string') return record.destinationID.name.toLowerCase().includes(value.toLowerCase())
            },
            getValue: ({ destinationID }) => destinationID && destinationID.name,
            width: 150,
            sorter: destinationSorter
        },
        tsa: {
            title: 'TSA',
            key: 'tsa',
            width: 50,
            render: (_, record) => {
                var color = '#d9d9d9';
                var tooltipText = 'Not checked';
                if (record.authorization){
                    color = record.authorization.noFlyListed ? '#f5222d' : '#52c41a'
                    tooltipText = record.authorization.noFlyListed ? 'On No-Fly List' : 'Not On No-Fly List'
                }
                return (
                    <Tooltip title={tooltipText}>
                        <div style={{
                            borderRadius: '14px',
                            height: '14px',
                            width: '14px',
                            background: color
                        }}>
                            <span style={{display: 'none'}}>{tooltipText}</span>
                        </div>
                    </Tooltip>
                )
            }
        },
        restrictions: {
            title: 'Restrictions',
            key: 'restrictions',
            render: (_, record, index, onClick) => <BoardingRestrictionButton tabIndex={-1} block data={record} onClick={onClick && onClick} size="small" />,
            width: 150,
            sorter: (a, b) => {
                let ahr = paxHasRestriction(a);
                let bhr = paxHasRestriction(b);
                if (ahr && !bhr){
                    return -1;
                }
                if (ahr === bhr){
                    return 0;
                }
                return 1;
            }
        },
        isn: {
            ...isn,
            width: 150
        }
    }
}

class EmployerColumns extends ColumnSet {
    columns = {
        name: {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            render: (_, record) => {
                return record.name
            },
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            }
        },
        modified
    }
}

class NonPreferredColumns extends ColumnSet {
    columns = {
        employerName: {
            title: 'Name',
            key: 'employerName',
            render: (_, record) => {
                return record.id.name
            },
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            }
        },
        personName: {
            title: 'Name',
            key: 'name',
            render: (_, record) => {
                let person = record.id;
                if(person){
                    return `${person.lastName}, ${person.firstName}`
                }
                return null;
            }
        },
        employer: {
            title: 'Employer',
            key: 'employer',
            render: (_, record) => record.id.employerID ? record.id.employerID.name : NotFound
        },
        startDate: {
            title: 'Start Date',
            key: 'startDate',
            dataIndex: 'startDate',
            render: (time) => {
                if(time){
                    return moment(time).format('YYYY-MM-DD')
                }
                return null;
            }
        },
        reason: {
            title: 'Reason',
            key: 'reason',
            dataIndex: 'reason',
            render: (_, record) => {
                return <div style={{ maxWidth: '15rem', whiteSpace: 'initial' }}>
                    {record.reason}
                </div>
            }
        }
    }
}

class PersonColumns extends ColumnSet {
    columns = {
        name: {
            ...personName,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                const aName = `${a.lastName}, ${a.firstName}`
                const bName = `${b.lastName}, ${b.firstName}`
                if (aName < bName) return -1
                if (aName > bName) return 1
                return 0
            }
        },
        address: {
            title: 'City, State',
            key: 'citystate',
            render: (_, record) => {
                if (record.contactInfo && record.contactInfo.address){
                    const address = record.contactInfo.address;
                    const citystate = [address.city, address.state].filter(a => a)
                    return citystate.join(', ')
                }
                return '-'
            }
        },
        employer: {
            ...employer,
            sorter: (a, b) => {
                const aEmp = a.employerID ? a.employerID.name : null
                const bEmp = b.employerID ? b.employerID.name : null
                if (aEmp < bEmp) return -1
                if (aEmp > bEmp) return 1 
            }
        }
    }
}

class CargoColumns extends ColumnSet {
    columns = {
        name: {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            defaultSortOrder: 'ascend',
            width: 150,
            sorter: (a, b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            },
            onFilter: (value, record) => {
                if (typeof record.name === 'string') return record.name.toLowerCase().includes(value.toLowerCase())
            },
            useSearchFilter: true
        },
        customer: {
            title: 'Customer',
            key: 'customer',
            width: 150,
            render: (_, record) => record.customerID && record.customerID.name,
            onFilter: (value, record) => {
                if (record.customerID && typeof record.customerID.name === 'string') return record.customerID.name.toLowerCase().includes(value.toLowerCase())
            },
            getValue: ({ customerID }) => customerID && customerID.name,
            sorter: (a, b) => {
                const aCus = a.customerID ? a.customerID.name : null
                const bCus = b.customerID ? b.customerID.name : null
                if (aCus < bCus) return -1
                if (aCus > bCus) return 1 
            }
        },
        checkedIn: {
            title: 'Checked In',
            key: 'checkedIn',
            dataIndex: 'checkedInTime',
            width: 150,
            render: (time) => moment(time).format('YYYY-MM-DD HH:mm:ss'),
            sorter: (a, b) => momentSorter('checkedInTime', a, b)
        },
        departure: {
            ...departure,
            width: 150,
            onFilter: (value, record) => {
                if (record.departureID && typeof record.departureID.name === 'string') return record.departureID.name.toLowerCase().includes(value.toLowerCase())
            },
            getValue: (record) => record.departureID && record.departureID.name,
            sorter: departureSorter
        },
        transitType: {
            ...transitType,
            width: 150,
        },
        destination: {
            ...destination,
            width: 150,
            onFilter: (value, record) => {
                if (record.destinationID && typeof record.destinationID.name === 'string') return record.destinationID.name.toLowerCase().includes(value.toLowerCase())
            },
            getValue: (record) => {
                return record.destinationID && record.destinationID.name
            },
            sorter: destinationSorter
        },
        deliveredBy: {
            title: 'Delivered By',
            key: 'deliveredBy',
            dataIndex: 'deliveredBy',
            width: 150,
            useSearchFilter: true,
            onFilter: (value, record) => {
                if (typeof record.deliveredBy === 'string') return record.deliveredBy.toLowerCase().includes(value.toLowerCase())
            },
            sorter: (a, b) => {
                if (a.deliveredBy < b.deliveredBy) return -1
                if (a.deliveredBy > b.deliveredBy) return 1
                return 0
            }
        },
        attentionTo: {
            title: 'Attention To',
            key: 'attentionTo',
            width: 150,
            dataIndex: 'attentionTo',
            useSearchFilter: true,
            onFilter: (value, record) => {
                if (typeof record.attentionTo === 'string') return record.attentionTo.toLowerCase().includes(value.toLowerCase())
            },
            sorter: (a, b) => {
                if (a.attentionTo < b.attentionTo) return -1
                if (a.attentionTo > b.attentionTo) return 1
                return 0
            }
        },
        approvedBy: {
            title: 'Approved By',
            key: 'approvedBy',
            dataIndex: 'approvedBy',
            defaultSortOrder: 'ascend',
            width: 150,
            sorter: (a, b) => {
                if (a.approvedBy < b.approvedBy) return -1
                if (a.approvedBy > b.approvedBy) return 1
                return 0
            }
        },
        weight: {
            title: 'Weight',
            key: 'weight',
            width: 150,
            dataIndex: 'weight',
            render: (text) => text || 0,
            sorter: (a, b) => (a.weight || 0) - (b.weight || 0)
        },
        hazmat: {
            title: 'Hazmat',
            key: 'hazmat',
            width: 150,
            filters: [
                {
                    text: 'No',
                    value: false
                },
                {
                    text: 'Yes',
                    value: true
                }
            ],
            onFilter: (value, record) => {
                return record.hazmat === (value === 'true')
            },
            filterMultiple: false,
            render: (_, record) => {
                const render = (text) => <span style={{color: 'red'}} >{text}</span>
                if (record.hazmat){
                    if (record.hazmatUnCode){
                        return render(record.hazmatUnCode)
                    }else{
                        return render('Yes (No Code)')
                    }
                }else{
                    return 'No'
                }
            }
        },
        assignedFlight: {
            ...assignedFlight,
            onFilter: (value, record) => {
                if (record.currentCarrierID && typeof record.currentCarrierID.desig === 'string') return record.currentCarrierID.desig.toLowerCase().includes(value.toLowerCase())
            },
            getValue: (record) => record.currentCarrierID && record.currentCarrierID.desig,
            width: 150,
        }
    }
}

class CrewColumns extends ColumnSet {
    columns = {
        name: {
            ...personName,
            render: (_, record) => {
                if (!record.contactInfo) return null
                if (!record.contactInfo.name) return null
                return personName.render(null, record.contactInfo.name)
            },
            width: 200
        },
        location,
        isn: {
            ...isn,
            width: 150
        }
    }
}

class LocationColumns extends ColumnSet {
    columns = {
        name: {
            ...name,
            onFilter: (value, record) => {
                if (typeof record.name === 'string') return record.name.toLowerCase().includes(value.toLowerCase())
            },
            useSearchFilter: true
        },
        ownerOrg: {
            title: 'Organization',
            key: 'ownerOrg',
            dataIndex: 'ownerOrg',
            onFilter: (value, record) => {
                if (record.ownerOrg && typeof record.ownerOrg === 'string') return record.ownerOrg.toLowerCase().includes(value.toLowerCase())
            },
            useSearchFilter: true
        },
        type: {
            title: 'Type',
            key: 'type',
            dataIndex: 'type',
            onFilter: (value, record) => ('' + record.type).toUpperCase() === value,
            filterMultiple: false,
            filters: [
                {
                    text: 'ONSHORE',
                    value: 'ONSHORE'
                },
                {
                    text: 'OFFSHORE',
                    value: 'OFFSHORE'
                }
            ]
        },
        block: {
            title: 'Area',
            key: 'block',
            dataIndex: 'block'
        },
        field: {
            title: 'Block',
            key: 'field',
            dataIndex: 'field'
        },
        modified
    }
}

class FlightColumns extends ColumnSet {
    columns = {
        designation: {
            ...designation,
            useSearchFilter: true,
            width: 200,
            onFilter: (value, record) => {
                if (typeof record.desig === 'string') return record.desig.toLowerCase().includes(value.toLowerCase())
            },
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                if (a.desig < b.desig) return -1
                if (a.desig > b.desig) return 1
                return 0
            }
        },
        departureWeight: {
            title: 'Departure Weight',
            key: 'departureWeight',

            width: 150,
            render: (_, record) => getFlightDepartureWeight(record),
            sorter: (a, b) => {
                const aDep = getFlightDepartureWeight(a)
                const bDep = getFlightDepartureWeight(b)
                return aDep - bDep
            }
        },
        passengers: {
            ...passengers,
            width: 150
        },
        departure: {
            title: 'Departure',
            key: 'departure',
            dataIndex: 'departureID',
            width: 150,
            onFilter: (value, record) => {
                try{
                    const departure = getFlightDeparture(record);
                    if (departure && typeof departure.name === 'string') return departure.name.toLowerCase().includes(value.toLowerCase())
                }
                catch(error){
                    console.error("An error occurred while filtering flight departure: " + error.message);
                }
            },
            useSearchFilter: true,
            render: (_, record) => {
                const departure = getFlightDeparture(record);
                return departure && departure.name
            },
            sorter: (a, b) => {
                const aDep = getFlightDeparture(a)
                const bDep = getFlightDeparture(b)
                if (aDep < bDep) return -1
                if (aDep > bDep) return 1
                return 0
            }
        },
        destinations: {
            title: 'Destinations',
            key: 'destinations',
            width: 400,
            render: (_, record) => {
                if (record.legs){
                    const legs = Object.values(JSON.parse(record.legs));
                    const destinations = legs.map(leg => leg.destination);
                    return <DestinationList data={destinations} />
                }
            }
        },
        seatPercent : {
            title: 'Seat %',
            key: 'seatPercent',
            width: 150,
            render: (_, record) => {
                if (!record || !record.aircraftID){
                    return null
                }
                if(record.legsArray && record.legsArray[0] && record.aircraftID.maxSeats){
                    const seats = record.aircraftID.maxSeats || 0
                    const paxCount = record.legsArray[0].paxCount

                    const num = ((paxCount/seats)*100).toFixed(2)

                    if(num === 'NaN' || num === 'Infinity' || num < 0){
                        return null
                    }

                    return num
                }

                return null;
            }
        },
        weightPercent : {
            title: 'Weight %',
            key: 'weightPercent',
            width: 150,
            render: (_, record) => {
                if (!record){
                    return null
                }
                if(record.legsArray && record.legsArray[0]){
                    const paxWeight = record.legsArray[0].paxWeight
                    const firstLegUsableWeight = record.firstLegUsableWt || 0

                    const num = ((paxWeight/firstLegUsableWeight)*100).toFixed(2)
                    
                    if(num === 'NaN' || num === 'Infinity' || num < 0){
                        return null
                    }
                    
                    return num
                }

                return null;
            }
        },
        status: {
            title: 'Status',
            key: 'status',
            width: 50,
            render: (_, record) => <FlightState state={record.state} />
        }
    }
}

class BoatColumns extends ColumnSet {
    columns = {
        designation: {
            ...designation,
            width: 150
        },
        passengers: {
            ...passengers,
            width: 150
        },
        crewMembers: {
            title: 'Crew Members',
            key: 'crewMembers',
            width: 150,
            render: (_, record) => record.boatCrewIDs ? record.boatCrewIDs.length : 0
        },
        departures: {
            title: 'Departures',
            key: 'departures',
            width: 150,
            render: (_, record) => {
                if (!record.departureIDs) return null
                const locations = record.departureIDs.map(loc => loc.name);
                return locations.join(', ');
            }
        },
        destinations: {
            title: 'Destinations',
            key: 'destinations',
            width: 150,
            render: (_, record) => {
                if (!record.departureIDs) return null
                const locations = record.destinationIDs.map(loc => loc.name);
                return locations.join(', ');
            }
        }
    }
}

class OnboardPobColumns extends ColumnSet{
    columns = {
        name: {
            ...personName,
            useSearchFilter: true,
            width: 150,
            onFilter: (value, record) => {
                if (typeof record.lastName === 'string') return record.lastName.toLowerCase().includes(value.toLowerCase())
                if (typeof record.firstName === 'string') return record.firstName.toLowerCase().includes(value.toLowerCase())
            },
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                const aName = `${a.lastName}, ${a.firstName}`
                const bName = `${b.lastName}, ${b.firstName}`
                if (aName < bName) return -1
                if (aName > bName) return 1
                return 0
            }
        },
        customer: {
            title: 'Customer',
            key: 'customer',
            width: 150,
            render: (_, record) => record.customerID && record.customerID.name,
            onFilter: (value, record) => {
                if (record.customerID && typeof record.customerID.name === 'string') return record.customerID.name.toLowerCase().includes(value.toLowerCase())
            },
            getValue: ({ customerID }) => customerID && customerID.name,
            sorter: (a, b) => {
                const aCus = a.customerID ? a.customerID.name : null
                const bCus = b.customerID ? b.customerID.name : null
                if (aCus < bCus) return -1
                if (aCus > bCus) return 1 
            }
        },
        employer: {
            ...employer,
            width: 150,
            onFilter: (value, record) => {
                if (record.employerID && typeof record.employerID.name === 'string') return record.employerID.name.toLowerCase().includes(value.toLowerCase())
            },
            getValue: ({ employerID }) => employerID && employerID.name,
            sorter: (a, b) => {
                const aEmp = a.employerID ? a.employerID.name : null
                const bEmp = b.employerID ? b.employerID.name : null
                if (aEmp < bEmp) return -1
                if (aEmp > bEmp) return 1 
            }
        },
        checkedIn: {
            title: 'Check In',
            dataIndex: 'checkedInTime',
            key: 'checkedInTime',
            width: 150,
            render: (checkedInTime) => {
                const m = moment(checkedInTime)
                if (!m.isSameOrBefore(moment('1970-01-01'))){
                    return m.format('YYYY-MM-DD HH:mm:ss');
                }
            },
            sorter: (a, b) => momentSorter('checkedInTime', a, b)
        },
        offshoreCheckedIn: {
            title: 'Rig Check In',
            dataIndex: 'offshoreCheckIn',
            key: 'offshoreCheckIn',
            width: 150,
            render: (offshoreCheckIn) => {
                if(offshoreCheckIn){
                    const m = moment(offshoreCheckIn)
                    if (!m.isSameOrBefore(moment('1970-01-01'))){
                        return m.format('YYYY-MM-DD HH:mm:ss');
                    }
                }
            },
            sorter: (a, b) => momentSorter('offshoreCheckIn', a, b)
        }
    }
}

class RigColumns extends ColumnSet{
    columns = {
        location,
        onboardCount: {
            title: 'Onboard',
            dataIndex: 'onboardCount',
            key: 'onboardCount',
            getValue: ({ onboardCount }) => onboardCount && onboardCount
        },
        inboundCount: {
            title: 'Inbound',
            dataIndex: 'inboundCount',
            key: 'inboundCount',
            getValue: ({ inboundCount }) => inboundCount && inboundCount
        },
        outboundCount: {
            title: 'Outbound',
            dataIndex: 'outboundCount',
            key: 'outboundCount',
            getValue: ({ outboundCount }) => outboundCount && outboundCount
        },
        onboardCargoCount: {
            title: 'Onboard Cargo',
            dataIndex: 'onboardCargoCount',
            key: 'onboardCargoCount',
            getValue: ({ onboardCargoCount }) => onboardCargoCount && onboardCargoCount
        },
        inboundCargoCount: {
            title: 'Inbound Cargo',
            dataIndex: 'inboundCargoCount',
            key: 'inboundCargoCount',
            getValue: ({ inboundCargoCount }) => inboundCargoCount && inboundCargoCount
        },
        outboundCargoCount: {
            title: 'Outbound Cargo',
            dataIndex: 'outboundCargoCount',
            key: 'outboundCargoCount',
            getValue: ({ outboundCargoCount }) => outboundCargoCount && outboundCargoCount
        }
    }
}
class DocksCgoColumns extends ColumnSet{
    columns = {
        name: {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: 150,
            getValue: ({ name }) => name && name,
            sorter: (a, b) => {
                const aCus = a.name ? a.name : null
                const bCus = b.name ? b.name : null
                if (aCus < bCus) return -1
                if (aCus > bCus) return 1 
            }
        },
        assetID: {
            title: 'Asset ID',
            key: 'assetID',
            width: 150,
            getValue: ({ assetID }) => assetID && assetID.name,
            sorter: (a, b) => {
                const aCus = a.assetID ? a.assetID.name : null
                const bCus = b.assetID ? b.assetID.name : null
                if (aCus < bCus) return -1
                if (aCus > bCus) return 1 
            }

        },
        description: {
            title: 'Description',
            key: 'description',
            width: 150,
            dataIndex: 'description',
            getValue: ({ description }) => description && description,      
        },
        liftCount: {
            title: 'Lift Count',
            key: 'lifts',
            width: 150,
            dataIndex: 'lifts',
            getValue: ({ lifts }) => lifts && lifts,
        },
        liftWeight: {
            title: 'Lift Weight',
            key: 'liftWeight',
            width: 150,
            dataIndex: 'liftWeight',
            getValue: ({ liftWeight }) => liftWeight && liftWeight,
        },
        grossWeight: {
            title: 'Gross Weight',
            key: 'totalWeight',
            width: 150,
            dataIndex: 'totalWeight',
            render: (totalWeight, row) => {
                if(totalWeight){
                    return totalWeight
                }
                return row.weight
            },
        },
    }
}

class WorkHistory extends ColumnSet{
    columns = {
        startTime: {
            title: 'Start Time',
            dataIndex: 'startTime',
            key: 'startTime',
            render: (_, record) => {
                const start = moment(record.clockIn)
                return start.format('YYYY-MM-DD HH:mm:ss')
            }
        },
        endTime: {
            title: 'End Time',
            dataIndex: 'endTime',
            key: 'endTime',
            render: (_, record) => {
                const end = moment(record.clockOut)
                return end.format('YYYY-MM-DD HH:mm:ss')
            }
        },
        hours: {
            title: 'Hours',
            dataIndex: 'hours',
            key: 'hours',
            render: (_, record) => {
                    const start = moment.utc(record.clockIn)
                    const end = moment.utc(record.clockOut)
                    const difference = moment.duration(end.diff(start));
                    const hours = difference.asHours().toFixed(2);
                    return hours
                }
        }
    }
}

const commonColumns = {
    employer: new EmployerColumns(),
    person: new PersonColumns(),
    pax: new PassengerColumns(),
    cgo: new CargoColumns(),
    crew: new CrewColumns(),
    paxAndCgo: new PaxAndCgoColumns(),
    location: new LocationColumns(),
    flight: new FlightColumns(),
    boat: new BoatColumns(),
    onboardpob: new OnboardPobColumns(),
    rig: new RigColumns(),
    dockscgo: new DocksCgoColumns(),
    workHist: new WorkHistory(),
    nonPreferred: new NonPreferredColumns()
}

export default commonColumns