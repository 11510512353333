import React, { CSSProperties } from 'react';
import { Form, DatePicker, Input } from 'antd';
import { FormComponentProps, FormProps } from 'antd/lib/form';
import { LocationSelect } from 'components/form/select/LocationSelect';
import { createFormFields } from 'common/form';
import moment from 'moment';

export interface IGroupFormProps extends FormComponentProps {
    formElementProps?: FormProps,
    disabled?: boolean,
    formData?: any,
    onFieldsChange?(fields: any): void,
    onValuesChange?(values: any): void
}

let selectStyle: CSSProperties = {
    minWidth: '10rem'
}

const GroupForm: React.FC<IGroupFormProps> = React.forwardRef((props, ref: React.LegacyRef<Form>) => {
    const { getFieldDecorator } = props.form;
    let formElement = <Form ref={ref} layout="inline" {...props.formElementProps} form={props.form}>
        <Form.Item label="Flight Date">
        {getFieldDecorator('date', {
            rules: [
                { required: true, message: 'You must enter a flight date' }
            ]
        })(<DatePicker disabled={props.disabled} disabledDate={(current) => current && current < moment().startOf('day')} />)}
        </Form.Item>
        <Form.Item label="Schedule Name">
        {getFieldDecorator('name', {
            rules: [
                { required: true, message: 'You must enter a group name' }
            ]
        })(<Input onChange={e => {
            e.target.value = e.target.value.trimStart().replace(/[^\S ]/gm, '')}} disabled={props.disabled} />)}
        </Form.Item>
        <Form.Item label="Controlling Heliport">
        {getFieldDecorator('lastKnownController', {
            rules: [
                { required: true, message: 'You must enter a controlling heliport' }
            ]
        })(<LocationSelect type={"ONSHORE"} disabled={props.disabled} labelInValue style={selectStyle} />)}
        </Form.Item>
        <Form.Item label="Departure Location">
        {getFieldDecorator('departure', {
            rules: [
                { required: true, message: 'You must enter a departure location' }
            ]
        })(<LocationSelect disabled={props.disabled} labelInValue style={selectStyle} />)}
        </Form.Item>
        <Form.Item label="Destination Location">
        {getFieldDecorator('destination', {
            rules: [
                { required: true, message: 'You must enter an off shore location' }
            ]
        })(<LocationSelect disabled={props.disabled} labelInValue style={selectStyle} />)}
        </Form.Item>
    </Form>
    return formElement
})

export default Form.create<IGroupFormProps>({
    mapPropsToFields(props){
        if (!props.formData) return undefined
        return createFormFields(props.formData);
    },
    onFieldsChange(props, _, fields){
        if (props.onFieldsChange){
            props.onFieldsChange(fields);
        }
    },
    onValuesChange(props, _, values){
        if (props.onValuesChange){
            props.onValuesChange(values);
        }
    }
})(GroupForm)