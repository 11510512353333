import React, {useContext, useReducer} from 'react';
import {GlobalFilterWithWrapper} from 'components/GlobalFilter';
import {Alert, Button, Checkbox, Col, Divider, Drawer, Dropdown, Icon, Menu, Row, Spin, Tabs, Tooltip} from 'antd';
import LiveCard from '../LiveCard';
import {NetworkStatus} from 'apollo-boost';
import useAutoPaginatedQuery from 'hooks/apollo-extensions/useAutoPaginatedQuery';
import CARGO_QUERY from './query';
import getGenericStateReducer from 'common/reducer/generic-state-reducer';
import {GlobalFilterContext} from 'context/global-filter';
import {OrgDataContext} from 'context/orgData';
import {fetchMoreAndGetToken} from '../util';
import pluralize from 'pluralize';
import {GlobalAppStateContext} from 'context/global-app-state';
import commonColumns from 'common/table/columns';
import {cleanGraphQLErrorMsg, reactNodeTextGetter} from 'common/util';
import {FilterableTable} from 'components/tables/FilterableTable';
import useUserGroups from 'hooks/useUserGroups';
import CargoSiderTab from 'components/scheduling/PaxCgoScheduler/CargoSiderTab';
import CheckInInboundCargo from 'Mutations/CheckInInboundCargo';
import {useMutation} from 'react-apollo';
import moment from 'moment';
import './style.less';
import withUserInfo from 'components/withUserInfo';
import DataExport from "common/data-export";
import {ColumnProps} from "antd/lib/table";

export interface LiveCargoProps {

}

interface ReducerState {
    activeTab?: string,
    error: any,
    errorClosed: boolean,
    tableSettingsDropdownVisible: boolean,
    drawer: {
        error?: any,
        visible: boolean
    },
    approvalDrawer: {
        error?: any,
        visible: boolean,
        fields: any
    }
}

const DEFUALT_STATE: ReducerState = {
    activeTab: 'outbound',
    error: null,
    errorClosed: false,
    tableSettingsDropdownVisible: false,
    drawer: {
        error: null,
        visible: false
    },
    approvalDrawer: {
        error: null,
        visible: false,
        fields: null
    }
}

const ACTION_TYPES = {
    UPDATE_DRAWER_STATE: 'UPDATE_DRAWER_STATE',
    UPDATE_APPROVAL_DRAWER_STATE: 'UPDATE_APPROVAL_DRAWER_STATE'
}

function reducer(state: ReducerState, type: string, payload: any) {
    switch (type) {
        case ACTION_TYPES.UPDATE_DRAWER_STATE:
            return {
                ...state,
                drawer: {
                    ...state.drawer,
                    ...payload
                }
            }
        case ACTION_TYPES.UPDATE_APPROVAL_DRAWER_STATE:
            return {
                ...state,
                approvalDrawer: {
                    ...state.approvalDrawer,
                    ...payload
                }

            }
        default:
            return state;
    }
}

const LiveCargo: React.FC<LiveCargoProps> = (props: any) => {
    const globalFilter = useContext(GlobalFilterContext);
    const globalAppState = useContext(GlobalAppStateContext);
    const { customer, transporter } = useContext(OrgDataContext);
    const [userGroups] = useUserGroups();
    const { userinfo } = props;
    const [state, dispatch] = useReducer(getGenericStateReducer<ReducerState>(reducer), DEFUALT_STATE);

    const queryVars = {
        filter: {
            customers: globalFilter.customer && globalFilter.customer.length ? globalFilter.customer.map(({ key }) => key) : customer && [customer._id],
            transporter: globalFilter.transporter && globalFilter.transporter.length ? globalFilter.transporter.map(({ key }) => key)[0] : transporter && transporter._id,
            departures: globalFilter.departure && globalFilter.departure.map(({ key }) => key),
            destinations: globalFilter.destination && globalFilter.destination.map(({ key }) => key),
            transitTypes: ['OUTBOUND', 'INBOUND', 'TRANSFER'],
            // groundPickup: getGroundPickup(),
            onlyShowLive: true
        },
        limit: 100
    }

    const {
        data, fetchMore,
        networkStatus, paginating,
        pageCount, refetch,
        error, paginationError
    } = useAutoPaginatedQuery(CARGO_QUERY, {
        variables: queryVars,
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
        pollInterval: 20000,
        supressDataUpdates: true,
        onError: (err) => dispatch({ newState: { error: err, errorClosed: false } }),
        onPaginationError: (err) => dispatch({ newState: { error: err, errorClosed: false } }),
        getNextToken: async (token) => await fetchMoreAndGetToken(fetchMore, queryVars, token, 'cargo'),
        getTokenFromData: (data) => {
            let token = data?.cargo?.bookmark;
            if (token === 'nil') {
                token = null;
            }
            return token;
        }
    })

    const [setCargoNode, { loading: cargoSubmitting }] = useMutation(CheckInInboundCargo, {
        onCompleted: () => {
            dispatch({
                newState: {
                    activeTab: state.approvalDrawer.visible ? "outbound" : "inbound",
                    drawer: {
                        ...state.drawer,
                        visible: false
                    },
                    approvalDrawer: {
                        ...state.approvalDrawer,
                        fields: null,
                        visible: false
                    }
                }
            })
            refetch();
        },
        onError: (error) => {
            dispatch({
                type: ACTION_TYPES.UPDATE_DRAWER_STATE,
                payload: {
                    error
                }
            })
            dispatch({
                type: ACTION_TYPES.UPDATE_APPROVAL_DRAWER_STATE,
                payload: {
                    error
                }
            })
        }
    });

    const cargo = (data?.cargo?.docs) || []
    let outbound = cargo.filter(cgo => cgo?.transitType === 'OUTBOUND' && !cgo?.groundPickup);
    let inboundTransfer = cargo.filter(cgo => (cgo?.transitType === 'INBOUND' || cgo?.transitType === 'TRANSFER') && !cgo?.groundPickup);
    let groundPickupCgo = cargo.filter(cgo => cgo?.transitType === 'OUTBOUND' && cgo?.groundPickup);
    const tableSettings = globalAppState.getTableSettings('liveCargo');

    let dataSource = [];

    switch (state.activeTab) {
        case 'outbound':
            dataSource = outbound;
            break;
        case 'inbound':
            dataSource = inboundTransfer;
            break;
        case 'groundpickup':
            dataSource = groundPickupCgo;
            break;
        default:
            break;
    }

    function getColumns() {
        const columns = [
            {
                ...commonColumns.cgo.columns.assignedFlight,
                defaultSortOrder: 'ascend'
            },
            commonColumns.cgo.columns.name,
            !customer && commonColumns.cgo.columns.customer,
            ...commonColumns.cgo.except(['name', 'assignedFlight', 'transitType', 'customer', 'employer'])
        ].filter(col => col)
        return columns as ColumnProps<any>[]
    }

    function renderOptionsContent() {
        function isChecked(key: string) {
            return tableSettings
                ?.settings?.hiddenColumns?.includes(key)
                ? false : true
        }

        let columns = getColumns().map(col => ({ key: String(col.key), title: String(col.title) }))

        return <Menu
            onClick={(cp) => {
                cp.domEvent.stopPropagation();
                globalAppState.setTableColumnVisibility(
                    'liveCargo',
                    cp.key,
                    !isChecked(cp.key)
                );
            }}
        >
            <Menu.ItemGroup title="Show Columns">
                {columns.map(col => (
                    <Menu.Item key={col.key}>
                        {(
                            <Checkbox
                                style={{ marginRight: '12px' }}
                                checked={isChecked(col.key)}
                            />
                        )}
                        {col.title}
                    </Menu.Item>
                ))}
            </Menu.ItemGroup>
        </Menu>
    }

    function renderCargoApprovalDrawer() {
        return <Drawer visible={state.approvalDrawer.visible}
            onClose={() => {
                dispatch({
                    type: ACTION_TYPES.UPDATE_APPROVAL_DRAWER_STATE,
                    payload: {
                        error: null,
                        visible: false,
                        fields: null
                    }
                })
            }}
            width="50rem"
            title={
                <Row>
                    <Col span={12}>Approve {state.approvalDrawer.fields ? state.approvalDrawer.fields.name : "Cargo"}</Col>
                </Row>
            }>
            {state.approvalDrawer.error && <Alert
                type="error"
                message="Failed to approve cargo"
                description={state.approvalDrawer.error.message}
                showIcon
                style={{ marginBottom: '1rem' }}
            />}
            <CargoSiderTab
                isSubmitting={cargoSubmitting}
                useFields={[
                    'approvedBy']}
                fields={{ approvedBy: state.approvalDrawer.fields ? state.approvalDrawer.fields.approvedBy ? true : false : false }}
                onEditCancel={() => {
                    dispatch({
                        type: ACTION_TYPES.UPDATE_APPROVAL_DRAWER_STATE,
                        payload: {
                            error: null,
                            visible: false,
                            fields: null
                        }
                    })
                }}
                clearOnSubmit
                clearOnClose={!state.approvalDrawer.visible}
                onSubmit={(values) => {
                    dispatch({
                        type: ACTION_TYPES.UPDATE_APPROVAL_DRAWER_STATE,
                        payload: {
                            error: null,
                            fields: state.approvalDrawer.fields
                        }
                    })
                    var payload = {
                        _id: state.approvalDrawer.fields._id,
                        approvedBy: values.approvedBy ? (state.approvalDrawer.fields.approvedBy ? state.approvalDrawer.fields.approvedBy : userinfo.user.attributes.email) : ""
                    }

                    setCargoNode({
                        variables: { payload }
                    })
                }}
            />
        </Drawer>
    }

    function renderCargoCheckinDrawer() {
        return <Drawer visible={state.drawer.visible}
            onClose={() => {
                dispatch({
                    type: ACTION_TYPES.UPDATE_DRAWER_STATE,
                    payload: {
                        error: null,
                        visible: false
                    }
                })
            }}
            width="50rem"
            title={
                <Row>
                    <Col span={12}>Add Inbound Cargo</Col>
                </Row>
            }>
            {state.drawer.error && <Alert
                type="error"
                message="Failed to add inbound cargo"
                description={state.drawer.error.message}
                showIcon
                style={{ marginBottom: '1rem' }}
            />}
            <CargoSiderTab
                isSubmitting={cargoSubmitting}
                useFields={[
                    'name',
                    'weight',
                    'contractID',
                    'lastKnownController',
                    'departureID',
                    'destinationID',
                    'hazmat',
                    'hazmatUnCode',
                    'attentionTo',
                    'notes']}
                onEditCancel={() => {
                    dispatch({
                        type: ACTION_TYPES.UPDATE_DRAWER_STATE,
                        payload: {
                            error: null,
                            visible: false
                        }
                    })
                }}
                clearOnSubmit
                onSubmit={(values) => {
                    dispatch({
                        type: ACTION_TYPES.UPDATE_DRAWER_STATE,
                        payload: {
                            error: null
                        }
                    })
                    delete values['_id']
                    values.name = values.name.toUpperCase().trim();
                    values.attentionTo = values.attentionTo.toUpperCase().trim();

                    var payload = {
                        ...values,
                        tpID: transporter._id,
                        transitType: "INBOUND",
                        receivedLocationID: values["lastKnownController"],
                        checkInType: "WEB",
                        firstCheckInType: "WEB",
                        nodeState: "CHECKED_IN",
                        cargoType: "CARGO",
                        checkedInTime: moment().toISOString(),
                        scheduledFlightDate: moment().format("YYYY-MM-DD")
                    }
                    setCargoNode({
                        variables: { payload }
                    })
                }}
            />
        </Drawer>
    }

    function renderTabs() {
        return (
            <Tabs
                activeKey={state.activeTab}
                onChange={(tab) => dispatch({ newState: { activeTab: tab } })}
                className="mc-tabs-pill"
            >
                <Tabs.TabPane key="outbound" tab={`Outbound (${outbound.length})`} />
                <Tabs.TabPane key="groundpickup" tab={`Ground Pickup (${groundPickupCgo.length})`} />
                <Tabs.TabPane key="inbound" tab={`Inbound/Transfer (${inboundTransfer.length})`} />
            </Tabs>
        )
    }

    let columns = getColumns().filter((col: any) => {
        return !tableSettings?.settings?.hiddenColumns?.includes(col.key)
    });

    const { getNodeText, registerNode }  = reactNodeTextGetter();
    columns = columns.map((col) => DataExport.Utils.registerAntdColumn(col, registerNode));

    let rightContent = [
        <Divider type="vertical" style={{ height: '100%' }} className="mc-show-larger-than-large-screen" />,
    ];

    if (![NetworkStatus.setVariables, NetworkStatus.loading].includes(networkStatus)) {
        rightContent.unshift(
            <span className="mc-show-larger-than-large-screen">{`${dataSource.length} ${pluralize('Result', dataSource.length)}`}</span>
        );
    }

    if (userGroups.includes('flytsuite.live.cargo')) {
        rightContent = [
            ...rightContent,
            <Button type="primary" onClick={() => {
                dispatch({
                    type: ACTION_TYPES.UPDATE_DRAWER_STATE,
                    payload: {
                        visible: true
                    }
                })
            }}>Add Inbound Cargo</Button>,
            <Divider type="vertical" style={{ height: '100%' }} />
        ]
    }

    rightContent = [
        ...rightContent,
        <Dropdown
            overlay={renderOptionsContent()}
            placement="bottomLeft"
            trigger={['click']}
            visible={state.tableSettingsDropdownVisible}
            onVisibleChange={(visible) => dispatch({ newState: { tableSettingsDropdownVisible: visible } })}
        >
            <Button icon="setting" />
        </Dropdown>,
        <Tooltip title="Export as CSV" placement="bottomLeft">
            <Button
                icon="export"
                onClick={() => {
                    const config = {
                        columns: columns.map((col) => DataExport.Utils.convertAntdColumn(col, getNodeText))
                    }
                    DataExport.exportDataAsCsv(dataSource, "Cargo", config);
                }}
            />
        </Tooltip>,
        <Button
            icon="reload"
            loading={networkStatus === NetworkStatus.refetch}
            disabled={networkStatus === NetworkStatus.loading}
            onClick={() => refetch()}
        />
    ]


    if ([NetworkStatus.loading, NetworkStatus.setVariables].includes(networkStatus) || paginating) {
        rightContent.unshift(
            <span className="mc-show-larger-than-medium-screen"><Icon type="loading" spin /> {`Fetching page ${pageCount}`}</span>
        )
    }

    return <>
        <div className="mc-live-cargo" style={{ display: 'flex', flexDirection: 'column', margin: -24, height: 'calc(100% + 48px)' }}>
            <GlobalFilterWithWrapper
                wrapperProps={{ style: { margin: 0 } }}
                showFields={[
                    'customer',
                    'departure',
                    'destination'
                ]}
            />
            {(error || paginationError) && !state.errorClosed ? (
                <Alert
                    message="Failed to load cargo"
                    description={cleanGraphQLErrorMsg((error?.message) || paginationError?.message)}
                    style={{ margin: '12px', marginBottom: 0 }}
                    showIcon
                    type="error"
                    closable
                    afterClose={() => dispatch({ newState: { errorClosed: true } })}
                />
            ) : null}
            <LiveCard
                contentNoPadding
                animation={{
                    slideIn: true
                }}
                hasTable
                headerProps={{
                    tabs: renderTabs(),
                    title: 'Cargo',
                    verticallyCenterTitle: true,
                    rightContent
                }}
            >
                <Spin
                    indicator={<Icon type="loading" />}
                    spinning={
                        networkStatus === NetworkStatus.loading && cargo.length <= 0
                    }
                    style={{ overflowX: 'auto' }}
                >
                    <FilterableTable
                        className="mc-table mc-table-scroll-auto mc-table-header-nowrap"
                        pagination={false}
                        rowKey={record => record._id}
                        size="small"
                        dataSource={dataSource}
                        columns={columns}
                        filterBarProps={{
                            style: { margin: 0 },
                            banner: true,
                            className: "mc-live-filtered-table-filter-bar"
                        }}
                        showHighlightMatches={false}
                        onRow={(record) => {
                            return {
                                onClick: () => userGroups.includes('flight.live.cargo.approval') ?
                                    dispatch({
                                        type: ACTION_TYPES.UPDATE_APPROVAL_DRAWER_STATE,
                                        payload: {
                                            error: null,
                                            visible: true,
                                            fields: record
                                        }
                                    }) : null
                            };
                        }}
                    />
                </Spin>
            </LiveCard>
            <div className="mc-live-cargo-mobile-tabs">
                {renderTabs()}
            </div>
        </div>
        {userGroups.includes('flytsuite.live.cargo') ? renderCargoCheckinDrawer() : null}
        {userGroups.includes('flight.live.cargo.approval') ? renderCargoApprovalDrawer() : null}
    </>
}

export default withUserInfo(LiveCargo)