import { locationListToFlightLegs } from "common/util";
import { FlightLeg } from "schema";

export interface FlightPax {
    _id: string,
    departureID: string,
    destinationID: string,
    paxWeight: number,
    bagWeight: number,
    bagCount: number
}

export interface FlightCgo {
    _id: string,
    departureID: string,
    destinationID: string,
    weight: number
}

class FlightPath {

    private origin: string;
    private originalOrigin: string;
    private path: Array<string> = [];

    constructor(origin?: string){
        this.origin = origin;
    }

    static fromFlightLegs(legs: FlightLeg[], overrideOrigin?: string): FlightPath {
        
        let origin = overrideOrigin || legs[0]?.departureID;

        let originOverridden = overrideOrigin && overrideOrigin !== legs[0]?.departureID;
        let originalOrigin = legs[0]?.departureID;

        let flightPath = new FlightPath(origin);
        flightPath.originalOrigin = originalOrigin;
        if (legs.length === 0) return flightPath;

        legs.forEach((leg, idx) => {

            // If the leg destination is equal to the original origin, change it to the overridden origin.
            let legDest = originalOrigin === leg.destinationID && originOverridden ? origin : leg.destinationID;

            if (idx === legs.length-1 && legDest === origin){
                // Skip if last leg is the same as the origin
                return;
            }
            else
            {
                flightPath.addNextNode(legDest);
            }
        })

        return flightPath;
    }

    static fromLocationList(locations: string[] | { _id: string, name: string }[]){
        let getID = (loc: string | { _id: string, name: string }) => 
            typeof loc === 'object' ? loc._id : loc
        
        let flightPath = new FlightPath();

        locations.forEach((loc) => {
            let id = getID(loc);
            flightPath.addNextNode(id);
        })

        return flightPath;
    }

    removeNode(locID: string){
        if (locID === this.origin){
            // If there is only one location in the round trip and you remove the leg that is inbound to the origin,
            // remove the location from the path.
            if (this.path.length === 1){
                this.path = [];
            }
            // Otherwise we can't remove the origin
            return;
        }
        let nodeIdx = this.path.findIndex((node) => locID === node);
        if (nodeIdx > -1){
            this.path.splice(nodeIdx, 1);
        }
    }

    /**
     * Adds a new location node in the path if not in path.
     * If origin was not defined, then this node will become the origin.
     * @param locID 
     * @returns Index of location node in flight path
     */
    addNextNode(locID: string): number {
        if (!this.origin){
            this.origin = locID;
            return 0;
        }
        else if (this.path.includes(locID))
        {
            return this.path.findIndex((id) => id === locID) + 1 // +1 taking into account the origin
        }
        else if (locID === this.origin){
            return 0; // This is the origin, so don't insert anything.
        }
        else
        {
            this.path.push(locID);
            return this.path.length // Why not subtract 1? We are taking into account the origin, so -1 + 1 = 0;
        }
    }

    insertNode(departure: string, destination: string){
        let subset = this.getNodeSubset(departure, destination);
        if (subset && subset.length > 0){
            // A route for this departure and destination exists. No need to insert anything new
            return;
        }
        let depIdx = this.getNodeIdx(departure);
        let destIdx = this.getNodeIdx(destination);

        if (depIdx > destIdx){
            // Departure and destination indexes for this entity are in reverse order.
            // Insert a new destination after the entity departure
            this.path.splice(depIdx, 0, destination);
        }
        else if (depIdx > -1){
            // Departure exists in route. Insert destination to route.
            this.path.push(destination);
        }
        else if (destIdx > -1){
            // Destination exists in route, but not departure. Insert departure before destination.
            this.path.splice(destIdx-1, 0, departure);
        }
        else
        {
            // Neither departure nor destination are in the route. Insert both into route.
            this.path.push(departure);
            this.path.push(destination);
        }
    }

    getNodeIdxInSubset(locID: string, startIdx: number, endIdx: number){
        let idx = this.path
            .slice(startIdx, endIdx)
            .findIndex((id) => id === locID);
        return idx === -1 ? -1 : startIdx + idx + 1;
    }

    getNodeIdx(locID: string){
        if (locID === this.origin) return 0;

        let idx = this.path.findIndex((id) => id === locID);
        return idx === -1 ? -1 : idx+1;
    }

    getNodeIdxs(locID: string){
        if (locID === this.origin) return [0];
        let idxs: number[] = [];
        this.path.forEach((node, idx) => {
            if (node === locID){
                idxs.push(idx+1);
            }
        })
        return idxs;
    }

    getNodeSubset(startLoc: string, endLoc: string, includeRoundTrip=true){
        let startIdx = this.getNodeIdx(startLoc);
        let endIdx = this.getNodeIdx(endLoc);

        if (startIdx > endIdx){
            // Reverse order found. Does the endLoc have duplicates?
            let idxs = this.getNodeIdxs(endLoc);
            if (idxs.length > 1){
                // Duplicate found. Find the next index that is larger than the starting index
                for (let i = 0; i < idxs.length; i++) {
                    const secondEndIdx = idxs[i];
                    if (secondEndIdx > startIdx){
                        endIdx = secondEndIdx;
                        break;
                    }
                }
            }
        }

        if (startIdx === -1 || endIdx === -1){
            return undefined;
        }

        if (startIdx === 0){
            // This is the origin
            return [ this.origin, ...this.path.slice(0, endIdx) ]
        }
        if (endIdx === 0 && includeRoundTrip){
            return [...this.path.slice(startIdx-1, this.path.length), this.origin ]
        }
        else if (endIdx === 0){
            return undefined
        }
        return this.path.slice(startIdx-1, endIdx)
    }

    isRoundTrip(){
        return this.path[-1] === this.origin
    }

    getPath(){
        // If there is no origin, then there shouldn't be any other nodes
        if (!this.origin || this.path.length === 0) return []

        return [ this.origin, ...this.path ]
    }

    getRoundTripPath(){
        // If there is no origin, then there shouldn't be any other nodes
        if (!this.origin || this.path.length === 0) return []

        if (this.path[-1] === this.origin){
            // Path is already returning to origin
            return [
                this.origin,
                ...this.path
            ]
        }

        return [
            this.origin,
            ...this.path,
            this.origin
        ]
    }

    getOrigin(){
        return this.origin;
    }

    setOrigin(locID: string) {
        this.origin = locID;
    }

    /**
     * Move node to a particular index in the path
     * @param locID Location ID to move
     * @param displaceLocID Location ID to push out of the way
     * @returns Reason of move failure. If undefined, then move was successful.
     */
    moveNode(locID: string, displaceLocID: string): string {
        let nodeIdx = this.getNodeIdx(locID);
        let insertAt = this.getNodeIdx(displaceLocID);
        if (nodeIdx === 0){
            return "Cannot move origin.";
        }
        if (insertAt === 0){
            return "Cannot move location before origin.";
        }
        this.path.splice(nodeIdx-1, 1);
        this.path.splice(insertAt-1, 0, locID);
        return;
    }

    getOriginalOrigin(){
        return this.originalOrigin;
    }
}

function updateEntityDepDest(originalOrigin: string, overrideOrigin: string, entity: FlightPax | FlightCgo){
    let entityCopy = {...entity};
    if (originalOrigin === overrideOrigin) return entityCopy;

    if (entityCopy.departureID === originalOrigin){
        entityCopy.departureID = overrideOrigin;
    }
    if (entityCopy.destinationID === originalOrigin){
        entityCopy.destinationID = overrideOrigin;
    }
    return entityCopy;
}

class FlightLegManager {

    flightPath: FlightPath;
    allPax: Map<string, FlightPax> = new Map();
    allCgo: Map<string, FlightCgo> = new Map();
    private locIDToNameMap: Map<string, string> = new Map();

    private manAssignedPax = new Map<string, [string, string]>();
    private manAssignedCgo = new Map<string, [string, string]>();

    /**
     * 
     * @param initialLegs Set the initial flight legs
     * @param locationIDToNameMap Maps the location ID to a human-readable name. REQUIRED FOR 'departure' and 'destination' fields in flight legs to be populated!!!
     */
    constructor(initialLegs?: Array<FlightLeg>, paxObjs?: FlightPax[], cgoObjs?: FlightCgo[], locationIDToNameMap?: Map<string, string>, overrideOrigin?: string){
        this.flightPath = FlightPath.fromFlightLegs(initialLegs, overrideOrigin);
        this.locIDToNameMap = locationIDToNameMap;
        paxObjs.forEach((obj) => {
            let newObj = updateEntityDepDest(this.flightPath.getOriginalOrigin(), overrideOrigin, obj) as FlightPax;
            this.allPax.set(obj._id, newObj);
        })
        cgoObjs.forEach((obj) => {
            let newObj = updateEntityDepDest(this.flightPath.getOriginalOrigin(), overrideOrigin, obj) as FlightCgo;
            this.allCgo.set(obj._id, newObj);
        })
    }

    // public insertPaxCgo(
    //     departureID: string,
    //     destinationID: string,
    //     paxObjs: FlightPax[],
    //     cgoObjs: FlightCgo[]
    //     ){
    //         this.flightPath.insertNode(departureID, destinationID);
    //         paxObjs.forEach((obj) => this.allPax.set(obj._id, obj))
    //         cgoObjs.forEach((obj) => this.allCgo.set(obj._id, obj))
    // }

    // Sees if a passenger's departure and destination includes this flight leg
    paxOnLeg(pax: FlightPax, legDep: string, legDest: string){
        let paxDep = pax.departureID;
        let paxDest = pax.destinationID;
        let pathSubset = this.flightPath.getNodeSubset(paxDep, paxDest);
        if (pathSubset && pathSubset.includes(legDep) &&
            pathSubset.includes(legDest) &&

            // Ensure that the legDest inclusion check above isn't looking at the origin of the path
            pathSubset.findIndex(node => node === legDest) !== 0){
            return true;
        }
        return false;
    }

    // Sees if a cargo's departure and destination includes this flight leg
    cgoOnLeg(cgo: FlightCgo, legDep: string, legDest: string){
        let cgoDep = cgo.departureID;
        let cgoDest = cgo.destinationID;
        let pathSubset = this.flightPath.getNodeSubset(cgoDep, cgoDest);
        if (pathSubset && pathSubset.includes(legDep) &&
            pathSubset.includes(legDest) &&

            // Ensure that the legDest inclusion check above isn't looking at the origin of the path
            pathSubset.findIndex(node => node === legDest) !== 0){
            return true;
        }
        return false;
    }

    private cleanRedundantNodes(){
        let scanned = new Set();
        Array.from(this.allPax.values()).forEach((pax) => {
            scanned.add(pax.departureID);
            scanned.add(pax.destinationID);
        })
        Array.from(this.allCgo.values()).forEach((cgo) => {
            scanned.add(cgo.departureID);
            scanned.add(cgo.destinationID);
        })

        let redundantNodes = [];

        let path = this.flightPath.getPath();
        path.forEach((node) => {
            if (!scanned.has(node)){
                redundantNodes.push(node);
            }
        })

        redundantNodes.forEach((node) => this.flightPath.removeNode(node))
    }

    /**
     * Checks if a location in the flight path is no longer needed.
     * @param locID 
     * @returns true if the leg is redundant. False if not redundant.
     */
    public isLocationRedundant(locID: string){
        if (!locID) return false;
        let scanned = new Set();

        if (this.getOrigin() === locID){
            // Locations that are the origin of the flight path
            // are not redundant, even though no pax/cgo are flying on it
            return false;
        }

        Array.from(this.allPax.values()).forEach((pax) => {
            scanned.add(pax.departureID);
            scanned.add(pax.destinationID);
        })
        Array.from(this.allCgo.values()).forEach((cgo) => {
            scanned.add(cgo.departureID);
            scanned.add(cgo.destinationID);
        })
        if (!scanned.has(locID)){
            return true;
        }
        return false;
    }

    /**
     * Removes a node from the flight path if it is not in anyone's flight path
     * @param locID 
     * @returns true if leg was removed. False if leg still remains.
     */
    public removeLocationIfRedundant(locID: string){
        if (this.isLocationRedundant(locID)){
            this.flightPath.removeNode(locID);
            return true;
        }
        return false;
    }

    addPassenger(pax: FlightPax){
        this.allPax.set(pax._id, pax);
        this.flightPath.insertNode(pax.departureID, pax.destinationID);
    }

    addCgo(cgo: FlightCgo){
        this.allCgo.set(cgo._id, cgo);
        this.flightPath.insertNode(cgo.departureID, cgo.destinationID);
    }

    removePassenger(id: string){
        // let pax = this.allPax.get(id);
        this.allPax.delete(id);
        // this.removeLocationIfRedundant(pax.destinationID);
    }

    removeCgo(id: string){
        // let cgo = this.allCgo.get(id);
        this.allCgo.delete(id);
        // this.removeLocationIfRedundant(cgo.destinationID);
    }

    doesEntityHaveALeg(entity: FlightPax | FlightCgo) {
        let subset = this.flightPath.getNodeSubset(entity.departureID, entity.destinationID);
        if (!subset || subset.length === 0){
            return false;
        }
        return true;
    }

    // If two destinations, A → B, are reordered to B → A, and a TRANSFER pax/cgo travels from A → B, and A and B do not contain an origin,
    // then this pax/cgo no longer has a viable path in the flight path.
    // What we need to do is detect these pax/cgo that are in this situation and add a NEW leg that goes from A → B for them.
    // Thus, this will create a third destination with the path B → A → B
    private findAndCreateMissingLegs(){
        const putDestConditionally = (entity: FlightPax | FlightCgo) => {
            let hasLeg = this.doesEntityHaveALeg(entity);
            if (!hasLeg){
                this.flightPath.insertNode(entity.departureID, entity.destinationID);
            }
        }

        let pax = Array.from(this.allPax.values());
        let cgo = Array.from(this.allCgo.values());

        pax.forEach(putDestConditionally);
        cgo.forEach(putDestConditionally);
    }

    findUnassigned(){
        let paxList = Array.from(this.allPax.values());
        let cgoList = Array.from(this.allCgo.values());

        return {
            pax: paxList.filter((pax) => !this.doesEntityHaveALeg(pax)),
            cgo: cgoList.filter((cgo) => !this.doesEntityHaveALeg(cgo))
        }
    }

    buildFlightLegs(roundTrip=true): FlightLeg[] {
        this.findAndCreateMissingLegs();
        let path = roundTrip ? this.flightPath.getRoundTripPath() : this.flightPath.getPath();

        if (path.length <= 1){
            return [];
        }

        let legs: FlightLeg[] = [];

        path.forEach((dest, idx) => {
            if (idx === 0) return;

            let departsFrom = path[idx-1];
            let paxOnLeg = Array.from(this.allPax.values())
                .filter((pax) => this.paxOnLeg(pax, departsFrom, dest));
            let cgoOnLeg = Array.from(this.allCgo.values())
                .filter((cgo) => this.cgoOnLeg(cgo, departsFrom, dest));
            
            legs.push({
                order: idx-1,
                paxIDs: paxOnLeg.map(obj => obj._id),
                paxWeight: paxOnLeg.reduce((acc, { paxWeight=0 }) => acc + paxWeight, 0),
                bagWeight: paxOnLeg.reduce((acc, { bagWeight=0 }) => acc + bagWeight, 0),
                paxCount: paxOnLeg.length,
                bagCount: paxOnLeg.reduce((acc, { bagCount=0 }) => acc + bagCount, 0),
                cgoIDs: cgoOnLeg.map(obj => obj._id),
                cgoWeight: cgoOnLeg.reduce((acc, { weight=0 }) => acc + weight, 0),
                cgoCount: cgoOnLeg.length,
                departureID: departsFrom,
                departure: this.locIDToNameMap.get(departsFrom),
                destinationID: dest,
                destination: this.locIDToNameMap.get(dest)
            })
        })
        return legs;
    }

    getPaxIDList(){
        return Array.from(this.allPax.values()).map(obj => obj._id)
    }

    getCgoIDList(){
        return Array.from(this.allCgo.values()).map(obj => obj._id)
    }

    moveDestination(grabDest: string, hoverDest: string){
        // let error = this.flightPath.moveNode(grabDest, hoverDest);
        // if (!error){
        //     // Reodering may cause some nodes in the flight route to become redundant.
        //     this.cleanRedundantNodes();
        // }
        return this.flightPath.moveNode(grabDest, hoverDest);
    }

    getOrigin(){
        return this.flightPath.getOrigin();
    }

    setOrigin(locID: string){
        this.flightPath.setOrigin(locID);
    }

    getLocationName(locID: string){
        return this.locIDToNameMap.get(locID);
    }
}

export default FlightLegManager