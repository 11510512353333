import React from 'react';
import { Form, Input, Spin, Button } from 'antd';
import { LocationSelect } from '../../form/select/LocationSelect';
import { formItemLayout, tailFormItemLayout } from '../../../common/form';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { FormComponentProps } from 'antd/lib/form';

const { Item } = Form;

const GET_LOCATIONS = gql`
query GetLocations($departureID: ID!, $getDeparture: Boolean, $destinationID: ID!, $getDestination: Boolean){
    departure: getLocation(_id: $departureID) @include(if: $getDeparture){
        _id
        name
    }
    destination: getLocation(_id: $destinationID) @include(if: $getDestination){
        _id
        name
    }
}
`

export interface SetFormProps extends FormComponentProps {
    defaultName?: string,
    defaultDepartureID?: string,
    defaultDestinationID?: string,
    loading?: boolean,
    onSubmit?(values: any): void
}

const SetForm = ({ form, defaultName, defaultDepartureID, defaultDestinationID, loading: propLoading, onSubmit }) => {
    const { getFieldDecorator, validateFieldsAndScroll } = form;
    const submit = () => {
        validateFieldsAndScroll((err, values) => {
            if (!err && onSubmit) {
                onSubmit(values)
            }
        })
    }
    return (
        <Form>
            <Item label="Set Name" {...formItemLayout}>
            {getFieldDecorator('name', {
                initialValue: defaultName,
                rules: [
                    {required: true, message: 'Name is required'}
                ]
            })(<Input />)}
            </Item>
            <Query 
                skip={!defaultDepartureID && !defaultDestinationID}
                query={GET_LOCATIONS}
                variables={{
                    departureID: defaultDepartureID,
                    destinationID: defaultDestinationID,
                    getDeparture: defaultDepartureID ? true : false,
                    getDestination: defaultDestinationID ? true : false
                }}
            >
            {({ data, loading }) => {
                return (
                    <Spin spinning={loading}>
                        <Item label="Heliport" {...formItemLayout}>
                        {getFieldDecorator('departure', {
                                initialValue: {
                                    key: data && data.departure && data.departure._id,
                                    label: data && data.departure && data.departure.name
                                },
                                rules: [
                                    {required: true, message: 'Heliport is required'}
                                ]
                            })(<LocationSelect labelInValue type="ONSHORE"  />)
                        }
                        </Item>
                        <Item label="Off Shore Location" {...formItemLayout}>
                        {getFieldDecorator('destination', {
                                initialValue: {
                                    key: data && data.destination && data.destination._id,
                                    label: data && data.destination && data.destination.name
                                },
                                rules: [
                                    {required: true, message: 'Off Shore Location is required'}
                                ]
                            })(<LocationSelect labelInValue type="OFFSHORE" />)
                        }
                        </Item>
                        <Item {...tailFormItemLayout}>
                            <Button disabled={loading} onClick={() => submit()} type="primary" loading={propLoading}>Create Set</Button>
                        </Item>
                    </Spin>
                )
            }}
            </Query>
        </Form>
    )
}

export default Form.create<SetFormProps>()(SetForm)