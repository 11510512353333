import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import gql from 'graphql-tag';
import WithOrgData from '../../../WithOrgData';
import LoadingContent from '../../../LoadingContent';
import { Alert, Row, Col, Table, Button, message, Popconfirm } from 'antd';
import commonColumns from '../../../../common/table/columns';
import _ from 'lodash';
import PersonSearch from 'components/search/PersonSearch';
import './index.less';

const WHITE_LIST = gql`
query WhiteList($_id: ID!){
    getWhiteList(_id: $_id){
        _id
        personIds {
            _id
            lastName
            firstName
            employerID {
                _id
                name
            }
            contactInfo {
                address {
                    city
                    state
                }
            }
        }
    }
}
`

const SET_WHITE_LIST = gql`
mutation SetWhiteList(
    $_id: ID
    $customerID: ID!
    $tpID: ID!
    $personIds: [ID]
){
    setWhiteList(
        _id: $_id
        customerID: $customerID
        tpID: $tpID
        personIds: $personIds
    ){
        _id
        personIds {
            _id
            lastName
            firstName
            employerID {
                _id
                name
            }
            contactInfo {
                address {
                    city
                    state
                }
            }
        }
    }
}
`

class Person extends Component {
    state = {
        personsToAdd: [],
        addingPersons: false,
        selectedPersons: []
    }
    addPersons = () => {
        const { orgData: { customer, transporter }, data: { getWhiteList } } = this.props;
        const { personsToAdd } = this.state;
        const prevPersonIds = getWhiteList ? getWhiteList.personIds : [];
        const personIds = _.union(prevPersonIds.map(per => per._id), personsToAdd);

        this.setState({ addingPersons: true });
        this.props.setWhiteList({
            variables: {
                _id: customer._id.replace('CUS-', 'WHL-'),
                customerID: customer._id,
                tpID: transporter._id,
                personIds
            }
        })
        .then(() => {
            message.success(`Successfully added ${personsToAdd.length} personnel to whitelist`);
            this.setState({ personsToAdd: [] })
        })
        .catch((error) => {
            console.error(error)
            message.error("Failed to add personnel to non-preferred list");
        })
        .then(() => this.setState({ addingPersons: false }))
    }
    removePersons = () => {
        const { orgData: { customer, transporter }, data: { getWhiteList } } = this.props;
        const { selectedPersons } = this.state;
        const persons = getWhiteList.personIds.filter(per => !selectedPersons.includes(per._id));
        const personIds = persons.map(per => per._id);

        this.props.setWhiteList({
            variables: {
                _id: customer._id.replace('CUS-', 'WHL-'),
                customerID: customer._id,
                tpID: transporter._id,
                personIds
            },
            optimisticResponse: {
                __typename: 'Mutation',
                setWhiteList: {
                    __typename: 'WhiteList',
                    _id: getWhiteList._id,
                    personIds: persons
                }
            }
        })
        .then(() => {
            message.success(`Successfully removed ${selectedPersons.length} personnel from white list`);
            this.setState({ selectedPersons: [] });
        })
        .catch(error => {
            console.error(error);
            message.error('Failed to remove personnel from white list');
        })
    }
    render(){
        const { getWhiteList, loading, error } = this.props.data;
        if (loading) return <LoadingContent style={{margin: '10rem 0'}} />
        if (error){
            const notFoundError = error.graphQLErrors.find(err => err.errorType === 'not_found' && err.path.length === 1)
            if (!notFoundError){
                return <Alert type="error" message="Failed to load white list" description={error.message} showIcon />
            }
        }
        
        return (
            <Row>
                <Col span={8}>
                    <div className="whitelist-person-search-col">
                        <Row type="flex" style={{ padding: '0 0.5rem' }}>
                            <Col><h3 style={{ margin: 0 }}>Add Personnel</h3></Col>
                            <div style={{ flex: 1 }} />
                            <Col>
                                <Button
                                    type="primary"
                                    disabled={!this.state.personsToAdd.length}
                                    loading={this.state.addingPersons}
                                    onClick={this.addPersons}
                                >Add to White List</Button>
                            </Col>
                        </Row>
                        <PersonSearch
                            onSelection={(rows) => this.setState({ personsToAdd: rows })}
                            selectedItems={this.state.personsToAdd}
                            filterResults={(rows) => rows.filter((row) => !getWhiteList.personIds.map(p => p._id).includes(row._id))}
                            style={{ flex: 1, minHeight: 0 }}
                            showCustomerColumn={false}
                        />
                        {/* <PersonSearch 
                            title="Add Personnel"
                            extra={
                                <Button
                                    type="primary"
                                    disabled={!this.state.personsToAdd.length}
                                    loading={this.state.addingPersons}
                                    onClick={this.addPersons}
                                >
                                    Add to White List
                                </Button>
                            }
                            onChange={(personsToAdd) => this.setState({ personsToAdd })}
                            selectedPersonKeys={this.state.personsToAdd}
                        /> */}
                    </div>
                </Col>
                <Col span={16}>
                    <div style={{ padding: 24 }}>
                        <Row type="flex" style={{marginBottom: '1rem'}} >
                            <Col style={{flex: 1}}>
                                <h3>Personnel on White List</h3>
                            </Col>
                            <Col>
                                <Popconfirm 
                                    title={`Are you sure you want to remove ${this.state.selectedPersons.length} personnel?`}
                                    okText="Remove"
                                    onConfirm={this.removePersons}
                                    placement="bottomLeft"
                                >
                                    <Button
                                        type="danger"
                                        disabled={this.state.selectedPersons.length ? false : true}
                                    >
                                        Remove {this.state.selectedPersons.length} Personnel
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                        <Table
                            dataSource={getWhiteList.personIds}
                            rowKey={record => record._id}
                            size="small"
                            className="mc-table"
                            pagination={false}
                            columns={commonColumns.person.all()}
                            rowSelection={{
                                selectedRowKeys: this.state.selectedPersons,
                                onChange: (selectedPersons) => this.setState({ selectedPersons })
                            }}
                        />
                    </div>
                </Col>
            </Row>
        )
    }
}

export default compose(
    WithOrgData,
    graphql(
        WHITE_LIST,
        {
            options: props => ({
                variables: {
                    _id: props.orgData.customer._id.replace('CUS-', 'WHL-')
                },
                pollInterval: ['cache-only', 'cache-first'].includes(props.fetchPolicy) ? undefined : 15000,
                fetchPolicy: props.fetchPolicy || 'network-only'
            }),
            props: props => {
                const defaultWl = {
                    _id: props.ownProps.orgData.customer._id.replace('CUS-', 'WHL-'),
                    personIds: [],
                    __typename: 'WhiteList'
                }
                if (props.ownProps.useDefault){
                    return {
                        ...props,
                        data: {
                            ...props.data,
                            useDefault: undefined,
                            error: undefined,
                            getWhiteList: defaultWl
                        }
                    }
                }
                return props
            }
        }
    ),
    graphql(
        SET_WHITE_LIST,
        {
            name: 'setWhiteList'
        }
    )
)(Person);