import { Alert, Button, Card, Col, Form, Input, message, Row, Select } from 'antd';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Mutation, Query } from "react-apollo";
import { safeGet } from '../../../common/util';
import { ColumnSelector } from '../../ColumnSelector';
import useManifestPrintout from '../../scheduling/Printout/useManifestPrintout';
import withCognitoUser from '../../WithCognitoUser';
import WithOrgData from '../../WithOrgData';

const getColumnSets  = (report_type) => {
    const paxColumns = [
        {
            setName: 'Columns',
            key: 'columns',
            columns: [
                {
                    label: 'First Name',
                    key: 'firstName'
                },
                {
                    label: 'Last Name',
                    key: 'lastName'
                },
                {
                    label: 'Flight',
                    key: 'currentCarrier.desig'
                },
                {
                    label: 'Departure',
                    key: 'departure.name'
                },
                {
                    label: 'Destination',
                    key: 'destination.name'
                },
                {
                    label: 'Pax Weight',
                    key: 'paxWeight'
                },
                {
                    label: 'Bag Weight',
                    key: 'bagWeight'
                },
                {
                    label: 'Bag Count',
                    key: 'bagCount'
                },
                {
                    label: 'Transporter',
                    key: 'transporter.name'
                },
                {
                    label: 'Customer',
                    key: 'customer.name'
                },
                {
                    label: 'Employer',
                    key: 'employer.name'
                },
                {
                    label: 'Checked-In-Time',
                    key: 'checkedInTime'
                },
                {
                    label: 'Finalization reason',
                    key: 'finalizationReasonType'
                },
                {
                    label: 'Charge Code',
                    key: 'chargeCode'
                }
                //{
                //    label: 'State',
                //    key: 'nodeState'
                //}
            ]
        }
    ]
    const paxCovidVaxColumns = [
        {
            setName: 'Columns',
            key: 'columns',
            columns: [
                {
                    label: 'First Name',
                    key: 'firstName'
                },
                {
                    label: 'Last Name',
                    key: 'lastName'
                },
                {
                    label: 'Is Vaccinated',
                    key: 'isVaccinated'
                },
                {
                    label: 'Flight',
                    key: 'currentCarrier.desig'
                },
                {
                    label: 'Departure',
                    key: 'departure.name'
                },
                {
                    label: 'Destination',
                    key: 'destination.name'
                },
                {
                    label: 'Pax Weight',
                    key: 'paxWeight'
                },
                {
                    label: 'Bag Weight',
                    key: 'bagWeight'
                },
                {
                    label: 'Bag Count',
                    key: 'bagCount'
                },
                {
                    label: 'Transporter',
                    key: 'transporter.name'
                },
                {
                    label: 'Customer',
                    key: 'customer.name'
                },
                {
                    label: 'Employer',
                    key: 'employer.name'
                },
                {
                    label: 'Checked-In-Time',
                    key: 'checkedInTime'
                },
                {
                    label: 'Finalization reason',
                    key: 'finalizationReasonType'
                },
                {
                    label: 'Charge Code',
                    key: 'chargeCode'
                },
                {
                    label: 'Essential On',
                    key: 'essentialOn'
                }
                //{
                //    label: 'State',
                //    key: 'nodeState'
                //}
            ]
        }
    ]
    const personCovidVaxDetails = [
        {
            setName: 'Columns',
            key: 'columns',
            columns: [
                {
                    label: 'First Name',
                    key: 'firstName'
                },
                {
                    label: 'Last Name',
                    key: 'lastName'
                },
                {
                    label: 'DOB',
                    key: 'dob'
                },
                {
                    label: 'Employer',
                    key: 'employerName'
                },
                {
                    label: 'Shot Manufacturer',
                    key: 'shotManufacturer'
                },
                {
                    label: 'Shot 1',
                    key: 'shot_1'
                },
                {
                    label: 'Shot 2',
                    key: 'shot_2'
                },
                {
                    label: 'Vaccinated on',
                    key: 'vaccinated_on'
                },
                {
                    label: 'POS Test',
                    key: 'pos_test'
                },
                {
                    label: 'RTW',
                    key: 'rtw'
                },
                {
                    label: 'RTW 2',
                    key: 'rtw_2'
                },
                {
                    label: 'Essential On',
                    key: 'essentialOn'
                }
                // {
                //     label: 'Booster 1',
                //     key: 'booster_1'
                // },
                // {
                //     label: 'Booster 2',
                //     key: 'booster_2'
                // }
            ]
        }
    ]
    const covidColumns = [
        {
            setName: 'Columns',
            key: 'columns',
            columns: [
                {
                    label: 'Checked-In-Time',
                    key: 'checkedInTime'
                },
                {
                    label: 'Last Name',
                    key: 'lastName'
                },
                {
                    label: 'First Name',
                    key: 'firstName'
                },
                {
                    label: 'Customer',
                    key: 'customer.name'
                },
                {
                    label: 'Employer',
                    key: 'employer.name'
                },
                {
                    label: 'Departure',
                    key: 'departure.name'
                },
                {
                    label: 'Symptoms',
                    key: 'covid_hasFluSymptoms'
                },
                {
                    label: 'Has High Temp',
                    key: 'covid_selfReportedHighBodyTemperature'
                },
                {
                    label: 'Exposed to Covid',
                    key: 'covid_ExposedToVirus'
                },
                {
                    label: 'Vaccinated',
                    key: 'covid_hasBeenVaccinated'
                }
            ]
        }
    ]
    const cgoColumns = [
        {
            setName: 'Columns',
            key: 'columns',
            columns: [
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Flight',
                    key: 'currentCarrier.desig',
                },
                {
                    label: 'Departure',
                    key: 'departure.name'
                },
                {
                    label: 'Destination',
                    key: 'destination.name'
                },
                {
                    label: 'Ground Pickup',
                    key: 'groundPickup'
                },
                {
                    label: 'Weight',
                    key: 'weight'
                },
                {
                    label: 'Transporter',
                    key: 'transporter.name'
                },
                {
                    label: 'Customer',
                    key: 'customer.name'
                },
                {
                    label: 'Check-In-Time',
                    key: 'checkedInTime'
                },
                {
                    label: 'Initials',
                    key: 'initials'
                },
                {
                    label: 'State',
                    key: 'nodeState'
                },
                {
                    label: 'Charge Code',
                    key: 'chargeCode'
                },
                {
                    label: 'Last Modified',
                    key: 'modifiedTs'
                }
            ]
        }
    ]
    const isnColumns = [
        {
            setName: 'Columns',
            key: 'columns',
            columns: [
                {
                    label: 'Last Name',
                    key: 'lastName'
                },
                {
                    label: 'First Name',
                    key: 'firstName'
                },
                {
                    label: 'Checked-In-Time',
                    key: 'checkedInTime'
                },
                {
                    label: 'Departure',
                    key: 'departure.name'
                },
                {
                    label: 'Destination',
                    key: 'destination.name'
                },
                {
                    label: 'Transporter',
                    key: 'transporter.name'
                },
                {
                    label: 'Customer',
                    key: 'customer.name'
                },
                {
                    label: 'Employers',
                    key: 'employer.name'
                },
                {
                    label: 'ISN Status',
                    key: 'isn_status',
                    format: 'isn_status'
                },
                {
                    label: 'ISN ID',
                    key: 'lastIsnResultWithHeader.selectedResult.isnId'
                },
                {
                    label: 'Training Deficiencies',
                    key: 'trainingDeficiencies',
                    format: 'get_selected_result'
                },
                {
                    label: 'Reasons For Non Compliance',
                    key: 'reasonsForNonCompliance',
                    format: 'get_selected_result'
                },
                {
                    label: 'Overridden By',
                    key: 'overridden_by',
                    format: 'overridden_by'
                },
                {
                    label: 'Override Notes',
                    key: 'authorization.brdRestriction_Isn.notes'
                },
                {
                    label: 'Overrider Reason',
                    key: 'authorization.brdRestriction_Isn.overriderReason'
                },
                {
                    label: 'Assigned Activities',
                    key: 'assignedActivities',
                    format: 'get_selected_result'
                }
            ]
        }
    ]

    const personHotfuel = [
        {
            setName: 'Columns',
            key: 'columns',
            columns: [
                {
                    label: 'Last Name',
                    key: 'lastName'
                },
                {
                    label: 'First Name',
                    key: 'firstName'
                },
                {
                    label: 'Customer',
                    key: 'customerName'
                },
                {
                    label: 'Employer',
                    key: 'employerName'
                },
                {
                    label: 'HotFuel Expiration Date',
                    key: 'hotfuelExpirationDate'
                }
            ]
        }
    ]

    const paxIsnEventsColumns = [
        {
            setName: 'Columns',
            key: 'columns',
            columns: [
                "Event Timestamp",
                "Event Type",
                "Pax Name",
                "Pax ID",
                "Destination",
                "Customer",
                "Employer",
                "Scheduled Flight Date",
                "ISN Status",
                "ISN Reason",
                "ISN Company",
                "ISN ID",
                "Override Type",
                "Override Time",
                "Override Title",
                "Override Reason",
                "Training or Whitelist",
                "Overrider Name",
                "Approver Name",
                "Override Notes",
                "Deleted Ts",
                "Created Ts",
                "Created By",
                "Modified Ts",
                "Modified By",

            ]
            .map(col => ({ label: col, key: col }))
        }
    ]

    const noColumns = []

    switch (report_type) {
        case 'PAX':
            return paxColumns
        case 'CGO':
            return cgoColumns
        case 'MANIFEST':
            return noColumns
        case 'ISN':
            return isnColumns
        case 'COVID':
            return covidColumns
        case 'PAX_COVID19_VAX':
            return paxCovidVaxColumns
        case 'PERSON_COVID19_VAX_DETAILS':
            return personCovidVaxDetails
        case 'PERSON_HOTFUEL':
            return personHotfuel
        case 'PAX_ISN_EVENTS':
            return paxIsnEventsColumns
        default:
            return null
    }
}

export const renderExport = (report_type, report_id, flightID) => {
    const column_sets = getColumnSets(report_type);
    if (!column_sets){
        return <div>Unknown report type</div>
    }
    return <ReportExporter flightID={flightID} report_id={report_id} column_sets={column_sets} report_type={report_type}  />
}

const file_types = [
    {
        key: 'csv',
        label: 'CSV',
        content_type: 'text/csv'
    },
    {
        key: 'json',
        label: 'JSON',
        content_type: 'application/json'
    }
]

var ReportExporter = ({ flightID, report_type, report_id, data, export_name, column_sets, titleRowText, criteria, default_file_type="csv", export_as_zip=false, form: { getFieldDecorator, validateFieldsAndScroll, getFieldValue } }) => {
    const [ downloadUrl, setDownloadUrl ] = useState(null);
    const [ desig, setDesig] = useState(null)
    const exportedFileType = downloadUrl && downloadUrl.split('.').slice(-1)[0];
    const single_column_set = column_sets.length === 1;
    const FLIGHT = gql`
    query Flight($_id: ID!){
        getFlightHistory(_id: $_id){
            _id
            desig
            scheduledFlightDate
            callSign
            customerID {
                _id
                name
            }
            paxIDList {
                _id
                lastName
                firstName
                scheduledOrder
                scheduledGroup
                employerID {
                    _id
                    name
                }
                transitType
                departureID {
                    _id
                    name
                }
                destinationID {
                    _id
                    name
                }
                paxWeight
                bagWeight
                bagCount
            }
            legs
            legsArray {
                departure
                destination
                departureID
                destinationID
                flightTime
                paxIDs {
                    _id
                    lastName
                    firstName
                    employerID {
                        _id
                        name
                    }
                    chargeCode
                    paxWeight
                    bagWeight
                    departureID {
                        _id
                        name
                    }
                    destinationID {
                        _id
                        name
                    }
                    personID {
                        _id
                        dob
                        nation
                    }
                }
                cgoIDs{
                    _id
                    name
                    approvedBy
                    weight
                    assigned
                    checkInType
                    nodeState
                    hazmat
                    transitType
                    attentionTo
                    deliveredBy
                    notes
                    chargeCode
                    departureID{
                        _id
                        name
                    }
                    destinationID{
                        _id
                        name
                    }
                }
            }
            tpID {
                _id
            }
            pilot {
                _id
                name {
                    lastName
                    firstName
                }
            }
            copilot {
                _id
                name {
                    lastName
                    firstName
                }
            }
            contract {
                _id
                name
            }
            aircraftID {
                _id
                tailNum
            }
            departureID {
                _id
                name
            }
        }
    }
    `

    let mutation_name = report_id ? 'export_report' : 'export_data';

    let mut = `
    mutation ExportReport(
            ${report_id ? "$report_id: ID!," : "$data: AWSJSON!,"}
            ${!report_id ? "$export_name: String!" : ""}
            ${!report_id ? "$report_type: String!" : ""}
            ${!report_id ? "$criteria: [ReportCriteriaInput]!" : ""}
            $file_type: String!,
            $content_type: String!,
            $column_sets: [ExportReportColumnSetInput!]!
            $export_as_zip: Boolean
            $titleRowText: String
        ) {
            ${report_id ? "export_report" : "export_data"}(
                ${report_id ? "report_id: $report_id," : "data: $data,"}
                ${!report_id ? "export_name: $export_name" : ""}
                ${!report_id ? "report_type: $report_type" : ""}
                ${!report_id ? "criteria: $criteria" : ""}
                file_type: $file_type,
                content_type: $content_type,
                column_sets: $column_sets
                export_as_zip: $export_as_zip
                titleRowText: $titleRowText
            )
        }
    `

    return (
        <Mutation
            mutation={gql(mut)}
            onCompleted={(data) => setDownloadUrl(data[mutation_name])}
        >
        {(export_report, { loading, error }) => {
            const submit = () => {
                validateFieldsAndScroll((err, values) => {
                    if (!err){
                        const selectedFileType = file_types.find(({ key }) => key === values.file_type);
                        let _criteria;
                        if (Array.isArray(criteria)){
                            _criteria = [ ...criteria ];
                            _criteria.forEach((c) => {
                                if ('__typename' in c){
                                    delete c.__typename;
                                }
                            })

                        }

                        export_report({
                            variables: {
                                export_as_zip: export_as_zip,
                                report_id: report_id,
                                criteria: _criteria,
                                data: typeof data === 'string' ? data : JSON.stringify(data),
                                report_type: report_type,
                                export_name: export_name,
                                file_type: values.file_type,
                                content_type: selectedFileType && selectedFileType.content_type,
                                titleRowText: values.titleRowText ? values.titleRowText.replace(',', '') : undefined,
                                column_sets: column_sets.map(colSet => {
                                    const newColumns = values[colSet.key] && values[colSet.key]
                                        .filter(col => col.checked)
                                        .map(({ label, key, dataIndex, format }) => ({ label, key: dataIndex ? dataIndex : key, format }));
                                    const newColSet = {
                                        ...colSet,
                                        columns: newColumns
                                    }
                                    return newColSet
                                })
                            }
                        })
                    }
                })
            }
            const renderColumnSelector = (colSet, formItemProps={}) => {
                if (!colSet){
                    return null
                }
                return <Form.Item {...formItemProps}>
                    {getFieldDecorator(colSet.key, {
                        initialValue: colSet.columns.map(col => ({
                            ...col,
                            checked: typeof col.checkedByDefault === 'boolean' ? col.checkedByDefault : true
                        })),
                        valuePropName: 'columns'
                    })(<ColumnSelector disabled={loading} />)}
                </Form.Item>
            }
            return (
                <Form>
                    {error ? (
                        <Alert type="error" showIcon message="Failed to export report" />
                    ) : null}
                    {report_type === 'MANIFEST' ?
                        <Form.Item label = "The flight selected is: ">
                            <input type="text" name="flightdesigPicked" style={{width: "300px", fontSize: 18, fontWeight: 'bold'}} placeholder={"No flight was Selected yet"} value={desig} disabled/>
                        </Form.Item> : null }
                    <Form.Item label="File Type">
                    {getFieldDecorator('file_type', {
                        initialValue: default_file_type,
                        rules: [
                            {required: true, message: 'You must choose a file type'}
                        ]
                    })(
                        <Select style={{ width: '10rem' }} disabled={loading} showAction={["focus", "click"]}>
                            {file_types.map(({ key, label }) => <Select.Option key={key}>{label}</Select.Option>)}
                        </Select>
                    )}
                    </Form.Item>
                    <Form.Item label="Title Row Text">
                        {getFieldDecorator('titleRowText', {
                            initialValue: titleRowText
                        })(
                            <Input max={100} />
                        )}
                    </Form.Item>
                    <Row type="flex" gutter={12} style={{ flexWrap: 'wrap' }}>
                    {column_sets.map((colSet, i) => (
                        <Col style={{ minWidth: '25rem' }} key={i}>
                            {single_column_set ? renderColumnSelector(colSet, { label: 'Columns' }) : (
                                <Card size="small" title={colSet.setName + ':'}>
                                    {renderColumnSelector(colSet)}
                                </Card>
                            )}
                        </Col>
                    ))}
                    </Row>
                    <Form.Item>
                        {report_type === "MANIFEST" ?
                        <Query
                            query={FLIGHT}
                            variables={{_id: flightID}}
                            fetchPolicy="network-only"
                            pollInterval={10000}>
                                {(flight) => {
                                    var flightData = {};
                                    if (flight && flight.data && flight.data.getFlightHistory){
                                        setDesig(flight.data.getFlightHistory.desig)
                                        flightData = flight.data.getFlightHistory || {};
                                        flightData.departureID = {};
                                        flightData.departureID["_id"] = flightData.legsArray[0].departureID;
                                        flightData.departureID["name"] = flightData.legsArray[0].departure;
                                    }
                                    //console.log(column_sets[0].columns);
                                    return <PrintoutButton flight={flightData} flightDesigText={desig} disabled={loading || error || !flight}  />
                                    }
                                }
                        </Query> :
                        <Button
                            onClick={submit}
                            loading={loading}
                            type="primary"
                        >Export Report</Button>
                        }
                        {downloadUrl && !loading && !error ? (
                            <React.Fragment>
                                <h4>Export finished! Click the button below to download your report.</h4>
                                {exportedFileType === 'json' ? (
                                    <Alert
                                        type="info"
                                        showIcon
                                        message="NOTE WHEN DOWNLOADING JSON FILES"
                                        description="Your browser will display, but not download your JSON file. To download you will need to right-click, then click Save As..., or press Ctrl + S."
                                        style={{ marginBottom: '1rem' }}
                                    />
                                ) : null}
                                <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
                                    <Button
                                        type="primary"
                                        style={{ backgroundColor: 'rgb(82, 196, 26)' }}
                                    >
                                        Download Report
                                    </Button>
                                </a>
                            </React.Fragment>
                        ) : null}
                    </Form.Item>
                </Form>
            )
        }}
        </Mutation>
    )
}
var PrintoutButton = ({flight, flightDesigText, orgData, cognitoUser, ...restProps}) => {
    const [ fetchPrintout, fetching ] = useManifestPrintout('FlightWithLegs', 'Manifest');
    const userAttr = cognitoUser.attributes;
    return (
        <Query
            query={gql`
                query GetCustomerManifestPrintout($id: ID!){
                    getCustomer(_id: $id){
                        _id
                        name
                        manifestPrintout {
                            type
                            layouts {
                                name
                                logo
                            }
                        }
                    }
                }
            `}
            variables={{ id: flight.customerID }}
        >
        {({ data }) => {
            const manifestPrintouts = safeGet(['getCustomer', 'manifestPrintout'], data) || [];
            const flightManifest = manifestPrintouts.find((m) => m.type === 'Flight');
            const hessLayout = flightManifest && flightManifest.layouts.find((l) => l.name === 'Hess');
            const logoURL = hessLayout && hessLayout.logo;
            const handleClick = () => {
                if (!flight || !flightDesigText){
                    message.error('No flight was selected for exporting');
                    return;
                }
                const legs = flight.legsArray || [];
                const body = {
                    issuedBy: `${userAttr.given_name} ${userAttr.family_name}`,
                    orgName: flight.customerID.name,
                    logoURL: logoURL,
                    desig: flight.desig,
                    departure: flight.departureID && flight.departureID.name,
                    aircraftNo: flight.aircraftID && flight.aircraftID.tailNum,
                    scheduledFlightDate: flight.scheduledFlightDate,
                    legs: legs.map((leg) => {
                        return {
                            departure: leg.departure,
                            destination: leg.destination,
                            flightTime: leg.flightTime || 0,
                            cargo: leg.cgoIDs.map((cgo)=>{
                                return{
                                    _id: cgo._id,
                                    name: cgo.name,
                                    attentionTo: cgo.attentionTo,
                                    deliveredBy: cgo.deliveredBy,
                                    weight: cgo.weight,
                                    departure: cgo.departureID && cgo.departureID.name,
                                    destination: cgo.destinationID && cgo.destinationID.name,
                                    chargeCode: cgo.chargeCode && cgo.chargeCode.toUpperCase(),
                                }
                            }),
                            passengers: leg.paxIDs.map((pax) => {
                                return {
                                    _id: pax._id,
                                    lastName: pax.lastName,
                                    firstName: pax.firstName,
                                    employer: pax.employerID && pax.employerID.name,
                                    chargeCode: pax.chargeCode && pax.chargeCode.toUpperCase(),
                                    paxWeight: pax.paxWeight,
                                    bagWeight: pax.bagWeight,
                                    departure: pax.departureID && pax.departureID.name,
                                    destination: pax.destinationID && pax.destinationID.name,
                                    dob: pax.personID && pax.personID.dob
                                }
                            })
                        }
                    })
                }
                fetchPrintout(body, true, `${flight.scheduledFlightDate} ${flight.desig} Flight Manifest.xlsx`)
                .catch((err) => {
                    message.error('Failed to download printout', 3)
                    .then(() => message.error(err.message))
                })
            }
            return (
                <Button style={{ background: "#52c41a" , color: 'white'}} {...restProps} onClick={handleClick} loading={fetching}>Download Printout</Button>
            )
        }}
        </Query>
    )
}

PrintoutButton = WithOrgData( withCognitoUser( PrintoutButton ) )

ReportExporter.propTypes = {
    report_id: PropTypes.string.isRequired,
    column_sets: PropTypes.arrayOf(
        PropTypes.shape({
            setName: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
            columns: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string.isRequired,
                    key: PropTypes.string.isRequired
                })
                ).isRequired,
                dataSource: PropTypes.shape({
                    set: PropTypes.string.isRequired,
                    key: PropTypes.string.isRequired
                }),
        })
    )
}

ReportExporter = Form.create()(ReportExporter)

ReportExporter.displayName = 'Form(ReportExporter)'

const DataExporter = ({ ...props }) => <ReportExporter {...props} />

export { ReportExporter, DataExporter };
