import { Checkbox, DatePicker, Form, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { formItemLayout } from 'common/form';
import { LocationSelect } from 'components/form/select/LocationSelect';
import { OrganizationSelect } from 'components/form/select/OrganizationSelect';
import React, { forwardRef, LegacyRef, useRef } from 'react';
import { entryFormOptions, EntryFormProps, useAutoFocus } from '.';

export interface ContractEntryFormProps extends EntryFormProps {

}

const ContractEntryForm: React.FC<ContractEntryFormProps> = forwardRef((props, ref: LegacyRef<Form>) => {
    const nameInputRef = useRef(null);
    const { getFieldDecorator, getFieldValue } = props.form;

    useAutoFocus(props.autoFocus, nameInputRef.current)

    return <Form ref={ref}>
        <Form.Item label="Name" {...formItemLayout}>
        {getFieldDecorator('name', {
            rules: [
                { required: true, message: 'Name is required' }
            ]
        })(<Input ref={nameInputRef} />)}
        </Form.Item>
        <Form.Item label="Customer" {...formItemLayout}>
        {getFieldDecorator('customerID', {
            rules: [
                { required: true, message: 'Customer is required' }
            ]
        })(<OrganizationSelect labelInValue classTypes={['flytsuite.customer']} />)}
        </Form.Item>
        <Form.Item label="Heliport" {...formItemLayout}>
        {getFieldDecorator('locationID')(<LocationSelect labelInValue type="ONSHORE" />)}
        </Form.Item>
        {props.dupeChecker}
        <Form.Item label="Start Date" {...formItemLayout}>
        {getFieldDecorator('startDate', {
            rules: [
                { required: true, message: 'Start Date is required' }
            ]
        })(<DatePicker format="YYYY-MM-DD" />)}
        </Form.Item>
        <Form.Item label="Doesn't expire" {...formItemLayout}>
        {getFieldDecorator('doesntExpire', {
            valuePropName: 'checked',
            initialValue: false
        })(<Checkbox />)}
        </Form.Item>
        {!getFieldValue('doesntExpire') ? (
            <Form.Item label="End Date" {...formItemLayout}>
            {getFieldDecorator('endDate', {
                rules: [
                    { required: true, message: 'End Date is required' }
                ]
            })(<DatePicker format="YYYY-MM-DD" />)}
            </Form.Item>
        ) : null}
        <Form.Item label="Active" {...formItemLayout}>
        {getFieldDecorator('active', {
            initialValue: true,
            valuePropName: 'checked'
        })(<Checkbox />)}
        </Form.Item>
    </Form>
})

export default Form.create<ContractEntryFormProps & FormComponentProps>(entryFormOptions<ContractEntryFormProps>())(ContractEntryForm)