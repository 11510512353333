import React, { useRef, useEffect, useState } from 'react';
import CargoForm from 'components/form/CargoForm';
import CargoFooter from './CargoFooter';
import WithOrgData, { WithOrgDataProps } from 'components/WithOrgData';
import uuidv4 from 'uuid/v4';
import { createFormFields } from 'common/form';

export interface CargoSiderTabProps extends WithOrgDataProps {
    clearOnSubmit?: boolean,
    clearOnClose?:boolean,
    departureID?: string,
    destintationID?: string,
    onSubmit?(values: any): void,
    editingFields?: any,
    onEditCancel?(): void,
    useFields?: string[],
    isSubmitting?: boolean,
    fields?: any
}

export const formatCargoValues = (values) => {
    const {
        name,
        weight,
        hazmat,
        hazmatUnCode,
        attentionTo,
        notes,
        customerID,
        contractID,
        departureID,
        destinationID,
        lastKnownController,
        groundPickup,
        approvedBy
    } = values;
    const formattedValues = {
        name,
        weight,
        hazmat,
        hazmatUnCode,
        attentionTo,
        notes,
        customerID: customerID && customerID.key,
        departureID: departureID && departureID.key,
        destinationID: destinationID && destinationID.key,
        contractID,
        lastKnownController: lastKnownController && lastKnownController.key,
        deliveredBy: departureID && departureID.label,
        groundPickup: groundPickup || false,
        approvedBy: approvedBy || null
    }
    return formattedValues;
}

const CargoSiderTab: React.FC<CargoSiderTabProps> = (props) => {
    const cargoFormRef = useRef(null);
    const [ fields, setFields ] = useState(props.fields);
    const [ editMode, setEditMode ] = useState<boolean>(false);
    const [ initials, setInitials ] = useState<string>(undefined);

    useEffect(() => {
        if (editMode && fields){
            cargoFormRef.current.validateFields();
        }
    // eslint-disable-next-line
    }, [ props.editingFields ])

    useEffect(() => {
        if(props.clearOnClose){
            cargoFormRef.current.resetFields();
        }
    }, [props.clearOnClose])

    useEffect(() => {
        if(props.fields){
            setFields(props.fields)
        }
    }, [props.fields])

    useEffect(() => {
        if (props.editingFields && !editMode){
            setFields(createFormFields(props.editingFields));
            if (props.editingFields.initials){
                setInitials(props.editingFields.initials);
            }
            setEditMode(true);
        }
        if (!props.editingFields && editMode){
            setFields(undefined);
            setInitials(undefined);
            setEditMode(false);
        }
    // eslint-disable-next-line
    }, [ props.editingFields ])

    return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1, padding: '12px', overflow: 'auto'}}>
            <CargoForm
                ref={cargoFormRef}
                useFields={props.useFields ? props.useFields : [
                    'name',
                    'weight',
                    'hazmat',
                    'hazmatUnCode',
                    'attentionTo',
                    'notes']}
                onFieldsChange={(newFields) => setFields(newFields)}
                fields={fields}
            />
            <CargoFooter
                isSubmitting={props.isSubmitting}
                editMode={editMode}
                onEditCancel={props.onEditCancel}
                initials={initials}
                onInitialsChange={(value) => setInitials(value)}
                onSubmit={(i) => {
                    cargoFormRef.current.validateFieldsAndScroll((err: any, values: any) => {
                        if (!err && props.onSubmit) {
                            const formattedValues = formatCargoValues(values)
                            if(formattedValues.approvedBy && !formattedValues.name){
                                props.onSubmit({
                                    approvedBy: formattedValues.approvedBy
                                })
                            }else {
                                props.onSubmit({
                                    ...formattedValues,
                                    _id: (props.editingFields && props.editingFields._id) ? props.editingFields._id : `CGO-${uuidv4()}`,
                                    customerID: props.orgData.getOrgIDByType('customer'),
                                    tpID: props.orgData.getOrgIDByType('transporter'),
                                    initials: i,
                                    transitType: 'INBOUND',
                                    classType: 'flytsuite.cgonode'
                                })
                            }
                            if (props.clearOnSubmit){
                                cargoFormRef.current.resetFields();
                                setInitials(undefined);
                            }
                        }
                    })
                }}
            />
        </div>
    </div>
}

export default WithOrgData(CargoSiderTab)