import { Alert, Button, Col, Divider, Dropdown, Icon, Input, Layout, Menu, message, Modal, Radio, Row, Spin, Tabs, Tooltip } from 'antd';
import { AlertProps } from 'antd/lib/alert';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { WatchQueryFetchPolicy } from 'apollo-boost';
import { FormField, getFormFieldsValues, LabelInValue } from 'common/form';
import { useBulkISNChecker } from 'components/BulkISNChecker';
import ResolveEntityID from 'components/ResolveEntityID';
import MainHeader, { MainHeaderProps } from 'components/scheduling/MainHeader';
import MainLayout from 'components/scheduling/MainLayout';
import CreatePersonSet from 'components/scheduling/PaxCgoScheduler/CreatePersonSet';
import PersonSearch, { initialSearchState, SearchState } from 'components/search/PersonSearch';
import PersonSearchByCred, { CredSearchState, initialSearchCredState } from 'components/search/PersonSearchByCred';
import SelectionCount from 'components/search/SelectionCount';
import { withUserGroups, WithUserGroupsProps } from 'components/userGroup';
import WithOrgData, { OrgDataProps, WithOrgDataProps } from 'components/WithOrgData';
import QueryResultContext from 'context/query-result-context';
import 'css/Tabs.css';
import { useMultiSelect } from 'hooks/useMultiSelect';
import { useMultiSelectByID } from 'hooks/useMultiSelectByID';
import compose from 'lodash.flowright';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useEffect, useRef, useState } from 'react';
import { graphql, Mutation, withApollo, WithApolloClient } from 'react-apollo';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import checkScheduledPax from '../checkScheduledPax';
import CargoSiderTab from './CargoSiderTab';
import GroupForm from './GroupForm';
import ManifestPrintout from './ManifestPrintout';
import SaveScheduledPaxInbCgo from './mutation/SaveScheduledPaxInbCgo';
import './PaxCgoScheduler.less';
import GetScheduledPaxInbCgo from './query/GetScheduledPaxInbCgo';
import QueryPersonnelIDs from './QueryPersonnelIDs';
import { AddPersonnelType, WithSchedulerProps, withSchedulerState } from './schedulerStateHook';
import PaxCgoSchedulerTable from './table';
import MCIcon from "../../icon";
import ButtonLink from "../../ButtonLink";


const { Content } = Layout;

export interface GroupData {
    tpID: string,
    customerID: string,
    date: string,
    name: string,
    departureID: string,
    destinationID: string,
    lastKnownController: string
}

export interface GroupFields {
    tpID: FormField<LabelInValue>,
    customerID: FormField<LabelInValue>,
    date: FormField<string>,
    name: FormField<string>,
    departureID: FormField<LabelInValue>,
    destinationID: FormField<LabelInValue>,
    lastKnownController: FormField<LabelInValue>
}

export interface OnSaveSuccessfulParams {
    variables: any,
    data: any[]
}

export interface PaxCgoSchedulerProps extends WithUserGroupsProps, WithOrgDataProps, WithSchedulerProps {
    groupData?: GroupData,
    mainHeaderRoutes?: Route[],
    onEditingChange?(editing: boolean): void,
    data?: any,
    onSave?(data: {
        checkWhiteListed?: boolean,
        customerID: string,
        orgID: string,
        departureID: string,
        destinationID: string,
        lastKnownController: string,
        scheduledFlightDate: string,
        scheduledGroup: string,
        passengers: any[],
        inboundCargo: any[]
    }): void,
    onDeleteGroup?(data: {
        customerID: string,
        orgID: string,
        departureID: string,
        destinationID: string,
        lastKnownController: string,
        scheduledFlightDate: string,
        scheduledGroup: string,
        deletedEntities: any[]
    }): void,
    onDeleteGroupSuccessful?(): void,
    deletingGroup: boolean,
    deleteErrorMessage?: string,
    saving?: boolean
    saveErrorMessage?: string,
    saveSuccessAlertProps?: Pick<AlertProps, 'message' | 'description'>,
    onSaveSuccessful?(data: OnSaveSuccessfulParams): void,
    onBackToList?(): void,
    onToScheduledFlights?(): void,
    editing?: boolean,
    paxEdit?: boolean,
    onCancel?(): void,
    onNewSetClicked?(setID: string): void,
    fetchPolicy?: WatchQueryFetchPolicy,
    pollInterval?: number,
}

export interface SaveStatus {
    saving: boolean,
    error?: Error,
    data?: any
}

export interface SchedulerState {
    saveStatus: SaveStatus,
    showSetsDrawer: boolean
}

export const initalSaveStatus = {
    saving: false,
    error: null,
    data: null
}

type TransitType = 'OUTBOUND' | 'INBOUND'

export interface IUIState {
    showSetsDrawer: boolean,
    showPersonAssign: boolean,
    transitType?: TransitType,
    siderTab: string
}

export const initialUIState: IUIState = {
    showSetsDrawer: false,
    showPersonAssign: true,
    transitType: 'OUTBOUND',
    siderTab: 'personsearch'
}

export interface IGroupDataState {
    tpID?: string,
    customerID?: string,
    date?: string,
    name?: string,
    departureID?: string,
    destinationID?: string,
    lastKnownController?: string
}

export const initialGroupDataState: IGroupDataState = {
    tpID: null,
    customerID: null,
    date: null,
    name: null,
    departureID: null,
    destinationID: null,
    lastKnownController: null
}

export interface MultiSelectClassType {
    _id: string,
    classType: string
}

export function preparePassengerForSchedule(pax: any){
    return {
        _id: pax._id,
        _rev: pax._rev,
        personID: pax.personID._id,
        transitType: pax.transitType,
        paxWeight: pax.paxWeight,
        bagWeight: pax.bagWeight,
        bagCount: pax.bagCount,
        chargeCode: pax.chargeCode && pax.chargeCode.toUpperCase(),
        scheduledOrder: pax.scheduledOrder
    }
}

export function prepareCargoForSchedule(cgo: any){
    return {
        _id: cgo._id,
        _rev: cgo._rev,
        name: cgo.name,
        weight: cgo.weight,
        hazmat: cgo.hazmat,
        hazmatUnCode: cgo.hazmatUnCode,
        attentionTo: cgo.attentionTo,
        notes: cgo.notes,
        receivedLocationID: cgo.receivedLocationID,
        scheduledOrder: cgo.scheduledOrder,
        initials: cgo.initials
    }
}

export function createSaveMutationVars(orgData: OrgDataProps, groupFieldsValues: {
    lastKnownController: string,
    departureID: string,
    destinationID: string,
    group: string,
    date: any
}, data: any[], deletedData?: any[], defaultChargeCode?: string){
    function applyDefChgCode(obj: any){
        return {
            ...obj,
            chargeCode: (obj.chargeCode && obj.chargeCode.toUpperCase()) || (defaultChargeCode && defaultChargeCode.toUpperCase())
        }
    }
    return {
        customerID: orgData.getOrgIDByType('customer'),
        orgID: orgData.getActiveOrgID(),
        departureID: groupFieldsValues.departureID,
        destinationID: groupFieldsValues.destinationID,
        lastKnownController: groupFieldsValues.lastKnownController,
        scheduledGroup: groupFieldsValues.group,
        scheduledFlightDate: moment(groupFieldsValues.date).format('YYYY-MM-DD'),
        passengers: data.filter(item => item.classType === 'flytsuite.paxnode').map(applyDefChgCode).map(preparePassengerForSchedule),
        inboundCargo: data.filter(item => item.classType === 'flytsuite.cgonode')
        .map((cgo) => ({...cgo, receivedLocationID: groupFieldsValues.departureID }))
        .map(prepareCargoForSchedule),
        deletedEntities: deletedData && deletedData.filter(item => item._rev ? true : false).map(item => ({ _id: item._id, _rev: item._rev, classType: item.classType }))
    }
}
 
const PaxCgoScheduler: React.FC<WithApolloClient<PaxCgoSchedulerProps>> = (props) => {
    const [ searchState ] = useState<SearchState>(initialSearchState);
    const [ searchStateCred ] = useState<CredSearchState>(initialSearchCredState);
    const multiSelect = useMultiSelect<MultiSelectClassType>({ primaryKey: '_id' });
    const personSearchMultiSelect = useMultiSelectByID();
    let [ UIState, S_setUIState ] = useState<IUIState>(initialUIState);
    const [ cargoEditState, setCargoEditState ] = useState<any>();
    const bulkISNChecker = useBulkISNChecker(props.client, props.orgData.getActiveOrgID())
    const groupFormRef = useRef(null);
    const [ hideSaveErrorMessage, setHideSaveErrorMessage ] = useState<boolean>(false);
    const [ hideSaveSuccessAlert, setHideSaveSuccessAlert ] = useState<boolean>(false);
    const paxEdit = props.paxEdit

    const {
        data, 
        setData, 
        groupFormData, 
        setGroupFormData, 
        resetGroupFormData,
        // isGroupFormDataTouched,
        groupData, 
        addPersonnel, 
        removeEntities, 
        movePassengers,
        defaultChargeCode,
        setDefaultChargeCode,
        clearChargeCodes,
        deletedData,
        editing,
        setEditing
    } = props.schedulerProps;

    useEffect(() => {
        if (!props.saveErrorMessage || props.saving){
            setHideSaveErrorMessage(true);
        }
        else
        {
            setHideSaveErrorMessage(false);
        }
    }, [ props.saveErrorMessage, props.saving ])
    useEffect(() => {
        setHideSaveSuccessAlert(!props.saveSuccessAlertProps || props.saving || editing ? true : false);
    // eslint-disable-next-line
    }, [
        // eslint-disable-next-line
        props.saveSuccessAlertProps && props.saveSuccessAlertProps.message,
        // eslint-disable-next-line
        props.saveSuccessAlertProps && props.saveSuccessAlertProps.description,
        props.saving,
        editing
    ])

    let loading = props.data && props.data.loading;
    let queryError = props.data && props.data.error;

    function setUIState(changes: Partial<IUIState>): void {
        S_setUIState({
            ...UIState,
            ...changes
        })
    }

    let outboundPaxData: any[] = data.filter((row) => row && row.classType === 'flytsuite.paxnode' && row.transitType === 'OUTBOUND')

    const handleAddPersonnel: AddPersonnelType = (persons, transitType) => {
        addPersonnel(persons, transitType);
        personSearchMultiSelect.clearAll();
    }

    function handleMovePassengers(){
        movePassengers(
            multiSelect.selectedItems
                .map((item) => data.find((entity) => entity && entity.classType === 'flytsuite.paxnode' && entity._id === item._id))
                .filter((pax: any) => pax && pax.transitType === UIState.transitType)
                .map((pax) => pax._id),
            UIState.transitType
        )
        multiSelect.clearAll();
        setUIState({ transitType: UIState.transitType === 'OUTBOUND' ? 'INBOUND' : 'OUTBOUND' })
    }

    function runIsnCheck () {
        if (editing){
            message.error('Cannot run ISN check in edit mode');
            return;
        }
        bulkISNChecker.runChecks(outboundPaxData.map((pax) => pax._id));
    }

    function getTransitTypeCount(entities: any[], type: TransitType): number {
        function reducer(count: number, v: any){
            if (v && v.transitType === type){
                return count + 1
            }
            else
            {
                return count
            }
        }
        return entities.reduce(reducer, 0)
    }

    function getTransitTypeWeightCount(entities: any[], type: TransitType): number {
        function reducer(weight: number, v: any){
            if (v && v.transitType === type){
                return weight + v.paxWeight + v.bagWeight
            }
            else
            {
                return weight
            }
        }
        return entities.reduce(reducer, 0)
    }

    const paxData = data
        .filter(entity => entity.classType === 'flytsuite.paxnode')
        .map((pax) => {
            if (pax.transitType === 'INBOUND'){
                return pax;
            }
            let err = bulkISNChecker.checkError(pax._id);
            if (pax.isnCheckError && !err){
                err = pax.isnCheckError;
            }
            return {
                ...pax,
                isnCheckRunning: bulkISNChecker.checkLoading(pax._id),
                isnCheckError: err
            }
        })
    
    const viewablePaxData = paxData.filter((row) => {
        return row && UIState.transitType === row.transitType && row.classType === 'flytsuite.paxnode'
    })

    const cgoData = data.filter((row) => {
        return row && row.classType === 'flytsuite.cgonode'
    })

    function handleCancel(){
        if (typeof props.onCancel === 'function'){
            props.onCancel();
        }
    }

    function saveAndCheckPaxValues(){
        let passengers: any[] = data.filter((item) => item.classType === 'flytsuite.paxnode');
        checkScheduledPax(passengers, () => {
            groupFormRef.current.props.form.validateFieldsAndScroll((err, values) => {
                if (!err){
                    let saveData = createSaveMutationVars(
                        props.orgData,
                        {
                            date: values.date,
                            lastKnownController: values.lastKnownController.key,
                            departureID: values.departure.key,
                            destinationID: values.destination.key,
                            group: values.name
                        },
                        data,
                        deletedData,
                        defaultChargeCode
                    );
                    console.log('save data', saveData);
                    props.onSave(saveData)
                }
            })
        });
    }

    function renderMainHeader(){
        let mainHeaderProps: MainHeaderProps = {
            routes: props.mainHeaderRoutes,
            actions: (
                <Row type="flex" gutter={12}>
                    <Col>
                        <Button onClick={handleCancel}>Cancel</Button>
                    </Col>
                    <Col>
                        <Button 
                        disabled={(!props.groupData && data.length === 0) || ( props.groupData && (!props.data || props.data.loading || props.data.error))}
                        onClick={() => saveAndCheckPaxValues()}
                        type="primary"
                        >
                            {props.saving ? (
                                <Icon type="loading" spin style={{ marginRight: 6 }} />
                            ) : null}
                            Save Changes
                        </Button>
                    </Col>
                </Row>
            )
        }
        if (!editing){

            let deleteScheduleDisabled = false;

            let paxOnFlight = data.filter(pax => pax.currentCarrierID);

            if (bulkISNChecker.running || paxOnFlight.length)
                deleteScheduleDisabled = true;

            let deleteScheduleBtn = (
                <Button
                    disabled={deleteScheduleDisabled}
                    type="danger"
                    loading={props.saving}
                    onClick={() => {
                        Modal.confirm({
                            title: 'Are you sure?',
                            content: `Are you sure you want to delete ${data.length} passengers/cargo?`,
                            onOk() {
                                props.onDeleteGroup(createSaveMutationVars(
                                    props.orgData,
                                    {
                                        date: props.groupData.date,
                                        lastKnownController: props.groupData.lastKnownController,
                                        departureID: props.groupData.departureID,
                                        destinationID: props.groupData.destinationID,
                                        group: props.groupData.name
                                    },
                                    [],
                                    data
                                ))
                            }
                        })
                    }}
                >
                    <Icon type="delete" style={{ marginRight: 6 }} />Delete Schedule
                </Button>
            );
            let deleteScheduleBtnWrapped = deleteScheduleBtn;

            if (paxOnFlight.length){
                deleteScheduleBtnWrapped = (
                    <Tooltip title={
                        <>
                            <p>{paxOnFlight.length} PAX/CGO are on a flight. They need to be removed from their flight before you can do this.</p>
                            <span>Click on the{'  '}<MCIcon type="helicopter-flight" />{'  '}icon to go to their assigned flight.</span>
                        </>
                    }>
                        {deleteScheduleBtn}
                    </Tooltip>
                )
            }

            if (!props.userGroups.includes('flytsuite.schedule.passenger')){
                deleteScheduleBtnWrapped = (
                    <Tooltip title="You don't have permission to delete schedules">
                        {deleteScheduleBtn}
                    </Tooltip>
                )
            }

            mainHeaderProps.actions = (
                <Row type="flex" gutter={12}>
                    <Col>
                        {deleteScheduleBtnWrapped}
                    </Col>
                    <Col>
                        <CreatePersonSet
                            groupData={props.groupData}
                            paxData={paxData}
                            onNewSetClicked={props.onNewSetClicked}
                        />
                    </Col>
                    <Col>
                        <ResolveEntityID
                            typename="Location"
                            ids={[props.groupData.departureID, props.groupData.destinationID]}
                            gqlFields={`
                            name
                            `}
                        >
                            {({ data, loading, error }: any) => {
                                return <Spin spinning={loading}>
                                    <ManifestPrintout
                                        buttonProps={{
                                            disabled: (!data || !data[0] || !data[1] || error) ? true : false
                                        }}
                                        customerID={props.orgData.getOrgIDByType('customer')}
                                        groupData={props.groupData}
                                        paxData={paxData}
                                        cgoData={cgoData}
                                        departureName={data && data[0] && data[0].name}
                                        destinationName={data && data[1] && data[1].name}
                                    />
                                </Spin>
                            }}
                        </ResolveEntityID>
                    </Col>
                    <Col>
                        {props.userGroups.includes('flytsuite.schedule.passenger') ? (
                            <Button
                                disabled={!Array.isArray(outboundPaxData) || outboundPaxData.length === 0}
                                loading={bulkISNChecker.running} onClick={() => runIsnCheck()}>Run ISN Check</Button>
                        ) : (
                            <Tooltip title="You don't have permission to run ISN checks">
                                <Button disabled>Run ISN Check</Button>
                            </Tooltip>
                        )}
                    </Col>
                    <Col>
                        {props.userGroups.includes('flytsuite.schedule.passenger') ? (
                            <Button disabled={bulkISNChecker.running} type="primary" onClick={() => setEditing(!editing)}>
                                <Icon type="edit" style={{ marginRight: 6 }} />Edit
                            </Button>
                        ) : (
                            <Tooltip title="You don't have permission to edit schedules">
                                <Button disabled><Icon type="edit" style={{ marginRight: 6 }} />Edit</Button>
                            </Tooltip>
                        )}
                    </Col>
                </Row>
            )
        }
        return <MainHeader {...mainHeaderProps} />
    }

    function renderLayoutContent(){
        if (loading){
            return <div style={{ textAlign: 'center', marginTop: '5rem' }}>
                <Spin size="large" indicator={<Icon type="loading" />} />
            </div>
        }
        if (queryError){
            return <Alert banner type="error" showIcon message="An error ocurred while loading scheduled passengers and cargo" description={queryError.message} />
        }

        let collapserIcon = 'menu-fold';
        let collapserTooltipText = 'Hide personnel search';
        if (!UIState.showPersonAssign) {
            collapserIcon = 'menu-unfold';
            collapserTooltipText = 'Show personnel search';
        }

        function renderContent(){
            return <Content className="paxcgo-scheduler-content">
                {editing && props.saveErrorMessage && !hideSaveErrorMessage ? (
                    <Alert
                        type="error"
                        showIcon
                        banner
                        message="Failed to save scheduled group"
                        description={props.saveErrorMessage}
                        closable
                        onClose={() => setHideSaveErrorMessage(true)}
                    />
                ) : null}
                {props.saveSuccessAlertProps && !hideSaveSuccessAlert ? (
                    <Alert
                        type="success"
                        showIcon
                        banner
                        message={props.saveSuccessAlertProps.message}
                        description={
                            <>
                            {props.saveSuccessAlertProps.description ? (
                                <p>{props.saveSuccessAlertProps.description}</p>
                            ) : null}
                            <Button onClick={() => {
                                runIsnCheck();
                                setHideSaveSuccessAlert(true);
                            }} type="link">Run ISN Check</Button>
                            <Divider type="vertical" />
                            <Button type="link"
                                onClick={() => {
                                    if (props.onBackToList){
                                        props.onBackToList();
                                    }
                                }}
                            >Go back to list</Button>
                            <Divider type="vertical" />
                            <Button type="link"
                                onClick={() => {
                                    if (props.onToScheduledFlights){
                                        props.onToScheduledFlights();
                                    }
                                }}
                            >Go to Scheduled Flights</Button>
                            </>
                        }
                        closable
                        onClose={() => setHideSaveSuccessAlert(true)}
                    />
                ) : null}
                {editing ? (

                    <Row type="flex" align="middle" gutter={8} className="paxcgo-scheduler-toolbar">
                        <Col>
                            <Button icon={collapserIcon} onClick={() => setUIState({ showPersonAssign: !UIState.showPersonAssign })} title={collapserTooltipText} />
                        </Col>
                        <Divider type="vertical" />
                        <Col>
                            <Dropdown
                                trigger={['click']}
                                overlay={
                                    <Menu /*onClick={handleAction}*/>
                                        <Menu.Item
                                            disabled={!multiSelect.selectedItems.length}
                                            key="remove"
                                            onClick={() => {
                                                removeEntities(multiSelect.selectedKeys);
                                                multiSelect.clearAll();
                                            }}
                                        >Remove</Menu.Item>
                                        <Menu.Item
                                            disabled={!multiSelect.selectedItems.filter((item) => item.classType === 'flytsuite.paxnode').length}
                                            key="move"
                                            onClick={handleMovePassengers}
                                        >
                                            {UIState.transitType === 'OUTBOUND' ? 'Move to inbound' : 'Move to outbound'}
                                        </Menu.Item>
                                        <Menu.Item
                                            disabled={paxData.findIndex((pax) => pax.chargeCode) === -1}
                                            key="clearChargeCodes"
                                            onClick={() => {
                                                clearChargeCodes();
                                            }}
                                        >Clear Charge Codes</Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button>
                                    Actions <Icon type="down" />
                                </Button>
                            </Dropdown>
                        </Col>
                        <Divider type="vertical" />
                        <Col>
                            <span>Default Charge Code: </span>
                            <Input
                                style={{ width: 'auto', marginLeft: '0.25rem' }}
                                onChange={e => setDefaultChargeCode(e.target.value.split(" ").join(""))}
                                value={defaultChargeCode}
                            />
                        </Col>
                        <div style={{ flex: 1 }} />
                        {multiSelect.selectedItems.length ? (
                            <>
                            <Col>
                                <SelectionCount onClear={multiSelect.clearAll} items={multiSelect.selectedItems} />
                            </Col>
                            </>
                        ) : null}
                    </Row>
                ) : null}
                <Row type="flex" className="paxcgo-scheduler-table-header">
                    <Col style={{ flex: 1 }}>
                        <GroupForm
                            wrappedComponentRef={groupFormRef}
                            disabled={!editing}
                            formData={groupFormData}
                            onFieldsChange={(fields) => setGroupFormData(fields)}
                        />
                    </Col>
                    {groupFormRef.current && groupFormRef.current.props.form.isFieldsTouched() ? (
                        <Col style={{ paddingTop: '0.2rem', paddingRight: '0.2rem' }}>
                            <Tooltip title="Reset fields" placement="topLeft">
                                <Button icon="undo" onClick={() => {
                                    groupFormRef.current.props.form.resetFields();
                                    resetGroupFormData();
                                }} />
                            </Tooltip>
                        </Col>
                    ) : null}
                </Row>
                <div style={{ padding: 12 }}>
                    <PaxCgoSchedulerTable
                        editMode={editing}
                        paxEdit={paxEdit}
                        defaultChargeCode={defaultChargeCode}
                        onCargoEdit={(data) => {
                            let showDrawer = UIState.showPersonAssign;
                            if (!data){
                                setCargoEditState(undefined);
                            }
                            else
                            {
                                setCargoEditState(data);
                                showDrawer = true;
                            }
                            setUIState({ siderTab: 'cgo', showPersonAssign: showDrawer })
                        }}
                        cargoEditID={cargoEditState && cargoEditState._id}
                        paxData={viewablePaxData}
                        cgoData={cgoData}
                        multiSelect={{
                            toggleSelection: multiSelect.toggleSelection,
                            isSelected: multiSelect.isSelected
                        }}
                        onCellEdit={(id: string, fieldKey: string, value: any) => {
                            setData(data.map((row) => {
                                if (row._id === id){
                                    return {
                                        ...row,
                                        [fieldKey]: value
                                    }
                                }
                                return row
                            }))
                        }}
                        renderPaxPanelExtra={personSearchMultiSelect.selectedItems.length ? (
                        <QueryPersonnelIDs ids={personSearchMultiSelect.selectedItems}>
                            {({ data, loading, error }: any) => {
                                return <Spin spinning={loading} indicator={<Icon type="loading" />}>
                                    {error ? (
                                        <span style={{ color: 'red', marginRight: '1rem' }}>
                                            Failed to load selected personnel
                                        </span>
                                    ) : null}
                                    <Button
                                        disabled={error ? true : false}
                                        size="small"
                                        type="primary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddPersonnel(data, UIState.transitType)
                                        }}
                                    >
                                        Add {personSearchMultiSelect.selectedItems.length} PAX to {UIState.transitType.charAt(0).toUpperCase() + UIState.transitType.slice(1).toLowerCase()}
                                    </Button>
                                </Spin>
                            }}
                            </QueryPersonnelIDs>
                        ) : null}
                        paxPanelHeader={
                            <>
                                <span style={{ marginRight: '1rem' }}>Passengers</span>
                                <Radio.Group size="small" buttonStyle="solid" value={UIState.transitType} onChange={(e) => {
                                        setUIState({ transitType: e.target.value })}
                                    }>
                                    <Radio.Button value="OUTBOUND">{`Outbound (${getTransitTypeCount(paxData, 'OUTBOUND')}). Weight: ${getTransitTypeWeightCount(paxData, 'OUTBOUND')}`}</Radio.Button>
                                    <Radio.Button value="INBOUND">{`Inbound (${getTransitTypeCount(paxData, 'INBOUND')}). Weight: ${getTransitTypeWeightCount(paxData, 'INBOUND')}`}</Radio.Button>
                                </Radio.Group>
                            </>
                        }
                    />
                </div>
            </Content>
        }

        return <Layout className="paxcgo-scheduler-layout">
            <ReflexContainer orientation="vertical">
                <ReflexElement flex={editing && UIState.showPersonAssign ? 0.30 : 0} minSize={editing && UIState.showPersonAssign ? 300 : null}>
                    <Tabs
                        type="card"
                        className="ant-tabs-flex"
                        defaultActiveKey="personsearch"
                        tabBarGutter={8}
                        tabBarStyle={{ padding: '8px 8px 0 8px', margin: 0 }}
                        activeKey={UIState.siderTab}
                        onChange={(key) => setUIState({ siderTab: key })}
                        style={{
                            height: '100%'
                        }}
                    >
                        <Tabs.TabPane key="personsearch" tab="Personnel Search">
                            <Tabs
                                type="card"
                                className="ant-tabs-flex"
                                defaultActiveKey="name"
                                tabBarGutter={8}
                                tabBarStyle={{ padding: '8px 8px 0 8px', margin: 0 }}
                                style={{
                                    height: '100%'
                                }}
                            >
                                <Tabs.TabPane key="name" tab="By Name">
                                    <PersonSearch
                                        style={{
                                            height: '100%'
                                        }}
                                        filterOptions={{
                                            lastName: searchState.lastName,
                                            firstName: searchState.firstName
                                        }}
                                        selectedItems={personSearchMultiSelect.selectedItems}
                                        onSelection={(rows) => personSearchMultiSelect.setSelectedItems(rows)}
                                        filterResults={(rows) => rows.filter((person) => paxData.findIndex((row) => row.personID === person._id) === -1)}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane key="cred" tab="By ID">
                                    <PersonSearchByCred
                                        style={{
                                            height: '100%'
                                        }}
                                        filterOptions={{
                                            credKey: searchStateCred.credKey
                                        }}
                                        selectedItems={personSearchMultiSelect.selectedItems}
                                        onSelection={(rows) => personSearchMultiSelect.setSelectedItems(rows)}
                                    />
                                </Tabs.TabPane>
                            </Tabs>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="cgo" tab={cargoEditState ? 'Edit Inbound Cargo' : 'Add Inbound Cargo'}>
                            <CargoSiderTab
                                editingFields={cargoEditState}
                                departureID={groupData && groupData.departureID}
                                destintationID={groupData && groupData.destinationID}
                                onEditCancel={() => setCargoEditState(undefined)}
                                clearOnSubmit
                                onSubmit={(values) => {
                                    let obj = {
                                        ...values,
                                        classType: 'flytsuite.cgonode'
                                    }
                                    let newData = [ ...data ];
                                    let eIdx = data.findIndex((row) => row && row._id === obj._id);
                                    if (eIdx !== -1){
                                        newData[eIdx] = obj;
                                    }
                                    else
                                    {
                                        newData.push(obj);
                                    }
                                    setData(newData);
                                    setCargoEditState(undefined);
                                }}
                            />
                        </Tabs.TabPane>
                    </Tabs>
            </ReflexElement>
            {editing && UIState.showPersonAssign ? <ReflexSplitter /> : null}
            <ReflexElement flex={ editing && UIState.showPersonAssign ? 0.70 : 1}>
                {renderContent()}
            </ReflexElement>
        </ReflexContainer>
        </Layout>
    }
    // let newScheduleEditorMessage = null;
    //
    // if (!props.groupData){
    //     newScheduleEditorMessage = (
    //         <Alert
    //             type="info"
    //             message={
    //                 <span>
    //                 Try the new V2 scheduling tool.
    //                 <ButtonLink
    //                     type="primary"
    //                     style={{ marginLeft: '12px' }}
    //                     size="small"
    //                     to={'/app/scheduling/schedule/new'}
    //                 >Try it</ButtonLink>
    //             </span>
    //             }
    //             banner
    //         />
    //     )
    // }

    return <MainLayout>
        <QueryResultContext.Provider value={props.data}>
            {renderMainHeader()}
            {/*{newScheduleEditorMessage}*/}
            {renderLayoutContent()}
        </QueryResultContext.Provider>
    </MainLayout>
}

function getQueryVars(groupData: any){
    return groupData ? {
        tpID: groupData.tpID,
        customerID: groupData.customerID,
        scheduledFlightDate:groupData.date,
        scheduledGroup: groupData.name,
        departureID: groupData.departureID,
        destinationID: groupData.destinationID,
        lastKnownController: groupData.lastKnownController
    } : null
}

const withSave = (Scheduler: React.ComponentType<PaxCgoSchedulerProps>) => (props: PaxCgoSchedulerProps) => {
    return <Mutation
        mutation={SaveScheduledPaxInbCgo}
        onCompleted={(data) => {
            let formFieldValues = getFormFieldsValues(props.schedulerProps.groupFormData);
            if (props.onSaveSuccessful){
                props.onSaveSuccessful({
                    data: data.schedulePaxInbCgo,
                    variables: createSaveMutationVars(
                        props.orgData,
                        {
                            date: formFieldValues.date,
                            departureID: formFieldValues.departure.key,
                            destinationID: formFieldValues.destination.key,
                            lastKnownController: formFieldValues.lastKnownController.key,
                            group: formFieldValues.name
                        },
                        props.schedulerProps.data,
                        props.schedulerProps.deletedData,
                        props.schedulerProps.defaultChargeCode
                    )
                })
            }
            if (props.data){
                props.data.refetch();
            }
        }}
    >
    {(schedulePaxInbCgo: (arg0: { variables: { checkWhiteListed?: boolean; customerID: string; orgID: string; departureID: string; destinationID: string; lastKnownController: string; scheduledFlightDate: string; scheduledGroup: string; passengers: any[]; inboundCargo: any[]; }; }) => void, { data, loading, error }: any) => {
        let paxCount = 0;
        let cgoCount = 0;
        if (!loading && !error && data){
            paxCount = data.schedulePaxInbCgo.paxnodes.length;
            cgoCount = data.schedulePaxInbCgo.cgonodes.length;
        }
        let saveSuccessMsg: String;
        if (paxCount > 0 || cgoCount > 0){
            saveSuccessMsg = 'Successfully saved'
        }
        if (paxCount > 0){
            saveSuccessMsg +=` ${paxCount} ${pluralize('passenger', paxCount)}`
        }
        if (paxCount > 0 && cgoCount > 0){
            saveSuccessMsg += ` and`
        }
        if (cgoCount > 0){
            saveSuccessMsg += ` ${cgoCount} cargo`
        }
        return <Scheduler
            {...props}
            saving={loading}
            saveSuccessAlertProps={data && !loading && !error && {
                message: saveSuccessMsg
            }}
            saveErrorMessage={error && error.message}
            onSave={(data) => {
                console.log('onSave called!', data);
                return schedulePaxInbCgo({
                    variables: data
                });
            }}
        />
    }}
    </Mutation>
}

const withDelete = (Scheduler: React.ComponentType<PaxCgoSchedulerProps>) => (props: PaxCgoSchedulerProps) => {
    return <Mutation
        mutation={SaveScheduledPaxInbCgo}
        onCompleted={() => {
            if (props.onDeleteGroupSuccessful){
                props.onDeleteGroupSuccessful();
            }
        }}
    >
    {(deleteGroup: (arg0: { variables: { customerID: string; orgID: string; departureID: string; destinationID: string; lastKnownController: string; scheduledFlightDate: string; scheduledGroup: string; deletedEntities: any[]; }; }) => void, { loading, error }: any) => {
        return <Scheduler
            {...props}
            deletingGroup={loading}
            deleteErrorMessage={error && error.message}
            onDeleteGroup={(data) => {
                deleteGroup({
                    variables: data
                })
            }}
        />
    }}
    </Mutation>
}

export default compose(
    withApollo,
    withUserGroups,
    WithOrgData,
    graphql<PaxCgoSchedulerProps>(
        GetScheduledPaxInbCgo,
        {
            options: props => {
                return {
                    variables: getQueryVars(props.groupData),
                    fetchPolicy: props.fetchPolicy || 'network-only',
                    pollInterval: !props.editing ? props.pollInterval || 20000 : undefined
                }
            },
            skip: props => !props.groupData
        }
    ),
    withSchedulerState<PaxCgoSchedulerProps>(props => ({
        groupData: {
            name: props.groupData && props.groupData.name,
            date: props.groupData && props.groupData.date,
            departureID: props.groupData && props.groupData.departureID,
            lastKnownController: props.groupData && props.groupData.lastKnownController,
            destinationID: props.groupData && props.groupData.destinationID,
            customerID: props.groupData ? props.groupData.customerID : props.orgData.getOrgIDByType('customer'),
            tpID: props.groupData ? props.groupData.tpID : props.orgData.getOrgIDByType('transporter')
        },
        queryData: props.data && {
            loading: props.data.loading,
            entities: props.data.getScheduledPaxInbCgo ? [...props.data.getScheduledPaxInbCgo.paxnodes, ...props.data.getScheduledPaxInbCgo.cgonodes] : [],
            departure: props.data.getDeparture,
            destination: props.data.getDestination,
            lastKnownController: props.data.getLastKnownController,
            customer: props.data.getCustomer,
            transporter: props.data.getTransporter
        },
        editing: props.editing,
        onEditingChange: props.onEditingChange,
        onRefetch: () => props.data.refetch()
    })),
    withSave,
    withDelete
)(PaxCgoScheduler)