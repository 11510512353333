import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import gql from 'graphql-tag';
import WithOrgData from '../../../WithOrgData';
import LoadingContent from '../../../LoadingContent';
import { Alert, Row, Col, Button, message, Popconfirm, List, Checkbox } from 'antd';
import EmployerSearch from '../../../EmployerSearch';
import _ from 'lodash';
import pluralize from 'pluralize';
import './index.less';

const WHITE_LIST = gql`
query WhiteList($_id: ID!){
    getWhiteList(_id: $_id){
        _id
        organizationIds {
            _id
            name
            classType
        }
    }
}
`

const SET_WHITE_LIST = gql`
mutation SetWhiteList(
    $_id: ID
    $customerID: ID!
    $tpID: ID!
    $organizationIds: [ID]
){
    setWhiteList(
        _id: $_id
        customerID: $customerID
        tpID: $tpID
        organizationIds: $organizationIds
    ){
        _id
        organizationIds {
            _id
            name
            classType
        }
    }
}
`

export class Employer extends Component {
    state = {
        selected: [],
        employersToAdd: [],
        addingEmployers: false,
        removingEmployers: false
    }
    addOrgs = () => {
        const { getWhiteList } = this.props.data;
        const { customer, transporter } = this.props.orgData;
        const organizationIds = _.union(getWhiteList.organizationIds.map(org => org._id), this.state.employersToAdd);
        this.setState({ addingEmployers: true });
        this.props.setWhiteList({
            variables: {
                _id: customer._id.replace('CUS-', 'WHL-'),
                tpID: transporter._id,
                customerID: customer._id,
                organizationIds
            }
        })
        .then(() => {
            message.success(`Successfully added ${this.state.employersToAdd.length} organizations to the white list`);
            this.setState({ employersToAdd: [] })
        })
        .catch((error) => {
            console.error(error);
            message.error("Failed to add organizations to white list")
        })
        .then(() => this.setState({ addingEmployers: false }))
    }
    removeOrgs = () => {
        const { getWhiteList } = this.props.data;
        const { customer, transporter } = this.props.orgData;
        const organizations = getWhiteList.organizationIds.filter(org => !this.state.selected.includes(org._id));
        this.setState({ removingEmployers: true });
        this.props.setWhiteList({
            variables: {
                _id: customer._id.replace('CUS-', 'WHL-'),
                tpID: transporter._id,
                customerID: customer._id,
                organizationIds: organizations.map(org => org._id)
            },
            optimisticResponse: {
                __typename: 'Mutation',
                setWhiteList: {
                    __typename: 'WhiteList',
                    _id: getWhiteList._id,
                    organizationIds: organizations
                }
            }
        })
        .then(() => {
            message.success(`Successfully removed ${this.state.selected.length} organizations from white list`);
            this.setState({ selected: [] })
        })
        .catch((error) => {
            console.error(error);
            message.error("Failed to remove organizations from white list")
        })
        .then(() => this.setState({ removingEmployers: false }))
    }
    onSelectedChange = (employerID, checked) => {
        var selected;
        if (checked) {
            selected = _.union(this.state.selected, [employerID])
        }else{
            selected = this.state.selected.filter(item => item !== employerID)
        }
        this.setState({ selected })
        if (this.props.onChange){
            this.props.onChange(selected)
        }
    }
    
    clearSelected = () => this.setState({ selected: [] })
    render() { 
        const { getWhiteList, loading, error } = this.props.data;
        if (loading) return <LoadingContent style={{margin: '10rem 0'}} />
        if (error){
            const notFoundError = error.graphQLErrors.find(err => err.errorType === 'not_found' && err.path.length === 1)
            if (!notFoundError){
                return <Alert type="error" message="Failed to load white list" description={error.message} showIcon />
            }
        }
        const employers = getWhiteList.organizationIds.filter(
            org => org.classType === 'flytsuite.employer'
        )
        .sort((a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
        });
        return (
            <Row>
                <Col span={8}>
                    <div className="whitelist-left-panel">
                        <div style={{ padding: '24px 24px 0 24px', marginBottom: '-10px' }}>
                            <Row type="flex" style={{ alignItems: 'baseline' }}>
                                <Col style={{ flex: 1 }}>
                                    <h3 style={{ margin: 0 }} >Add Employers to white list</h3>
                                </Col>
                                <Col>
                                    <Button 
                                        disabled={!this.state.employersToAdd.length}
                                        loading={this.state.addingEmployers}
                                        type="primary"
                                        onClick={() => this.addOrgs()}
                                    > Add {this.state.employersToAdd.length} Employers</Button>
                                </Col>
                            </Row>
                        </div>
                        <EmployerSearch
                            selected={this.state.employersToAdd}
                            onChange={(employersToAdd) => this.setState({ employersToAdd })}
                        />
                    </div>
                </Col>
                <Col span={16}>
                    <div style={{ padding: 24 }}>
                        <Row type="flex" style={{marginBottom: '1rem'}} >
                            <Col style={{flex: 1}}>
                                <Row type="flex" style={{ alignItems: 'baseline' }} >
                                    <h3 style={{margin: 0}} >Employers on White List</h3>
                                    <div style={{ marginLeft: '2rem' }}>
                                        {this.state.selected.length ? (
                                            <div>
                                                <span>{this.state.selected.length} Selected</span>
                                                <Button className="mc-link-btn" style={{ marginLeft: 6 }} onClick={this.clearSelected} >Clear</Button>
                                            </div>
                                        ) : null}
                                    </div>
                                </Row>
                            </Col>
                            <Col>
                                <Popconfirm 
                                    title={`Are you sure you want to remove ${this.state.selected.length} ${pluralize('employer', this.state.selected.length)}?`}
                                    okText="Remove"
                                    onConfirm={this.removeOrgs}
                                    placement="bottomLeft"
                                >
                                    <Button
                                        type="danger"
                                        disabled={this.state.selected.length ? false : true}
                                        loading={this.state.removingEmployers}
                                    >
                                        Remove {this.state.selected.length} Employers
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                        <List
                            dataSource={employers}
                            size="small"
                            bordered
                            renderItem={(employer) => {
                                const isChecked = this.state.selected.find(item => item === employer._id) ? true : false
                                return (
                                    <List.Item>
                                        <Checkbox checked={isChecked} onChange={(e) => this.onSelectedChange(employer._id, e.target.checked)} />    
                                        <div style={{ marginLeft: '1rem' }}>
                                            {employer.name}
                                        </div>
                                    </List.Item>
                                )
                            }}
                        />
                    </div>
                </Col>
            </Row>
        )
    }
}
 
export default compose(
    WithOrgData,
    graphql(
        WHITE_LIST,
        {
            options: props => ({
                variables: {
                    _id: props.orgData.customer._id.replace('CUS-', 'WHL-')
                },
                pollInterval: ['cache-only', 'cache-first'].includes(props.fetchPolicy) ? undefined : 15000,
                fetchPolicy: props.fetchPolicy || 'network-only'
            }),
            props: props => {
                const defaultWl = {
                    _id: props.ownProps.orgData.customer._id.replace('CUS-', 'WHL-'),
                    organizationIds: [],
                    __typename: 'WhiteList'
                }
                if (props.ownProps.useDefault){
                    return {
                        ...props,
                        data: {
                            ...props.data,
                            useDefault: undefined,
                            error: undefined,
                            getWhiteList: defaultWl
                        }
                    }
                }
                return props
            }
        }
    ),
    graphql(
        SET_WHITE_LIST,
        {
            name: 'setWhiteList'
        }
    )
)(Employer);