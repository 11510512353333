
import { Alert, Button, Card, Col, Divider, Drawer, Icon, Layout, message, Modal, PageHeader, Popconfirm, Row, Spin, Tabs, Tooltip } from 'antd';
import { IconProps } from 'antd/lib/icon';
import { LayoutProps } from 'antd/lib/layout';
import { TooltipProps } from 'antd/lib/tooltip';
import { isEditable } from 'common/carriable';
import { getEntitiesFromFlightLegs } from 'common/flight';
import { FlightCgo, FlightPax } from 'common/flight-leg/flight-leg-manager';
import { buildLocNameMapFromPaxCgoLists, getFlightLegManagerFromFlightQuery, graphqlCgoToFlightManCgo, graphqlPaxToFlightManPax } from 'common/flight-leg/util';
import { getContractName, getDestinationsFromLegs, getFlightDeparture, getLabelInValue, getPilotName, mergeDataWithFormFields, paxListToFlightLegs, paxOnFlightLeg, safeGet } from 'common/util';
import FlightEditorFormWrapper from 'components/flighteditor/FlightEditorFormWrapper';
import FlightLegsTable from 'components/flighteditor/FlightLegsTable';
import LegContent from 'components/flighteditor/LegContent';
import LegManualAssignContent from 'components/flighteditor/LegManualAssignContent';
import DepartingHelicopter from 'components/icons/DepartingHeli';
import PaxCgoSearch, { initialSearchState, initialSelectState, SearchState, SelectState } from 'components/search/PaxCgoSearch';
import withCognitoUser from 'components/WithCognitoUser';
import { default as withOrgData, default as WithOrgData, WithOrgDataProps } from 'components/WithOrgData';
import 'css/Drawer.css';
import gql from 'graphql-tag';
import uniqBy from 'lodash/uniqBy';
import SetFlight from 'Mutations/SetFlight';
import GetFlight from 'Queries/GetFlight';
import React, { useEffect, useState } from 'react';
import { Mutation, Query, QueryResult, useApolloClient, withApollo, WithApolloClient } from 'react-apollo';
import { FlightLeg } from 'schema';
import useManifestPrintout from '../../components/scheduling/Printout/useManifestPrintout';
import './LiveFlightEditor.less';
import LiveLegsAdd from './LiveLegsAdd';

const { Content } = Layout;

const UPDATE_FLIGHT_LEGS = gql`
mutation UpdateFlightLegs($payload: FlightNodeInput!, $orgID: ID!){
    setFlight(payload: $payload, orgID: $orgID){
        _id
        paxIDList {
            _id
            lastName
            firstName
            scheduledOrder
            scheduledGroup
            employerID {
                _id
                name
            }
            transitType
            departureID {
                _id
                name
            }
            destinationID {
                _id
                name
            }
            paxWeight
            bagWeight
            bagCount
            classType
        }
        cgoIDList {
            _id
            name
            scheduledOrder
            approvedBy
            scheduledGroup
            transitType
            departureID {
                _id
                name
            }
            destinationID {
                _id
                name
            }
            weight
            attentionTo
            initials
            notes
            classType
        }
        legs
    }
}
`

export interface LiveFlightEditorProps extends WithOrgDataProps {
    flightId: string,
    style?: React.CSSProperties,
    onBack?: (e: React.MouseEvent<HTMLDivElement>) => void,
    onIDChange?(newID: string, prevID?: string): void
}

const renderEditDisableIcon = (props?: { tooltipProps?: TooltipProps } & IconProps) => {
    let { tooltipProps, ...rest } = props;
    tooltipProps = {
        placement: 'bottom',
        ...tooltipProps
    }
    return <Tooltip {...tooltipProps}>
        <Icon 
            type="lock"
            style={{
                fontSize: '1.5rem',
                marginTop: '-1.5rem',
                top: '0.2rem',
                marginLeft: '0.25rem',
                marginRight: '0.25rem',
                position: 'relative'
            }}
            {...rest}
        />
    </Tooltip>
}

interface SelectedEntitiesState {
    pax: string[] | number[],
    cgo: string[] | number[]
}

var PrintoutButton = ({flight, manifestType, buttonText, ...restProps}) => {
    const [ fetchPrintout, fetching ] = useManifestPrintout('FlightWithLegs', manifestType);
    const userAttr = restProps.cognitoUser.attributes
    const orgData = restProps.orgData
    return (
        <Query
            query={gql`
                query GetCustomerManifestPrintout($id: ID!){
                    getCustomer(_id: $id){
                        _id
                        name
                        manifestPrintout {
                            type
                            layouts {
                                name
                                logo
                            }
                        }
                    }
                }
            `}
            variables={{ id: orgData.customer._id }}
        >
        {({ data }): any => {
            const manifestPrintouts = safeGet(['getCustomer', 'manifestPrintout'], data) || [];
            const flightManifest = manifestPrintouts.find((m) => m.type === 'Flight'); 
            const hessLayout = flightManifest && flightManifest.layouts.find((l) => l.name === 'Hess');
            const logoURL = hessLayout && hessLayout.logo;
            const handleClick = () => {
                if (!flight){
                    return;
                }
                const legs = flight.legsArray || [];
                const body = {
                    issuedBy: `${userAttr.given_name} ${userAttr.family_name}`,
                    orgName: orgData.customer.name,
                    logoURL: logoURL,
                    desig: flight.desig,
                    departure: flight.departureID ? flight.departureID.name : legs && legs[0] && legs[0].departure,
                    aircraftNo: flight.aircraftID && flight.aircraftID.tailNum,
                    scheduledFlightDate: flight.scheduledFlightDate,
                    legs: legs.map((leg) => {
                        return {
                            departure: leg.departure,
                            destination: leg.destination,
                            passengers: leg.paxIDs.map((pax) => {
                                return {
                                    _id: pax._id,
                                    lastName: pax.lastName,
                                    firstName: pax.firstName,
                                    employer: pax.employerID && pax.employerID.name,
                                    chargeCode: pax.chargeCode && pax.chargeCode.toUpperCase(),
                                    paxWeight: pax.paxWeight,
                                    bagWeight: pax.bagWeight,
                                    departure: pax.departureID && pax.departureID.name,
                                    destination: pax.destinationID && pax.destinationID.name,
                                    dob: pax.personID && pax.personID.dob,
                                    nation: pax.personID && pax.personID.nation,
                                    credentials: pax.personID && pax.personID.credentials
                                }
                            }),
                            cargo: leg.cgoIDs.map((cgo) => {
                                return {
                                    _id: cgo._id,
                                    name: cgo.name,
                                    weight: cgo.weight,
                                    chargeCode: cgo.chargeCode && cgo.chargeCode.toUpperCase(),
                                    departure: cgo.departureID && cgo.departureID.name,
                                    destination: cgo.destinationID && cgo.destinationID.name,
                                    attentionTo: cgo.attentionTo,
                                    deliveredBy: cgo.deliveredBy,
                                    transitType: cgo.transitType,
                                    approvedBy: cgo.approvedBy
                                }
                            })
                        }
                    })
                }
                fetchPrintout(body, true, `${orgData.customer.name} Flight Manifest.xlsx`)
                .catch((err) => {
                    message.error('Failed to download printout', 3).then(() => {
                        message.error(err.message, 5);
                    }, null)
                })
            }
            return (
                <Button {...restProps} onClick={handleClick} loading={fetching}>{buttonText}</Button>
            )
        }}
        </Query>
    )
}

PrintoutButton = WithOrgData(withCognitoUser(PrintoutButton))

const LiveFlightEditor: React.FC<WithApolloClient<LiveFlightEditorProps>> = (props) => {
    const [ selectedLeg, setSelectedLeg ] = useState<string>('0');
    const [ selectedLegs, setSelectedLegs ] = useState<string[]>([]);
    const [ legTabKey, setLegTabKey ] = useState<'pax' | 'cgo'>('pax');
    const [ selectedEntities, setSelectedEntities ] = useState<SelectedEntitiesState>({pax: [], cgo: []})
    const [ formFields, setFormFields ] = useState<any>({});
    const [ editingForm, setEditingForm ] = useState<boolean>(false);
    const [ assignerOpen, setAssignerOpen ] = useState<boolean>(false);
    const [ addLegOpen, setAddLegOpen ] = useState<boolean>(false);
    const [ searchSelections, setSearchSelections ] = useState<SelectState>(initialSelectState);
    const [ searchState, setSearchState ] = useState<SearchState>(initialSearchState);
    const [ assigning, setAssigning ] = useState<boolean>(false);
    const [ forceRefresh, setForceRefresh ] = useState<boolean>(false);
    const [ removingEntities, setRemovingEntities ] = useState<boolean>(false);
    const [ tabState, setTabState] = useState<string>('auto')
    const client = useApolloClient();

    useEffect(() => {
        setSelectedEntities({ pax: [], cgo: [] });
    }, [ selectedLeg ])

    const handleEditingForm = (editing: boolean, data: any) => {
        setEditingForm(editing);
        setFormFields(data);
    }
    
    return <Query
        query={GetFlight}
        variables={{ flightId: props.flightId }}
        fetchPolicy="network-only"
        pollInterval={10000}
    >
    {({ data, loading, error, refetch }: QueryResult) => {
        let flightData: any;
        if (data && data.getFlight){
            flightData = data.getFlight || {};
            if(!flightData.departureID){
                flightData.departureID = {}
                flightData.departureID["_id"] = flightData.legsArray[0].departureID
                flightData.departureID["name"] = flightData.legsArray[0].departure
            }
        }
        let formFieldsWithValues: any;
        if (!editingForm){
            formFieldsWithValues = flightData && mergeDataWithFormFields({
                scheduledFlightDate: flightData.scheduledFlightDate,
                desig: flightData.desig,
                contract: flightData.contract && getLabelInValue('_id', null, getContractName)(flightData.contract),
                pilot: flightData.pilot && getLabelInValue('_id', null, getPilotName)(flightData.pilot),
                copilot: flightData.copilot && getLabelInValue('_id', null, getPilotName)(flightData.copilot),
                aircraft: flightData.aircraftID && getLabelInValue('_id', 'tailNum')(flightData.aircraftID)
            }, formFields);
        }
        else {
            formFieldsWithValues = formFields
        }

        let paxIDMap = new Map<string, any>();
        flightData?.paxIDList?.forEach(pax => paxIDMap.set(pax._id, pax));
        let cgoIDMap = new Map<string, any>();
        flightData?.cgoIDList?.forEach(cgo => cgoIDMap.set(cgo._id, cgo));

        let flightState: string = flightData && flightData.state;
        let liveEditedState: string = flightData && flightData.liveEditedState

        let subTitle: JSX.Element | string;
        if (flightData && flightData.desig){
            subTitle = flightData.desig;
        }
        let subTitleIcon: JSX.Element;
        if (flightState === 'LOCKED'){
            subTitleIcon = renderEditDisableIcon({ tooltipProps: { title: 'Flight is locked. Editing is disabled.' } });
        }
        if (flightState === 'DEPARTED'){
            subTitleIcon = renderEditDisableIcon({ component: DepartingHelicopter, type: null, tooltipProps: { title: 'Flight has departed. Editing is disabled.' } })
        }
        if (flightState === 'OVERNIGHT'){
            subTitleIcon = renderEditDisableIcon({ tooltipProps: { title: 'Flight is overnight. Editing is disabled.' } });
        }
        if(liveEditedState === "BLESSED"){
            subTitleIcon = renderEditDisableIcon({ tooltipProps: { title: 'Flight has been approved. Editing is disabled. Please contact a CSR to make changes.' } });
        }
        if (subTitleIcon){
            subTitle = <span>{subTitle} {subTitleIcon}</span>
        }
        else if (loading){
            subTitle = <Spin size="small" indicator={<Icon type="loading" />} />
        }
        let editable = isEditable(flightData);

        if (!editable) {
            if (selectedEntities.pax.length || selectedEntities.cgo.length){
                setSelectedEntities({ pax: [], cgo: [] });
            }
            if (assignerOpen){
                setAssignerOpen(false);
            }
        }

        function checkEditable(){
            let t = '';
            if (flightState){
                t = t.toLowerCase();
                t = t.charAt(0).toUpperCase() + t.substring(1);
            }
            if (!editable){
                message.warning(`${t} flights cannot be edited.`)
            }
            return editable;
        }

        async function removeRedundantDestination(locID){
            let flManager = getFlightLegManagerFromFlightQuery(flightData);
    
            if (!flManager.removeLocationIfRedundant(locID)){
                return;
            }
    
            let legs = flManager.buildFlightLegs();
    
            let flightid = flightData._id;

            let legsJson = JSON.stringify( Object.assign({}, legs) );
    
            try{
                var res = await client.mutate({
                    mutation: UPDATE_FLIGHT_LEGS,
                    variables: {
                        payload: {
                            _id: flightid,
                            legs: legsJson,
                            tpID: props.orgData.transporter._id,
                            customerID: props.orgData.customer._id
                        },
                        orgID: props.orgData.getActiveOrgID()
                    },
                    optimisticResponse: {
                        __typename: 'Mutation',
                        setFlight: {
                            __typename: 'FlightNode',
                            _id: flightid,
                            legs: legsJson,
                            legsArray: legs
                        }
                    }
                })
            }
            catch(err){
                console.error(err);
                message.error("Failed to remove redundant destination.");
                return;
            }
            message.success('Successfully removed redundant destination.');
            refetch();
            return res;
        }

        function handleFlightLegsChange(legs: FlightLeg[], paxObjList: FlightPax[], cgoObjList: FlightCgo[], useOptimisticResponse: boolean=false, liveEditedState: String="IS_EDITING") {
            if (!checkEditable()) return
            let formattedLegs = legs.map((leg) => {
                let objID = (obj: any) => obj && typeof obj === 'object' && obj._id ? obj._id : obj;
                return {
                    ...leg,
                    paxIDs: leg.paxIDs.map(objID),
                    cgoIDs: leg.cgoIDs.map(objID)
                }
            })
            let json = JSON.stringify(Object.assign({}, formattedLegs));
            return props.client.mutate({
                mutation: gql`
                mutation SetFlightLegs($payload: FlightNodeInput!, $orgID: ID!){
                    setFlight(payload: $payload, orgID: $orgID){
                        _id
                        desig
                        legs
                        paxIDList {
                            _id
                            lastName
                            firstName
                            scheduledOrder
                            scheduledGroup
                            employerID {
                                _id
                                name
                            }
                            transitType
                            departureID {
                                _id
                                name
                            }
                            destinationID {
                                _id
                                name
                            }
                            paxWeight
                            bagWeight
                            bagCount
                            classType
                        }
                        cgoIDList {
                            _id
                            name
                            scheduledOrder
                            approvedBy
                            scheduledGroup
                            transitType
                            departureID {
                                _id
                                name
                            }
                            destinationID {
                                _id
                                name
                            }
                            weight
                            attentionTo
                            initials
                            notes
                            classType
                        }
                        legsArray {
                            bagCount
                            bagWeight
                            cgoCount
                            cgoWeight
                            chosen
                            departure
                            departureID
                            destination
                            destinationID
                            order
                            paxCount
                            paxWeight
                            usefulLoad
                            seats
                            paxIDs {
                                _id
                                lastName
                                firstName
                                classType
                                employerID {
                                    _id
                                    name
                                }
                                chargeCode
                                paxWeight
                                bagWeight
                                departureID {
                                    _id
                                    name
                                }
                                destinationID {
                                    _id
                                    name
                                }
                                personID {
                                    _id
                                    dob
                                }
                                transitType
                            },
                            cgoIDs {
                                _id
                                name
                                weight
                                approvedBy
                                checkedInTime
                                chargeCode
                                classType
                                departureID {
                                    _id
                                    name
                                }
                                destinationID {
                                    _id
                                    name
                                }
                                deliveredBy
                                attentionTo
                                transitType
                                approvedBy
                            }
                        }
                    }
                }
                `,
                variables: {
                    payload: {
                        _id: props.flightId,
                        customerID: props.orgData.getOrgIDByType('customer'),
                        tpID: props.orgData.getActiveOrgID(),
                        legs: json,
                        paxIDList: paxObjList.map(obj => obj._id),
                        cgoIDList: cgoObjList.map(obj => obj._id),
                        liveEditedState: liveEditedState
                    },
                    orgID: props.orgData.getActiveOrgID()
                },
                optimisticResponse: useOptimisticResponse ? {
                    __typename: 'Mutation',
                    setFlight: {
                        __typename: 'FlightNode',
                        ...flightData,
                        _id: props.flightId,
                        paxIDList: paxObjList,
                        cgoIDList: cgoObjList,
                        legs: json,
                        legsArray: legs
                    }
                } : undefined
            })
            .then(() => {
                message.success('Flight updated successfully!');
                refetch();
            })
            .catch((err) => {
                message.error('Failed to update flight.', 2, () => {
                    message.error(err.message, 5);
                })
            })
        }

        const onLegsReorder = (dragIndex, hoverIndex) => {
            const { legs } = data.getFlight;
            if (!legs) return null;

            let flManager = getFlightLegManagerFromFlightQuery(data.getFlight);

            // const departureID = getFlightDeparture(data.getFlight);
            const parsedLegs = Object.values(JSON.parse(legs));
            if (dragIndex === parsedLegs.length - 1){
                message.warn("The last flight leg cannot be reordered")
                return null
            }
            if (hoverIndex === parsedLegs.length -1){
                message.warn("The last flight leg must be a return to the flight departure location")
                return null
            }
            
            const destinations = getDestinationsFromLegs(parsedLegs);

            flManager.moveDestination(destinations[dragIndex]._id, destinations[hoverIndex]._id);

            // const startSlice = dragIndex < hoverIndex ? dragIndex : hoverIndex;
            // const endSlice = dragIndex > hoverIndex ? dragIndex : hoverIndex;
            // const reorderedDestinations = [...destinations.slice(0, startSlice), destinations[endSlice], ...destinations.slice(startSlice + 1, endSlice), destinations[startSlice], ...destinations.slice(endSlice + 1)]
            // var newAllList = [];
    
            // const filteredDestinations = reorderedDestinations.filter((loc, idx, arr) => {
            //     if(idx !== arr.length -1){
            //         if(arr[idx+1]._id === loc._id){
            //             return false;
            //         }
            //     }
            //     return loc; 
            // });
            
            // filteredDestinations.forEach(location => {
            //     const sortPaxByOrder = (a, b) => {
            //         return a.scheduledOrder - b.scheduledOrder
            //     }
            //     var newList = [];
            //     if(paxIDList && paxIDList.length > 0){
            //         newList = newList.concat(paxIDList.filter(pax => pax.destinationID._id === location._id));
            //     }
            //     if(cgoIDList && cgoIDList.length > 0){
            //         newList = newList.concat(cgoIDList.filter(cgo => cgo.destinationID._id === location._id));
            //     }
            //     const outbound = newList.filter(pax => pax.transitType === 'OUTBOUND').sort(sortPaxByOrder);
            //     const transfer = newList.filter(pax => pax.transitType === 'TRANSFER').sort(sortPaxByOrder);
            //     const inbound = newList.filter(pax => pax.transitType === 'INBOUND').sort(sortPaxByOrder);
            //     newAllList = newAllList.concat(outbound);
            //     newAllList = newAllList.concat(transfer);
            //     newAllList = newAllList.concat(inbound);
            // })

            // newAllList = Array.from(new Set(newAllList));
            // const newPaxList = newAllList.filter(pax => pax.classType === 'flytsuite.paxnode' );
            // const newCgoList = newAllList.filter(cgo => cgo.classType === 'flytsuite.cgonode' );
            // const newLegs = paxListToFlightLegs(departureID, newPaxList, newCgoList, filteredDestinations);
            const newLegs = flManager.buildFlightLegs();

            return newLegs;
        }

        const handleRemoveFromLegSelectedEntities = async () => {
            if (!checkEditable()) return
            let legs: FlightLeg[] = flightData.legsArray;
            let paxAndCgo = [...flightData.paxIDList, ...flightData.cgoIDList]
            let combinedRemoveEntities = [...selectedEntities.pax, ...selectedEntities.cgo];

            paxAndCgo = paxAndCgo.filter((e) => {
                if (!e) return false;
                return !combinedRemoveEntities.includes(e._id);
            });

            const newPaxList = paxAndCgo.filter(pax => pax.classType === 'flytsuite.paxnode' );
            const newCgoList = paxAndCgo.filter(cgo => cgo.classType === 'flytsuite.cgonode' );
            if(tabState === 'manual') {
                selectedLegs.forEach(order => {
                    let leg = legs[order]
                    legs[order] = paxOnFlightLeg(leg, leg.paxIDs.filter(pax => newPaxList.map(p => p._id).includes(pax._id)), leg.cgoIDs.filter(cgo => newCgoList.map(c => c._id).includes(cgo)))
                })
                setRemovingEntities(true);
                return handleFlightLegsChange(legs, newPaxList, newCgoList, true)
                    .then(() => {
                        setRemovingEntities(false);
                        setSelectedEntities({ pax: [], cgo: [] });
                        message.success('Successfully removed PAX/CGO from flight.')
                    })
                    .catch((err) => {
                        setRemovingEntities(false);
                        message.error('Failed to remove PAX/CGO from flight.', 2).then(() => {
                            message.error(err.message, 5);
                        }, null)
                    })
            }
        }

        const handleRemoveSelectedEntities = async () => {
            if (!checkEditable()) return
            // let legs: FlightLeg[] = flightData.legsArray;
            // let newEntities = getEntitiesFromFlightLegs<any>(legs);
            // newEntities = newEntities.map((e) => {
            //     return {
            //         ...e,
            //         departure: e.departureID,
            //         destination: e.destinationID
            //     }
            // })
            // let combinedSelectedEntities: (string | number)[] = [...selectedEntities.pax, ...selectedEntities.cgo];
            // newEntities = newEntities.filter((e) => {
            //     if (!e) return false;
            //     return !combinedSelectedEntities.includes(e._id);
            // })
            // const departureID = getFlightDeparture(data.getFlight);
            // const newPaxList = newEntities.filter(pax => pax.classType === 'flytsuite.paxnode' );
            // const newCgoList = newEntities.filter(cgo => cgo.classType === 'flytsuite.cgonode' );
            // if(selectedLegs.length > 0) {
            //     let newLegs: any = []
            //     legs.forEach(leg => {
            //         newLegs.push(paxOnFlightLeg(leg, leg.paxIDs.filter(pax => newPaxList.map(p => p._id).includes(pax._id)), leg.cgoIDs.filter(cgo => newCgoList.map(c => c._id).includes(cgo))))
            //     })
            //     legs = newLegs;
            // }else {
            //     legs = paxListToFlightLegs(departureID, newPaxList, newCgoList);
            // }

            let flManager = getFlightLegManagerFromFlightQuery(flightData);
            selectedEntities.pax.forEach((id) => flManager.removePassenger(id));
            selectedEntities.cgo.forEach((id) => flManager.removeCgo(id));

            let legs = flManager.buildFlightLegs();

            setRemovingEntities(true);
            return handleFlightLegsChange(
                legs,
                flManager.getPaxIDList().map((id) => paxIDMap.get(id)),
                flManager.getCgoIDList().map((id) => cgoIDMap.get(id)),
                true
            )
            .then(() => {
                setRemovingEntities(false);
                setSelectedEntities({ pax: [], cgo: [] });
                message.success('Successfully removed PAX/CGO from flight.')
            })
            .catch((err) => {
                setRemovingEntities(false);
                message.error('Failed to remove PAX/CGO from flight.', 2).then(() => {
                    message.error(err.message, 5);
                }, null)
            })
        }

        function renderHeader(){
            return <PageHeader
                className="live-flight-editor-header"
                title="Flight Editor"
                subTitle={subTitle}
                onBack={props.onBack}
                extra={[
                    <PrintoutButton
                        buttonText={'Download Printout'}
                        manifestType ={'Manifest'}
                        flight={data && data.getFlight}
                        disabled={loading || error || !data || !data.getFlight}
                    />,
                    <PrintoutButton
                        buttonText={'Download Customs Printout'}
                        manifestType ={'Hess'}
                        flight={data && data.getFlight}
                        disabled={loading || error || !data || !data.getFlight}
                    />,
                    <Button disabled={loading} type="primary">Assign PAX/CGO</Button>
                ]}
            />
        }

        let layoutProps: LayoutProps = {
            style: { ...(props.style || {}), overflow: 'hidden' }
        }

        if (!flightData && loading){
            return <Layout {...layoutProps}>
                {renderHeader()}
                <Content>
                    <div style={{ textAlign: 'center', marginTop: '5rem' }}>
                        <Spin size="large" indicator={<Icon type="loading" />} />
                    </div>
                </Content>
            </Layout>
        }
        let legsArray: FlightLeg[] = [];
        if (flightData?.legs){
            let parsedLegs = JSON.parse(flightData.legs);
            parsedLegs = Array.from(Object.values(parsedLegs).values());
            legsArray = parsedLegs;
        }
        legsArray = legsArray.map((leg: FlightLeg) => {
            let paxIDs = (leg && leg.paxIDs) || [];
            let cgoIDs = (leg && leg.cgoIDs) || [];
            function locIDToObj(id: any){
                let obj = flightData.paxIDList.find(pax => pax._id === id);
                if (!obj){
                    obj = flightData.cgoIDList.find(cgo => cgo._id === id);
                }
                return {
                    ...obj,
                    departure: obj.departureID,
                    destination: obj.destinationID
                }
            }
            paxIDs = paxIDs.map(locIDToObj);
            cgoIDs = cgoIDs.map(locIDToObj);
            return {
                ...leg,
                paxIDs,
                cgoIDs
            }
        })
        const handleAssignEntities = async () => {
            if (!checkEditable()) return
            setAssigning(true);
            try{
                let entities: any = [];
                let paxList: any[] = [];
                let cgoList: any[] = [];
                
                // legsArray.forEach((leg) => {
                //     if (Array.isArray(leg && leg.paxIDs) && leg.paxIDs.length){
                //         entities.push(...leg.paxIDs);
                //     }
                //     if (Array.isArray(leg && leg.cgoIDs) && leg.cgoIDs.length){
                //         entities.push(...leg.cgoIDs);
                //     }
                // })

                if (searchSelections.pax.length){
                    var { data: { resolve_entity_ids: pax } } = await props.client.query({
                        variables: {
                            ids: searchSelections.pax
                        },
                        query: gql`
                        query ResolvePax($ids: [ID!]!){
                            resolve_entity_ids(ids: $ids, typename: "PaxNode"){
                                ... on PaxNode {
                                    _id
                                    lastName
                                    firstName
                                    classType
                                    chargeCode
                                    departureID {
                                        _id
                                        name
                                    }
                                    destinationID {
                                        _id
                                        name
                                    }
                                    employerID {
                                        _id
                                        name
                                    }
                                    paxWeight
                                    bagWeight
                                    bagCount
                                    transitType
                                }
                            }
                        }
                        `
                    })
                    paxList.push(...pax);
                }
                if (searchSelections.cgo.length){
                    var { data: { resolve_entity_ids: cgo } } = await props.client.query({
                        variables: {
                            ids: searchSelections.cgo
                        },
                        query: gql`
                        query ResolvePax($ids: [ID!]!){
                            resolve_entity_ids(ids: $ids, typename: "CargoNode"){
                                ... on CargoNode {
                                    _id
                                    name
                                    classType
                                    approvedBy
                                    chargeCode
                                    departureID {
                                        _id
                                        name
                                    }
                                    destinationID {
                                        _id
                                        name
                                    }
                                    transitType
                                    weight
                                    deliveredBy
                                    attentionTo
                                    approvedBy
                                }
                            }
                        }
                        `
                    })
                    cgoList.push(...cgo);
                }
                // entities = entities.map(e => {
                //     return {
                //         ...e,
                //         departure: e.departureID,
                //         destination: e.destinationID
                //     }
                // })
                // entities = uniqBy(entities, (item: any) => item && item._id);

                const departureID = getFlightDeparture(data.getFlight);
                let legs = null;

                let newPaxList = [...flightData.paxIDList, ...paxList];
                let newCgoList = [...flightData.cgoIDList, ...cgoList];

                let paxIDMap = new Map<string, any>();
                let cgoIDMap = new Map<string, any>();

                newPaxList.forEach(obj => paxIDMap.set(obj._id, obj));
                newCgoList.forEach(obj => cgoIDMap.set(obj._id, obj));
                
                if(selectedLegs.length > 0 ){
                    const paxSearch = paxList.filter(pax => searchSelections.pax.includes(pax._id))
                    const cgoSearch = cgoList.filter(cgo => searchSelections.cgo.includes(cgo._id))
                    const resultLegs = legsArray;
                    selectedLegs.forEach(idx => {
                        var leg = resultLegs[Number.parseInt(idx)]
                        var pax = paxSearch.concat(leg.paxIDs).filter((v, i, a) => a.map(e => e._id).indexOf(v._id) === i) //Filter out dupes
                        var cgo = cgoSearch.concat(leg.cgoIDs).filter((v, i, a) => a.map(e => e._id).indexOf(v._id) === i) //Filter out dupes
                        resultLegs[idx] = paxOnFlightLeg(leg, pax, cgo)
                    })
                    legs = resultLegs;
                }else {
                    let flManager = getFlightLegManagerFromFlightQuery(flightData, buildLocNameMapFromPaxCgoLists(paxList, cgoList));
                    paxList.forEach((pax) => flManager.addPassenger(graphqlPaxToFlightManPax(pax)));
                    cgoList.forEach((cgo) => flManager.addCgo(graphqlCgoToFlightManCgo(cgo)));
                    newPaxList = flManager.getPaxIDList().map(id => paxIDMap.get(id));
                    newCgoList = flManager.getCgoIDList().map(id => cgoIDMap.get(id));
                    legs = flManager.buildFlightLegs();
                }

                handleFlightLegsChange(legs, newPaxList, newCgoList, false)
                .then(() => {
                    setAssignerOpen(false);
                    setAssigning(false);
                    setSearchSelections(initialSelectState);
                    setSearchState(initialSearchState);
                })
                .catch(() => {
                    setAssigning(false);
                });
            }
            catch(err){
                console.error(err);
                message.error('Failed to assign PAX/CGO', 2)
                .then(() => message.error(err.message, 5), () => null);
                setAssigning(false);
            }
        }
        function renderContent(){

            let flManager = getFlightLegManagerFromFlightQuery(flightData);

            return <>
                {error ? (
                    <Alert
                        type="error"
                        showIcon
                        message="Failed to load flight"
                        description={error.message}
                        style={{ marginBottom: 12 }}
                    />
                ) : null}
                <Card size="small" style={{ marginBottom: 12 }}>
                    <Mutation
                        mutation={SetFlight}
                        onCompleted={(data) => {
                            message.success('Flight saved succsessfuly!');
                            setEditingForm(false);
                            if (data.setFlight && data.setFlight._id !== flightData._id && props.onIDChange){
                                props.onIDChange(data.setFlight._id, flightData._id);
                            }
                        }}
                    >
                    {(saveForm: any, { loading: saving }) => {
                        const handleFormSave = (fields: any, mutation: any, flightData: any, tpID: string, customerID: string) => {
                            if (!checkEditable()) return
                            if (!fields || !flightData || !mutation) return;
                            const variables = {
                                payload: {
                                    _id: props.flightId,
                                    desig: safeGet(['desig', 'value'], formFieldsWithValues),
                                    aircraftID: safeGet(['aircraft', 'value', 'key'], formFieldsWithValues),
                                    contractID: safeGet(['contract', 'value', 'key'], formFieldsWithValues),
                                    pilotID: safeGet(['pilot', 'value', 'key'], formFieldsWithValues),
                                    copilotID: safeGet(['copilot', 'value', 'key'], formFieldsWithValues),
                                    tpID,
                                    customerID,
                                    legs: flightData.legs
                                },
                                orgID: props.orgData.getActiveOrgID()
                            }
                        saveForm({ variables })
                        }
                        return <FlightEditorFormWrapper
                            {...formFieldsWithValues}
                            locationID={getFlightDeparture(data.getFlight) && getFlightDeparture(data.getFlight)._id}
                            onEditChange={(editing) => handleEditingForm(editing, formFieldsWithValues)}
                            onChange={(changedFields) => setFormFields({ ...formFields, ...changedFields })}
                            editing={editingForm}
                            onSave={props.orgData.customer && props.orgData.transporter && ((fields) => handleFormSave(fields, saveForm, flightData, props.orgData.transporter._id, props.orgData.customer._id))}
                            saving={saving}
                            disabled={!editable}
                        />
                    }}
                    </Mutation>
                </Card>
                <Row gutter={12}>
                    <Tabs
                        className="live-flight-editor-legs-tabs"
                        defaultActiveKey="auto"
                        animated={false}
                        onChange={tab => {
                            setTabState(tab)
                            if(tab === 'auto'){
                                setSelectedLegs([])
                            }
                        }}>
                        <Tabs.TabPane key="auto" tab="Auto Assign">
                            <Col span={8}>
                                <FlightLegsTable
                                    legs={legsArray}
                                    checkLegIsRedundant={leg => flManager.isLocationRedundant(leg.destinationID)}
                                    onMoveRow={(di, hi) => {
                                        try {
                                            let newLegs = onLegsReorder(di, hi);
                                            if (newLegs){
                                                handleFlightLegsChange(newLegs, flightData.paxIDList, flightData.cgoIDList, true);
                                            }
                                        }
                                        catch(err){
                                            console.error(err);
                                            if (err.name === 'ReorderError'){
                                                message.warning(err.message);
                                            }
                                            else
                                            {
                                                message.error('An error occurred while reordering legs', 2, () => {
                                                    message.error(err.message, 5);
                                                });
                                            }
                                        }
                                    }}
                                    onRow={(_, index) => {
                                        return {
                                            onClick: () => setSelectedLeg(String(index)),
                                            style: { cursor: String(index) !== selectedLeg ? 'pointer' : 'default' }
                                        }
                                    }}
                                    rowSelection={{
                                        onChange: (keys: string[]) => setSelectedLeg(keys[0]),
                                        selectedRowKeys: [ selectedLeg ],
                                        type: 'radio'
                                    }}
                                />
                            </Col>
                            <Col span={16}>
                                {legsArray[Number(selectedLeg)] ? (
                                    <LegContent
                                        leg={legsArray[Number(selectedLeg)]}
                                        onLegTabChange={setLegTabKey}
                                        legTabKey={legTabKey}
                                        renderTitle={(title) => <span>
                                            <span>{title}</span>
                                            {flManager.isLocationRedundant(flightData?.legs ? JSON.parse(flightData.legs)[selectedLeg].destinationID : undefined) ? (
                                                <Tooltip title={`No PAX/CGO are planned to visit this destination. Removal is recommended.`}>
                                                    <Button
                                                        type='danger'
                                                        style={{ marginLeft: '12px' }}
                                                        onClick={() => Modal.confirm({
                                                            title: 'Are you sure you want to remove this redundant leg?',
                                                            onOk: () => removeRedundantDestination(JSON.parse(flightData.legs)[selectedLeg].destinationID),
                                                            okButtonProps: {
                                                                children: 'Remove',
                                                                type: 'danger'
                                                            }
                                                        })}
                                                    >Remove Redundant Leg</Button>
                                                </Tooltip>
                                            ) : null}
                                        </span>}
                                        cardProps={{
                                            extra: <>
                                                {selectedEntities.pax.length > 0 ? (
                                                    <span>{selectedEntities.pax.length} PAX</span>
                                                ) : null}
                                                {selectedEntities.pax.length > 0 && selectedEntities.cgo.length > 0 ? (
                                                    <Divider type="vertical" />
                                                ) : null}
                                                {selectedEntities.cgo.length > 0 ? (
                                                    <span>{selectedEntities.cgo.length} CGO</span>
                                                ) : null}
                                                {selectedEntities.pax.length > 0 || selectedEntities.cgo.length > 0 ? (
                                                    <span> selected</span>
                                                ) : null}
                                                {selectedEntities.pax.length > 0 || selectedEntities.cgo.length > 0 ? (
                                                    <>
                                                        <Divider type="vertical" />
                                                        <Button className="mc-link-btn" onClick={() => setSelectedEntities({ pax: [], cgo: [] })}>Clear</Button>
                                                    </>
                                                ) : null}
                                                {selectedEntities.pax.length > 0 || selectedEntities.cgo.length > 0 ? (
                                                    <Popconfirm title="Are you sure?" onConfirm={handleRemoveSelectedEntities}>
                                                        <Button disabled={removingEntities} type="danger" style={{ marginLeft: 12 }}>{removingEntities ? 'Removing...' : 'Remove'}</Button>
                                                    </Popconfirm>
                                                ) : null}
                                            </>
                                        }}
                                        rowSelection={!editable ? null : legTabKey === 'pax' ? {
                                            selectedRowKeys: selectedEntities.pax,
                                            onChange: (keys) => setSelectedEntities({ ...selectedEntities, pax: keys })
                                        } : {
                                            selectedRowKeys: selectedEntities.cgo,
                                            onChange: (keys) => setSelectedEntities({ ...selectedEntities, cgo: keys })
                                        }}
                                    />
                                ) : null}
                            </Col>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="manual" tab="Manual Assign">
                            <Col span={8}>
                                <FlightLegsTable
                                    legsCardProps={{
                                        extra: editable ? <Button onClick={() => {
                                            setAddLegOpen(true)
                                            setForceRefresh(true)
                                        }} type="primary">Modify Legs</Button> : undefined
                                    }}
                                    legs={legsArray}
                                    onMoveRow={(di, hi) => {}}
                                    onRow={(_, index) => {
                                        return {
                                            onClick: () => {},
                                            style: { cursor: 'default' }
                                        }
                                    }}
                                    rowSelection={{
                                        onChange: (keys: string[]) => {
                                            setSelectedEntities({ pax: [], cgo: [] })
                                            setSelectedLegs(keys)
                                        },
                                        selectedRowKeys: selectedLegs
                                    }}
                                />
                            </Col>
                            <Col span={16}>
                                {legsArray.filter(leg => selectedLegs.includes(String(leg.order))) ? (
                                    <LegManualAssignContent
                                        legs={legsArray.filter(leg => selectedLegs.includes(String(leg.order)))}
                                        onLegTabChange={setLegTabKey}
                                        legTabKey={legTabKey}
                                        cardProps={{
                                            extra: <>
                                                {selectedEntities.pax.length > 0 ? (
                                                    <span>{selectedEntities.pax.length} PAX</span>
                                                ) : null}
                                                {selectedEntities.pax.length > 0 && selectedEntities.cgo.length > 0 ? (
                                                    <Divider type="vertical" />
                                                ) : null}
                                                {selectedEntities.cgo.length > 0 ? (
                                                    <span>{selectedEntities.cgo.length} CGO</span>
                                                ) : null}
                                                {selectedEntities.pax.length > 0 || selectedEntities.cgo.length > 0 ? (
                                                    <span> selected</span>
                                                ) : null}
                                                {selectedEntities.pax.length > 0 || selectedEntities.cgo.length > 0 ? (
                                                    <>
                                                        <Divider type="vertical" />
                                                        <Button className="mc-link-btn" onClick={() => setSelectedEntities({ pax: [], cgo: [] })}>Clear</Button>
                                                    </>
                                                ) : null}
                                                {selectedEntities.pax.length > 0 || selectedEntities.cgo.length > 0 ? (
                                                    <>
                                                        <Popconfirm title="Are you sure you want to remove from flight?" onConfirm={handleRemoveSelectedEntities}>
                                                            <Button disabled={removingEntities} type="danger" style={{ marginLeft: 12 }}>{removingEntities ? 'Removing from Flight...' : 'Remove from Flight'}</Button>
                                                        </Popconfirm>
                                                        
                                                        <Popconfirm title="Are you sure you want to remove from selected legs?" onConfirm={handleRemoveFromLegSelectedEntities}>
                                                            <Button disabled={removingEntities} type="danger" style={{ marginLeft: 12 }}>{removingEntities ? 'Removing from selected Legs...' : 'Remove from selected Legs'}</Button>
                                                        </Popconfirm>
                                                    </>
                                                ) : null}
                                            </>
                                        }}
                                        rowSelection={!editable ? null : legTabKey === 'pax' ? {
                                            selectedRowKeys: selectedEntities.pax,
                                            onChange: (keys) => setSelectedEntities({ ...selectedEntities, pax: keys })
                                        } : {
                                            selectedRowKeys: selectedEntities.cgo,
                                            onChange: (keys) => setSelectedEntities({ ...selectedEntities, cgo: keys })
                                        }}
                                    />
                                ) : null}
                            </Col>
                            <Drawer
                                title={"Add Legs"}
                                visible={addLegOpen}
                                closable
                                onClose={() => setAddLegOpen(false)}
                                width="65%"
                                bodyStyle={{ display: 'flex', flexDirection: 'column' }}
                                style={{ display: 'flex', flexDirection: 'column' }}
                                className="ant-drawer-flex ant-drawer-body-no-padding"
                                >
                                    <LiveLegsAdd
                                        forceRefresh={forceRefresh}
                                        onForceRefresh={(fr) => setForceRefresh(fr)}
                                        onSave={(ss) => {
                                            console.log(ss)
                                            handleFlightLegsChange(ss, flightData.paxIDList, flightData.cgoIDList, true)
                                            setAddLegOpen(false)
                                        }}
                                        resultLegs={legsArray}
                                    />
                            </Drawer>
                        </Tabs.TabPane>
                    </Tabs>
                    
                    
                </Row>
            </>
        }

        return <Layout {...layoutProps}>
            <PageHeader
                title="Flight Editor"
                className="live-flight-editor-header"
                subTitle={subTitle}
                onBack={props.onBack}
                extra={[
                    (editable ? <Button type="primary" onClick={() => {
                        handleFlightLegsChange(flightData.legsArray, flightData.paxIDList, flightData.cgoIDList, true, "BLESSED")
                        
                    }}>Bless Flight</Button> : null)
                ,<PrintoutButton
                    buttonText={'Download Printout'}
                    manifestType ={'Manifest'}
                    flight={data && data.getFlight}
                    disabled={loading || error || !data || !data.getFlight}
                />,<PrintoutButton
                    buttonText={'Download Customs Printout'}
                    manifestType ={'Hess'}
                    flight={data && data.getFlight}
                    disabled={loading || error || !data || !data.getFlight}
                />,
                (editable ? <Button disabled={tabState === 'manual' && (selectedLegs === null || selectedLegs.length === 0)} onClick={() => {
                    setAssignerOpen(true)
                    setForceRefresh(true)
                }} type="primary">Assign PAX/CGO</Button> : null)]}
            />
            <Content style={{ padding: 12, overflow: 'auto' }}>
                {renderContent()}
            </Content>
            <Drawer
                title="Assign PAX/CGO"
                visible={assignerOpen}
                closable
                onClose={() => setAssignerOpen(false)}
                width="65%"
                bodyStyle={{ display: 'flex', flexDirection: 'column' }}
                style={{ display: 'flex', flexDirection: 'column' }}
                className="ant-drawer-flex ant-drawer-body-no-padding"
            >
                <PaxCgoSearch
                    forceRefresh={forceRefresh}
                    onForceRefresh={(fr) => setForceRefresh(fr)}
                    style={{ flex: 1, minHeight: 0 }}
                    paxSearchOptions={{
                        filter: { onlyShowLive: true }
                    }}
                    cgoSearchOptions={{
                        filter: { onlyShowLive: true, groundPickup: false }
                    }}
                    onEntitySelect={(ss) => setSearchSelections(ss)}
                    selectedEntities={searchSelections}
                    searchState={searchState}
                    onSearchStateChange={(ss) => setSearchState(ss)}
                    currentCarrier={tabState === 'manual' ? flightData._id: null}
                    tabsProps={{
                        tabBarExtraContent: <div>
                            {searchSelections.pax.length > 0 ? (
                                <span>{searchSelections.pax.length} PAX</span>
                            ) : null}
                            {searchSelections.pax.length > 0 && searchSelections.cgo.length > 0 ? (
                                <Divider type="vertical" />
                            ) : null}
                            {searchSelections.cgo.length > 0 ? (
                                <span>{searchSelections.cgo.length} CGO</span>
                            ) : null}
                            {searchSelections.pax.length > 0 || searchSelections.cgo.length > 0 ? (
                                <span> selected</span>
                            ) : null}
                            <Button
                                disabled={searchSelections.pax.length === 0 && searchSelections.cgo.length === 0}
                                type="primary"
                                style={{ marginLeft: 12 }}
                                loading={assigning}
                                onClick={handleAssignEntities}
                            >Assign To Flight</Button>
                        </div>
                    }}
                />
            </Drawer>
        </Layout>
    }}
    </Query>
}

export default withApollo( withOrgData(LiveFlightEditor) )