import {ColumnProps} from "antd/lib/table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxOpen} from "@fortawesome/free-solid-svg-icons";
import {Icon, Typography} from "antd";
import React from "react";
import {NotesForPilotQuickEdit} from "./notes-for-pilot/quickedit/notes-for-pilot-quick-edit";

type TEMP_TYPE_Pax = any;
type TEMP_TYPE_Cgo = any;
type TEMP_TYPE_Entity = TEMP_TYPE_Pax | TEMP_TYPE_Cgo

export function createScheduledEntityColumns(options?: { containerRef?: HTMLElement, reviewMode?: boolean }){

    const { containerRef, reviewMode } = options;

    const columns: ColumnProps<TEMP_TYPE_Entity>[] = [
        {
            title: 'Scheduled Group',
            key: 'scheduledGroup',
            dataIndex: 'scheduledGroup'
        },
        {
            title: 'Name',
            key: 'name',
            render: (_, record: TEMP_TYPE_Entity) => {
                let iconStyle = {
                    marginRight: 6
                }
                if(record.__typename === 'CargoNode'){
                    return <span><FontAwesomeIcon icon={faBoxOpen} style={iconStyle} /> {record.name}</span>
                }
                return <span><Icon type="user" style={iconStyle} />{record.lastName}, {record.firstName}</span>
            }
        },
        {
            title: 'Employer',
            key: 'employer',
            render: (_, record: TEMP_TYPE_Entity) => {
                if (record.classType === 'flytsuite.paxnode'){
                    return record.employerID ? record.employerID.name : <span style={{color: 'red'}}>Not Found</span>
                }
                else if (record.classType === 'flytsuite.cgonode'){
                    return <Typography.Text type="secondary">N/A</Typography.Text>
                }
                return null
            }
        },
        {
            title: 'Controlling Heliport',
            key: 'lastKnownController',
            dataIndex: 'lastKnownController',
            render: (doc) => doc?.name
        },
        {
            title: 'Weight',
            key: 'weight',
            render: (_, record: TEMP_TYPE_Entity) => {
                if(record.__typename === 'CargoNode'){
                    return `${record.weight}`
                }
                return `${record.paxWeight}`
            }
        },
        {
            title: 'Bag Weight',
            key: 'bagWeight',
            dataIndex: 'bagWeight'
        },
        {
            title: 'Bag Count',
            key: 'bagCount',
            dataIndex: 'bagCount'
        },
        {
            title: 'Departure',
            key: 'departure',
            render: (_, record: TEMP_TYPE_Entity) => record.departureID ? record.departureID.name : <span style={{color: 'red'}}>Not Found</span>
        },
        {
            title: 'Destination',
            key: 'destination',
            render: (_, record: TEMP_TYPE_Entity) => record.destinationID ? record.destinationID.name : <span style={{color: 'red'}}>Not Found</span>
        },
        {
            title: 'Transit Type',
            key: 'transitType',
            dataIndex: 'transitType'
        },
        {
            title: 'Pilot Notes',
            key: 'pilotNotes',
            render: (_, row: TEMP_TYPE_Entity) => {
                return <NotesForPilotQuickEdit
                    scheduleNodeData={row}
                    readOnly
                    disabled={reviewMode}
                    popupContainer={containerRef}
                />
            }
        }
    ]

    return columns;
}