import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import gql from 'graphql-tag';
import WithOrgData from '../../../WithOrgData';
import { Alert, Row, Col, Button, message, Popconfirm, List, Checkbox, Modal, Icon, Input } from 'antd';
import LocationSearch from '../../../LocationSearch';
import EmployerSearch from '../../../EmployerSearch';
import _ from 'lodash';
import withLoading from '../../../WithLoading';
import withError from '../../../WithError';
import { Link } from 'react-router-dom';
import pluralize from 'pluralize';
import { withRouter } from 'react-router-dom';
import './index.less';

const WHITE_LIST = gql`
query WhiteList($_id: ID!){
    getWhiteList(_id: $_id){
        _id
        locationToEmployer {
            locationID {
                _id
                name
            }
            employerIDs {
                _id
                name
            }
        }
    }
}
`

const SET_WHITE_LIST = gql`
mutation SetWhiteList(
    $_id: ID
    $customerID: ID!
    $tpID: ID!
    $locationToEmployer: [LocationToEmployerMapInput]
){
    setWhiteList(
        _id: $_id
        customerID: $customerID
        tpID: $tpID
        locationToEmployer: $locationToEmployer
    ){
        _id
        locationToEmployer {
            locationID {
                _id
                name
            }
            employerIDs {
                _id
                name
            }
        }
    }
}
`

const LocationSearchPrompt = () => (
    <div style={{textAlign: 'center', margin: '5rem 0', color: 'grey'}}>
        <Icon type="search" style={{fontSize: '4rem', marginBottom: '1rem'}} />
        <div>Search for locations by typing into the search bar</div>
    </div>
)

class Location extends Component {
    state = {
        locationSearchOpen: false,
        locationFilter: '',
        locationsToAdd: [],
        employersToAdd: [],
        selectedEmployers: [],
        addingEmployers: false,
        addingLocations: false
    }
    componentDidUpdate(prevProps) {
        const prevLocation = prevProps.match.params.location;
        const currentLocation = this.getSelectedLocation();
        if (!prevLocation && currentLocation){
            this.props.history.replace(`/app/masterdata/whitelist/location/${currentLocation}`);
        }
        if (prevLocation !== currentLocation){
            this.setState({ selectedEmployers: [] });
        }
    }
    openLocationSearch = () => {
        this.setState({ locationSearchOpen: true, locationsToAdd: [] });
    }
    closeLocationSearch = () => {
        this.setState({ locationSearchOpen: false, locationsToAdd: [] })
    }
    addEmployers = () => {
        const { customer, transporter } = this.props.orgData;
        const { setWhiteList, data } = this.props;
        const locationToEmployer = data.getWhiteList ? data.getWhiteList.locationToEmployer || [] : [];
        const locationID = this.getSelectedLocation();
        const location = locationToEmployer.find(loc => loc.locationID._id === locationID);
        const employers = location ? location.employerIDs || [] : [];
        const employerIDs = employers.map(emp => emp._id);

        const lte = locationToEmployer.map(loc => {
            var result = {
                locationID: loc.locationID._id
            }
            if (loc.locationID._id === locationID){
                result.employerIDs = _.union(employerIDs, this.state.employersToAdd);
            }else{
                result.employerIDs = loc.employerIDs.map(emp => emp._id)
            }
            return result
        })
        this.setState({ addingEmployers: true });
        setWhiteList({
            variables: {
                _id: customer._id.replace('CUS-', 'WHL-'),
                tpID: transporter._id,
                customerID: customer._id,
                locationToEmployer: lte
            }
        })
        .then(() => {
            message.success(`Successfully added ${this.state.employersToAdd.length} employers to ${location.locationID.name}`);
            this.setState({ employersToAdd: [] });
        })
        .catch(error => {
            console.error(error);
            message.error("Failed to add employers to location")
        })
        .then(() => this.setState({ addingEmployers: false }))
    }
    removeEmployers = () => {
        const { customer, transporter } = this.props.orgData;
        const { setWhiteList, data } = this.props;
        const locationToEmployer = data.getWhiteList ? data.getWhiteList.locationToEmployer || [] : [];
        const locationID = this.getSelectedLocation();
        const location = locationToEmployer.find(loc => loc.locationID._id === locationID);
        const employers = location ? location.employerIDs || [] : [];
        const employerIDs = employers.map(emp => emp._id);

        const lte = locationToEmployer.map(loc => {
            var result = {
                locationID: loc.locationID._id
            }
            if (loc.locationID._id === locationID){
                result.employerIDs = employerIDs.filter(emp => !this.state.selectedEmployers.includes(emp));
            }else{
                result.employerIDs = loc.employerIDs.map(emp => emp._id)
            }
            return result
        })

        setWhiteList({
            variables: {
                _id: customer._id.replace('CUS-', 'WHL-'),
                tpID: transporter._id,
                customerID: customer._id,
                locationToEmployer: lte
            },
            optimisticResponse: {
                __typename: 'Mutation',
                setWhiteList: {
                    __typename: 'WhiteList',
                    _id: customer._id.replace('CUS-', 'WHL-'),
                    locationToEmployer: locationToEmployer.map(loc => {
                        var result = {
                            __typename: 'LocationToEmployerMap',
                            locationID: loc.locationID
                        }
                        if (loc.locationID._id === locationID){
                            result.employerIDs = employers.filter(emp => !this.state.selectedEmployers.includes(emp._id));
                        }else{
                            result.employerIDs = loc.employerIDs
                        }
                        return result
                    })
                }
            }
        })
        .then(() => {
            message.success(`Successfully removed ${this.state.selectedEmployers.length} employers to ${location.locationID.name}`);
            this.setState({ selectedEmployers: [] });
        })
        .catch(error => {
            console.error(error);
            message.error("Failed to add employers to location")
        })

    }
    addLocations = () => {
        const { customer, transporter } = this.props.orgData;
        const { setWhiteList, data } = this.props;
        const locationToEmployer = data.getWhiteList.locationToEmployer || [];
        const oldLocItems = locationToEmployer.map(loc => {
            return {
                locationID: loc.locationID._id,
                employerIDs: loc.employerIDs.map(emp => emp._id)
            }
        })
        const locItems = this.state.locationsToAdd.map(loc => ({
            locationID: loc,
            employerIDs: []
        }))
        const lte = _.unionBy(oldLocItems, locItems, 'locationID')
        this.setState({ addingLocations: true })
        setWhiteList({
            variables: {
                _id: customer._id.replace('CUS-', 'WHL-'),
                tpID: transporter._id,
                customerID: customer._id,
                locationToEmployer: lte
            }
        })
        .then(() => {
            message.success(`Succesfully added ${this.state.locationsToAdd.length} locations to white list`)
            this.closeLocationSearch()
        })
        .catch(error => {
            console.error(error)
            message.error("Failed to add locations to white list")
        })
        .then(() => this.setState({ addingLocations: false }))
    }
    removeLocation = (id) => {
        const { customer, transporter } = this.props.orgData;
        const { setWhiteList, data } = this.props;
        const currentLocationID = this.props.match.params.location;
        const locationToEmployer = data.getWhiteList.locationToEmployer || [];
        const newLocItems = locationToEmployer.filter(loc => loc.locationID._id !== id);
        const lte = newLocItems.map(loc => {
            return {
                locationID: loc.locationID._id,
                employerIDs: loc.employerIDs.map(emp => emp._id)
            }
        })
        if (currentLocationID === id){
            var path = `/app/masterdata/whitelist/location`;
            if (lte.length){
                path = path + `/${lte[0].locationID}`
            }
            this.props.history.push(path)
        }
        setWhiteList({
            variables: {
                _id: customer._id.replace('CUS-', 'WHL-'),
                tpID: transporter._id,
                customerID: customer._id,
                locationToEmployer: lte
            },
            optimisticResponse: {
                __typename: 'Mutation',
                setWhiteList: {
                    __typename: 'WhiteList',
                    _id: data.getWhiteList._id,
                    locationToEmployer: newLocItems
                }
            }
        })
        .then(() => {
            message.success(`Succesfully removed location from white list`)
        })
        .catch(error => {
            console.error(error)
            message.error("Failed to add locations to white list")
        })
    }
    getSelectedLocation = () => {
        const { locationToEmployer } = this.props.data.getWhiteList;
        const { match } = this.props;
        var selectedLoc = match.params.location;
        if (!selectedLoc && locationToEmployer && locationToEmployer.length){
            selectedLoc = locationToEmployer[0].locationID._id;
        }
        return selectedLoc
    }
    onEmployerChecked = (employerID, checked) => {
        var selectedEmployers;
        if (checked) {
            selectedEmployers = _.union(this.state.selectedEmployers, [employerID])
        }else{
            selectedEmployers = this.state.selectedEmployers.filter(item => item !== employerID)
        }
        this.setState({ selectedEmployers })
    }
    renderLocationContent = () => {
        const { locationToEmployer } = this.props.data.getWhiteList;
        const location = locationToEmployer.find(item => item.locationID._id === this.getSelectedLocation())
        if (location) {
            const employers = location.employerIDs || [];
            const employerList = (
                <div>
                    <Row type="flex" style={{ marginBottom: '1rem' }}>
                        <Col style={{ flex: 1 }} >
                            <h3 style={{ margin: 0 }}>White Listed Employers for {location.locationID.name}</h3>
                        </Col>
                        <Col>
                            <Popconfirm
                                title={`Are you sure you want to remove ${this.state.selectedEmployers.length} ${pluralize('employer', this.state.selectedEmployers.length)} from ${location.locationID.name}?`}
                                okText="Remove"
                                onConfirm={this.removeEmployers}
                                placement="bottomLeft"
                            >
                                <Button
                                    type="danger"
                                    disabled={!this.state.selectedEmployers.length}
                                    style={{ marginRight: '0.5rem' }}
                                >Remove Selected Employers</Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                    <List
                        dataSource={employers}
                        bordered
                        renderItem={emp => {
                            const isChecked = this.state.selectedEmployers.includes(emp._id);
                            return (
                                <List.Item>
                                    <Checkbox checked={isChecked} onChange={(e) => this.onEmployerChecked(emp._id, e.target.checked)} />
                                    <div style={{ marginLeft: '1rem' }}>
                                        {emp.name}
                                    </div>
                                </List.Item>
                            )
                        }}
                    />
                </div>
            )
            return (
                <Row type="flex" style={{ height: '100%' }}>
                    <Col span={15}>
                        <div style={{ padding: '1rem' }}>
                            {employers.length ? employerList : (
                                <div style={{ textAlign: 'center' }}>
                                    <h2 style={{ marginTop: '10rem' }}>{location.locationID.name} has no white listed employers</h2>
                                    <p>If a location has no white listed employers, the location is considered <strong>white listed.</strong></p>
                                    <p>To add employers, use the search on the right.</p>
                                    <p>If you don't want {location.locationID.name} to be white listed, then 
                                        <Popconfirm title="Are you sure you want to remove this location?" okText="Remove" onConfirm={() => this.removeLocation(location.locationID._id)}>
                                            <Button className="mc-link-btn"> remove </Button>
                                        </Popconfirm>
                                     this location.</p>
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col span={9}>
                        <div className="whitelist-right-panel" style={{ height: '100%' }}>
                            <div style={{ padding: '24px 24px 0 24px', marginBottom: '-10px' }}>
                                <Row type="flex" style={{ alignItems: 'baseline' }}>
                                    <Col style={{ flex: 1 }}>
                                        <h3 style={{ margin: 0 }} >Add Employers to {location.locationID.name}</h3>
                                    </Col>
                                    <Col>
                                        <Button
                                            type="primary"
                                            disabled={!this.state.employersToAdd.length}
                                            loading={this.state.addingEmployers}
                                            onClick={this.addEmployers}
                                        >Add Selected Employers</Button>
                                    </Col>
                                </Row>
                            </div>
                            <EmployerSearch
                                selected={this.state.employersToAdd}
                                onChange={employersToAdd => this.setState({ employersToAdd })}
                            />
                        </div>
                    </Col>
                </Row>
            )
        }
        return (
            <div style={{ textAlign: 'center' }}>
                <h2 style={{ marginTop: '10rem' }} >
                    Location does not exist in white list
                </h2>
                <p>Please choose a different location or add a <Button className="mc-link-btn" onClick={this.openLocationSearch} >new one</Button></p>
            </div>
        )
    }
    renderLocations = () => {
        const { locationToEmployer } = this.props.data.getWhiteList;
        const selectedLoc = this.getSelectedLocation();
        const locations = locationToEmployer.sort((a, b) => {
            if (a.locationID.name < b.locationID.name) return -1
            if (a.locationID.name > b.locationID.name) return 1
            return 0
        })
        const filteredLocations = locations.filter(loc => {
            if (this.state.locationFilter){
                if (loc.locationID.name && loc.locationID.name.toLowerCase().indexOf(this.state.locationFilter)){
                    return false
                }else{
                    return true
                }
            }else{
                return true
            }
        })
        return (
            <div style={{ padding: '1rem' }}>
                <h3>Locations</h3>
                <Input 
                    prefix={<Icon type="search" />}
                    placeholder="Filter locations"
                    onChange={(e) => this.setState({ locationFilter: e.target.value })}
                    style={{ marginBottom: '1rem' }}
                />
                <div style={{ overflow: 'auto', maxHeight: '30rem' }}>
                    <List 
                        dataSource={filteredLocations}
                        bordered
                        size="small"
                        renderItem={item => {
                            const isSelected = selectedLoc === item.locationID._id;
                            const style = {
                                background: isSelected ? '#1890ff' : undefined,
                                color: isSelected ? 'white' : undefined
                            }
                            const textStyle = {
                                color: isSelected ? 'white' : undefined
                            }
                            const buttonStyle = {
                                borderColor: isSelected ? 'white' : undefined,
                                background: isSelected ? 'transparent' : undefined,
                                color: isSelected ? 'white' : undefined
                            }
                            var renderText = <span style={textStyle}>{item.locationID.name}</span>
                            if (!isSelected) {
                                renderText = <Link style={textStyle} to={`/app/masterdata/whitelist/location/${item.locationID._id}`} >{item.locationID.name}</Link>
                            }
                            const employers = item.employerIDs || [];
                            var desc = <span style={textStyle}>{employers.length} {pluralize('Employer', employers.length)}</span>;
                            if (!employers.length){
                                desc = <span style={textStyle}>WHITELISTED (No employers)</span>
                            }
                            return (
                                <List.Item style={style} actions={[
                                    <Popconfirm title="Are you sure you want to remove this location?" okText="Remove" onConfirm={() => this.removeLocation(item.locationID._id)}>
                                        <Button style={buttonStyle} type="danger" size="small" shape="circle" icon="delete" />
                                    </Popconfirm>
                                ]} >
                                    <List.Item.Meta
                                        title={renderText}
                                        description={desc}
                                    />
                                </List.Item>
                            )
                        }}
                    />
                </div>
                <Button
                    type="primary"
                    ghost
                    block
                    style={{ margin: '1rem 0' }}
                    onClick={this.openLocationSearch} >Add Location</Button>
            </div>
        )
        // const renderItem = (item) => (
        //     <List.Item>
        //         {item.locationID.name}
        //     </List.Item>
        // )
        // return (
        //     <List
        //         renderItem={renderItem} 
        //         dataSource={getWhiteList.locationToEmployer} 
        //         bordered
        //         />
        // )
    }
    render() {
        const { getWhiteList } = this.props.data;
        const { customer } = this.props.orgData;
        var hasLocations = false;
        if (getWhiteList && getWhiteList.locationToEmployer && getWhiteList.locationToEmployer.length){
            hasLocations = true;
        }
        return (
            <React.Fragment>
                {hasLocations ? (
                    <Row type="flex">
                        <Col style={{ minWidth: '19rem', flexShrink: 0 }}>
                            <div className="whitelist-left-panel" style={{ minHeight: '40rem' }}>
                                {this.renderLocations()}
                            </div>
                        </Col>
                        <Col style={{ flex: 1 }}>
                            {this.renderLocationContent()}
                        </Col>
                    </Row>
                ) : (
                    <div style={{ textAlign: 'center', margin: '10rem 0' }}>
                        <h2>No locations are white listed for {customer.name}</h2>
                        <Button
                            type="primary"
                            style={{ marginTop: '1rem' }}
                            size="large"
                            onClick={this.openLocationSearch} >Add Locations</Button>
                    </div>
                )}
                <Modal
                    visible={this.state.locationSearchOpen}
                    title="Search locations"
                    onCancel={this.closeLocationSearch}
                    okText="Add locations"
                    onOk={this.addLocations}
                    okButtonProps={{
                        loading: this.state.addingLocations
                    }}
                >
                    <div style={{ margin: -24 }}>
                        <LocationSearch
                            onChange={(locationsToAdd => this.setState({ locationsToAdd }))}
                            selected={this.state.locationsToAdd}
                            searchPrompt={<LocationSearchPrompt />}
                        />
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

const withErrorRender = ({ error }, { message }) => {
    const notFoundError = error.graphQLErrors.find(err => err.errorType === 'not_found' && err.path.length === 1)
    if (!notFoundError){
        return <Alert type="error" message={message} description={error.message} showIcon style={{ margin: '1rem' }} />
    }
    return null
}
 
export default compose(
    withRouter,
    WithOrgData,
    graphql(
        WHITE_LIST,
        {
            options: props => ({
                variables: {
                    _id: props.orgData.customer._id.replace('CUS-', 'WHL-')
                },
                pollInterval: ['cache-only', 'cache-first'].includes(props.fetchPolicy) ? undefined : 15000,
                fetchPolicy: props.fetchPolicy || 'network-only'
            }),
            props: props => {
                const defaultWl = {
                    _id: props.ownProps.orgData.customer._id.replace('CUS-', 'WHL-'),
                    locationToEmployer: [],
                    __typename: 'WhiteList'
                }
                if (props.ownProps.useDefault){
                    return {
                        ...props,
                        useDefault: undefined,
                        data: {
                            ...props.data,
                            error: undefined,
                            getWhiteList: defaultWl
                        }
                    }
                }
                return props
            }
        }
    ),
    graphql(
        SET_WHITE_LIST,
        {
            name: 'setWhiteList'
        }
    ),
    withLoading({ loadingProps: { style: { margin: '5rem 0' } } }),
    withError({ message: 'Failed to load location white list data', render: withErrorRender })
)(Location);